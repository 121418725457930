import { forwardRef } from "react"
import type { DialogProps } from "@radix-ui/react-dialog"
import type { LucideIcon } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogOverlay,
	DialogTitle,
} from "@/components/ui/dialog"
import { VisuallyHidden } from "@/components/ui/visually-hidden"

const AddEditDialog = ({ children, ...props }: DialogProps) => {
	return (
		<Dialog {...props}>
			<DialogOverlay>
				<DialogContent
					size="lg"
					className="flex flex-col gap-0 p-0"
					onInteractOutside={(e) => {
						e.preventDefault()
					}}
					//hideClose
				>
					{children}
				</DialogContent>
			</DialogOverlay>
			<VisuallyHidden>
				<DialogDescription>Add Edit Dialog</DialogDescription>
			</VisuallyHidden>
		</Dialog>
	)
}

const AddEditHeader = ({ children }: { children: React.ReactNode }) => {
	return <DialogHeader className="px-5 pb-1 pt-7">{children}</DialogHeader>
}
const AddEditTitle = DialogTitle
const AddEditDescription = DialogDescription

const AddEditBody = ({
	className,
	children,
	...props
}: React.HTMLAttributes<HTMLDivElement>) => {
	return (
		<div
			className={cn("flex grow overflow-hidden @container", className)}
			{...props}
		>
			{children}
		</div>
	)
}
const AddEditSummary = ({
	className,
	children,
	...props
}: React.HTMLAttributes<HTMLDivElement>) => {
	return (
		<div
			className={cn(
				"hidden w-2/5 flex-col items-center gap-3 p-4 @[650px]:flex",
				className,
			)}
			{...props}
		>
			{children}
		</div>
	)
}
const AddEditEditor = ({
	className,
	children,
	...props
}: React.HTMLAttributes<HTMLDivElement>) => {
	return (
		<div
			className={cn("flex w-full flex-col @[650px]:w-2/3", className)}
			{...props}
		>
			{children}
		</div>
	)
}

const AddEditFooter = ({ children }: { children: React.ReactNode }) => {
	return <DialogFooter className="px-6 pb-4 pt-3">{children}</DialogFooter>
}

/**
 * @description			A large, bold heading used to label a {@link AddEditSection}. Can optionally include an icon.
 * @param icon 			An optional icon to be displayed to the left of the heading.
 */
const AddEditSectionHeading = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement> & { icon?: LucideIcon }
>(({ children, className, icon: Icon, ...props }, ref) => (
	<div
		className={cn(
			"col-span-full mb-1 flex items-center gap-2 text-lg font-bold text-foreground",
			className,
		)}
		{...props}
		ref={ref}
	>
		{Icon && <Icon className="size-6" />}
		{children}
	</div>
))
AddEditSectionHeading.displayName = "AddEditSectionHeading"

const AddEditSection = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement> & { columned?: boolean }
>(({ className, columned = false, ...props }, ref) => {
	return (
		<div
			className={cn(
				`flex flex-col gap-2`,
				columned && "grid grid-cols-2",
				className,
			)}
			{...props}
			ref={ref}
		/>
	)
})
AddEditSection.displayName = "AddEditSection"

const AddEditSectionsGrouping = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ className, ...props }, ref) => {
	return (
		<div
			className={cn(`flex flex-col gap-7`, className)}
			{...props}
			ref={ref}
		/>
	)
})
AddEditSectionsGrouping.displayName = "AddEditSectionsGrouping"

export {
	AddEditDialog,
	AddEditHeader,
	AddEditTitle,
	AddEditDescription,
	AddEditBody,
	AddEditSummary,
	AddEditEditor,
	AddEditFooter,
	AddEditSection,
	AddEditSectionsGrouping,
	AddEditSectionHeading,
}
