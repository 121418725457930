import { useEffect, useState } from "react"
import { Pause, Play } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { formatTimerFromSeconds } from "@/lib/utils/time-formatting-helpers"
import { TimerProgressBar } from "@/components/global/shop-watch/expanded/components/progress-bar"

export const ServiceCardTimeTracker = () => {
	const [time, setTime] = useState(0) // Time in seconds
	const [isRunning, setIsRunning] = useState(false)

	useEffect(() => {
		let timer: NodeJS.Timeout | undefined
		if (isRunning) {
			if (time == 0) setTime(1) // Set to 1 on click, then update every second
			timer = setInterval(() => {
				setTime((prevTime) => prevTime + 1)
			}, 1000)
		} else if (!isRunning && time !== 0) {
			clearInterval(timer)
		}
		return () => clearInterval(timer)
	}, [isRunning, time])

	return (
		<>
			<button
				onClick={() => setIsRunning(!isRunning)}
				className={cn(
					"flex min-w-[110px] flex-col gap-1 rounded border px-2 py-1 leading-none text-success-foreground",
					isRunning ?
						"border-success-weak bg-success-weak text-success-foreground"
					:	"border-primary-weak/30 bg-selected text-primary-weak",
				)}
			>
				<div className="flex items-center justify-between">
					<div className={cn("shrink-0 font-semibold")}>
						{formatTimerFromSeconds(time)}
					</div>

					{isRunning ?
						<Pause className="text-white" size={16} />
					:	<>
							<Play size={16} />
						</>
					}
				</div>
				<TimerProgressBar
					className="pr-0"
					currentTimeSeconds={time}
					estimatedMinutes={90}
					isRunning={isRunning}
				/>
			</button>
		</>
	)
}
