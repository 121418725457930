import { Bolt, Plus } from "lucide-react"

import type { Service } from "@/server/schemas"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionHeadingCount,
} from "@/components/ui/details-view"
import { FieldGroup } from "@/components/ui/feild-group"
import { Label } from "@/components/ui/form"
import { Input, InputSlot } from "@/components/ui/input"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { PartDetailsCard } from "@/components/features/part/details/card"

import CountIncrementField from "./increment"

export const ServicePartsSection: DetailsSectionComponent<Service> = ({
	data: service,
}) => (
	<DetailsSection>
		<Accordion type="multiple">
			<AccordionItem value="operator-inspections">
				<AccordionTrigger className="px-0">
					<DetailsSectionHeading>
						Parts
						<DetailsSectionHeadingCount>
							({service?.parts?.length})
						</DetailsSectionHeadingCount>
					</DetailsSectionHeading>
				</AccordionTrigger>
				<AccordionContent className="mb-5 flex flex-col gap-2 p-0 pt-2">
					<div className="flex items-center gap-5">
						<DetailsInfoGroup label="Flat Parts Fee">
							{service?.flatPartsFee}
						</DetailsInfoGroup>
						<DetailsInfoGroup label="Use Flat Fee">
							{service?.useFlatPartsFee ? "Yes" : "No"}
						</DetailsInfoGroup>
					</div>
					{service.parts &&
						service.parts.map((part) => {
							return <PartDetailsCard key={part.id} part={part} />
						})}
					<Button variant="placeholder" className="mt-3 gap-1">
						<Plus className="size-5" /> Add Part
					</Button>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	</DetailsSection>
)
ServicePartsSection.label = "Parts"
ServicePartsSection.icon = Bolt
