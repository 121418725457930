import { useEffect, useState } from "react"
import type { Message as AblyMessage } from "@ably/chat"
import {
	ChatRoomProvider,
	MessageEvents,
	RoomOptionsDefaults,
	useMessages,
} from "@ably/chat"
import { useUser } from "@clerk/remix"
import { MessageCircleIcon, SendIcon } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import type { WorkOrder } from "@/server/schemas"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { Input } from "@/components/ui/input"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

// Types
interface MessageProps {
	message: AblyMessage
	isCurrentUser: boolean
}

// Components
const Message = ({ message, isCurrentUser }: MessageProps) => {
	const time = new Date(message.timestamp).toLocaleTimeString([], {
		hour: "numeric",
		minute: "2-digit",
	})

	const name = String(message.metadata?.name) || message.clientId

	return (
		<div className="space-y-1">
			<div className="flex items-center justify-between text-xs opacity-70">
				<span>{name}</span>
				<span>{time}</span>
			</div>
			<div
				className={cn(
					"rounded-lg px-2 py-3",
					isCurrentUser ? "bg-info" : "bg-background-weak",
				)}
			>
				<div
					className={cn(
						"flex items-start gap-2",
						isCurrentUser ?
							"text-info-foreground"
						:	"text-foreground-weak",
					)}
				>
					<Avatar className="shrink-0">
						<AvatarFallback>{name}</AvatarFallback>
					</Avatar>
					<div className="flex-1">
						<div>{message.text}</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const MessageInput = ({
	messageText,
	setMessageText,
	onSend,
}: {
	messageText: string
	setMessageText: (text: string) => void
	onSend: () => void
}) => {
	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") {
			onSend()
		}
	}

	return (
		<div className="flex gap-2 bg-background pt-6">
			<Input
				className="flex-1 rounded-full"
				placeholder="Message"
				type="text"
				value={messageText}
				onChange={(e) => setMessageText(e.target.value)}
				onKeyDown={handleKeyDown}
			/>
			<Button
				size="icon"
				variant="primary"
				className="rounded-full"
				onClick={onSend}
				disabled={!messageText.trim()}
			>
				<SendIcon className="size-4" />
			</Button>
		</div>
	)
}

const EmptyMessages = () => (
	<div className="py-2">
		<div className="flex flex-1 flex-col items-center justify-center gap-2 text-foreground-weak">
			<MessageCircleIcon className="size-8 opacity-50" />
			<div className="flex flex-1 flex-col items-center justify-center gap-1 text-center">
				<div>No messages yet</div>
				<div className="text-sm">
					Be the first to start the conversation!
				</div>
			</div>
		</div>
	</div>
)

const MessagesContent = () => {
	const { user } = useUser()
	if (!user?.id) throw new Error("User not logged in or doesn't have an id")

	const [messageText, setMessageText] = useState("")
	const [messages, setMessages] = useState<AblyMessage[]>([])
	const [loading, setLoading] = useState(true)

	const { send, getPreviousMessages } = useMessages({
		listener: (event) => {
			const sortMessages = (msgs: AblyMessage[]) =>
				msgs.sort(
					(a, b) => b.timestamp.getTime() - a.timestamp.getTime(),
				)

			const isNewerVersion = (
				existing: AblyMessage | undefined,
				incoming: AblyMessage,
			) => !existing || !incoming.versionBefore(existing)

			switch (event.type) {
				case MessageEvents.Created:
					setMessages((prev) =>
						sortMessages([...prev, event.message]),
					)
					break
				case MessageEvents.Updated:
					setMessages((prev) => {
						const existing = prev.find(
							(m) => m.serial === event.message.serial,
						)
						if (!isNewerVersion(existing, event.message))
							return prev

						return sortMessages(
							prev.map((m) =>
								m.serial === event.message.serial ?
									event.message
								:	m,
							),
						)
					})
					break
				case MessageEvents.Deleted:
					setMessages((prev) => {
						const existing = prev.find(
							(m) => m.serial === event.message.serial,
						)
						if (!isNewerVersion(existing, event.message))
							return prev

						return prev.filter(
							(m) => m.serial !== event.message.serial,
						)
					})
					break
			}
		},
	})

	useEffect(() => {
		const loadMessages = async () => {
			if (!getPreviousMessages || !loading) return

			try {
				const result = await getPreviousMessages({ limit: 1000 })
				setMessages(
					result.items.sort(
						(a, b) => b.timestamp.getTime() - a.timestamp.getTime(),
					),
				)
			} catch (error) {
				console.error("Failed to load messages:", error)
			} finally {
				setLoading(false)
			}
		}

		loadMessages()
	}, [getPreviousMessages, loading])

	const handleSendMessage = async () => {
		const trimmedMessage = messageText.trim()
		if (!trimmedMessage) return

		try {
			const userName =
				user.fullName ||
				(user.firstName && user.lastName ?
					`${user.firstName} ${user.lastName}`.trim()
				:	user.firstName || user.lastName || user.id)

			const metadata = {
				name: userName,
			}

			await send({
				text: trimmedMessage,
				metadata,
			})

			setMessageText("")
		} catch (error) {
			console.error("Failed to send message:", error)
		}
	}

	return (
		<div className="flex flex-1 flex-col @3xl:max-h-[600px] @3xl:rounded-lg @3xl:border @3xl:p-5 @3xl:shadow">
			<div className="flex flex-1 flex-col-reverse gap-y-4 overflow-y-auto">
				{loading ?
					<div className="text-center text-foreground-weak">
						Loading messages...
					</div>
				: messages.length === 0 ?
					<EmptyMessages />
				:	messages.map((message) => (
						<Message
							key={message.serial}
							message={message}
							isCurrentUser={message.clientId === user.id}
						/>
					))
				}
			</div>

			<MessageInput
				messageText={messageText}
				setMessageText={setMessageText}
				onSend={handleSendMessage}
			/>
		</div>
	)
}

export const WorkOrderMessagesSection: DetailsSectionComponent<WorkOrder> = ({
	data: workOrder,
}) => {
	const { isLoaded, user } = useUser()

	if (!isLoaded) {
		return (
			<div className="flex items-center justify-center p-4 text-foreground-weak">
				Loading...
			</div>
		)
	}

	if (!user?.id) {
		return (
			<div className="flex items-center justify-center p-4 text-foreground-weak">
				Please sign in to view messages
			</div>
		)
	}

	return (
		<DetailsSection>
			<DetailsSectionHeading>Messages</DetailsSectionHeading>
			<ChatRoomProvider
				id={`work-order-${workOrder.id}`}
				options={RoomOptionsDefaults}
			>
				<MessagesContent />
			</ChatRoomProvider>
		</DetailsSection>
	)
}

WorkOrderMessagesSection.label = "Messages"
WorkOrderMessagesSection.icon = MessageCircleIcon
WorkOrderMessagesSection.disableScrollArea = true
