import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { equipmentPositionTable } from "@/server/tables"

const equipmentPositionOmitFields = {
	scopeId: true,
} as const
export const equipmentPositionSelectColumns = {
	...getTableSelectColumns(
		equipmentPositionTable,
		equipmentPositionOmitFields,
	),
}

const newEquipmentPositionFormValidation = z.object({
	latitude: z.number().nullable(),
	longitude: z.number().nullable(),
})

export const equipmentPositionSchema = createSelectSchema(
	equipmentPositionTable,
).omit(equipmentPositionOmitFields)
export type EquipmentPosition = z.infer<typeof equipmentPositionSchema>

export const newEquipmentPositionSchema = createInsertSchema(
	equipmentPositionTable,
)
	.omit(equipmentPositionOmitFields)
	.merge(newEquipmentPositionFormValidation)
export type NewEquipmentPosition = z.infer<typeof newEquipmentPositionSchema>
