import { createContext, useContext, useMemo, useState } from "react"

interface ServicesDetailsContext {
	isEditedVariant: boolean
	setIsEditedVariant: React.Dispatch<React.SetStateAction<boolean>>
}

// Context & Hook
const ServicesDetailsContext = createContext<ServicesDetailsContext | null>(
	null,
)

export const useServicesDetail = () => {
	const context = useContext(ServicesDetailsContext)
	if (!context) {
		throw new Error(
			"useServicesDetail must be used within a ServicesDetailProvider",
		)
	}
	return context
}

// Provider Component
export const ServicesDetailProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const [isEditedVariant, setIsEditedVariant] = useState(false)

	const contextValue: ServicesDetailsContext = useMemo(
		() => ({
			isEditedVariant,
			setIsEditedVariant,
		}),
		[isEditedVariant, setIsEditedVariant],
	)

	return (
		<ServicesDetailsContext.Provider value={contextValue}>
			{children}
		</ServicesDetailsContext.Provider>
	)
}
