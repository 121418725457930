import { Timer } from "lucide-react"

export function ServiceCardTimeTotal() {
	return (
		<div className="flex flex-col items-center rounded-md border border-success-border/30 bg-success/[15%] px-2 py-1">
			<div className="flex items-center gap-1">
				<Timer size={16} />
				<b>2h 04m</b>
			</div>
			<div className="text-sm opacity-70">est: 1h 30m</div>
		</div>
	)
}
