import type { FakePendingWork } from "@/routes/_main+/work+/pending-work/mock-data"
import { DollarSignIcon, InfoIcon } from "lucide-react"

import { DetailsSectionsGrouping } from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

import { Invoice } from "./invoice"

export const PendingWorkFinancialSection: DetailsSectionComponent<
	FakePendingWork
> = ({ data: pendingWork }) => (
	<DetailsSectionsGrouping>
		<Invoice />
	</DetailsSectionsGrouping>
)
PendingWorkFinancialSection.label = "Financial"
PendingWorkFinancialSection.icon = DollarSignIcon
