import { Calendar } from "lucide-react"

import type { PMMeterElapsed, PMTimeElapsed, Service } from "@/server/schemas"
import {
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
	DetailsSectionSubheading,
} from "@/components/ui/details-view"
import { FieldGroup } from "@/components/ui/feild-group"
import { GridRow } from "@/components/ui/gridRow"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const ServicePMSection: DetailsSectionComponent<Service> = ({
	data: service,
}) => {
	const hasPMTime = service.pmTimeElapsed?.id
	const hasPMMeter = service.pmMeterElapsed?.id

	if (!hasPMTime && !hasPMMeter)
		return (
			<DetailsSection>
				<div className="grid place-items-center rounded-md bg-background-weaker p-10 text-foreground-weak">
					This service is not designated as Preventive Maintenance
				</div>
			</DetailsSection>
		)

	return (
		<DetailsSection>
			<div>
				<DetailsSectionHeading>
					Preventive Maintenance
				</DetailsSectionHeading>
				<p className="mt-2">
					If date and meter are both chosen, the PM will be scheduled
					based on whichever occurs first.
				</p>
			</div>

			{hasPMTime && service.pmTimeElapsed && (
				<PmTime pm={service.pmTimeElapsed} />
			)}

			{hasPMMeter && service.pmMeterElapsed && (
				<PmMeter pm={service.pmMeterElapsed} />
			)}
		</DetailsSection>
	)
}
ServicePMSection.label = "PM"
ServicePMSection.icon = Calendar

function PmTime({ pm }: { pm: PMTimeElapsed }) {
	return (
		<DetailsSection>
			<DetailsSectionSubheading>Date</DetailsSectionSubheading>
			<GridRow columnSize="md">
				<FieldGroup label="PM Scheduled begin date">
					{pm.scheduleBeginDate}
				</FieldGroup>
				<FieldGroup label="PM due every">
					{pm.dueFrequencyValue}
					<span className="ml-1 text-sm text-foreground-weak">
						{pm.unitOfMeasure}
					</span>
				</FieldGroup>
				<FieldGroup label="notify before due">
					{pm.notifyBeforeDue}
					<span className="ml-1 text-sm text-foreground-weak">
						{pm.unitOfMeasure}
					</span>
				</FieldGroup>
				<FieldGroup label="combine other pm within">
					{pm.combineOtherPMWithin}
					<span className="ml-1 text-sm text-foreground-weak">
						{pm.unitOfMeasure}
					</span>
				</FieldGroup>
			</GridRow>
		</DetailsSection>
	)
}

function PmMeter({ pm }: { pm: PMMeterElapsed }) {
	return (
		<DetailsSection>
			<DetailsSectionSubheading>Meter</DetailsSectionSubheading>
			<GridRow columnSize="md">
				<FieldGroup label="PM begin at meter">
					{pm.scheduleBeginMeter}
				</FieldGroup>
				<FieldGroup label="PM due every">
					{pm.dueFrequencyValue}
					<span className="ml-1 text-sm text-foreground-weak">
						{pm.unitOfMeasure}
					</span>
				</FieldGroup>
				<FieldGroup label="notify before due">
					{pm.notifyBeforeDue}
					<span className="ml-1 text-sm text-foreground-weak">
						{pm.unitOfMeasure}
					</span>
				</FieldGroup>
				<FieldGroup label="combine other pm within">
					{pm.combineOtherPMWithin}
					<span className="ml-1 text-sm text-foreground-weak">
						{pm.unitOfMeasure}
					</span>
				</FieldGroup>
			</GridRow>
		</DetailsSection>
	)
}
