import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildObject,
} from "@/lib/utils/data/validators"
import { workOrderShopWatchTable } from "@/server/tables"

import { baseEmployeeSchema, baseEmployeeSelectColumns } from "../employee"

const workOrderShopWatchOmitFields = {
	scopeId: true,
} as const
export const workOrderShopWatchSelectColumns = {
	...getTableSelectColumns(
		workOrderShopWatchTable,
		workOrderShopWatchOmitFields,
	),
	employee: jsonBuildObject(baseEmployeeSelectColumns).as(
		"work_order_shop_watch_employee",
	),
}

export const workOrderShopWatchSchema = createSelectSchema(
	workOrderShopWatchTable,
)
	.omit(workOrderShopWatchOmitFields)
	.extend({
		employee: baseEmployeeSchema,
	})
export type WorkOrderShopWatch = z.infer<typeof workOrderShopWatchSchema>

export const newWorkOrderShopWatchSchema = createInsertSchema(
	workOrderShopWatchTable,
).omit(workOrderShopWatchOmitFields)
export type NewWorkOrderShopWatch = z.infer<typeof newWorkOrderShopWatchSchema>
