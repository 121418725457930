import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"
import { EllipsisIcon } from "lucide-react"

import { useDetailsState } from "@/lib/hooks/use-details-state"
import type { Location } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useAddEdit } from "@/components/shared/add-edit/provider"

export function LocationDetailsHeaderOptionsMenu({
	location,
}: {
	location: Location
}) {
	const addEdits = useAddEdit()
	const details = useDetailsState()
	const { revalidate } = useRevalidator()
	const utils = trpcClient.useUtils()
	const { mutateAsync: deleteLocation } =
		trpcClient.locations.deleteLocation.useMutation({
			onSuccess: () => {
				details.close()
				utils.locations.getLocationById.invalidate({
					id: location.id,
				})

				revalidate()
			},
		})

	const handleDelete = async () => {
		await deleteLocation({ id: location.id })
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-5" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start">
				<DropdownMenuGroup>
					<DropdownMenuLabel>Settings</DropdownMenuLabel>
				</DropdownMenuGroup>
				<DropdownMenuItem
					onClick={() => addEdits.openDialog("location", location)}
				>
					Edit
				</DropdownMenuItem>
				<DropdownMenuItem onSelect={() => handleDelete()}>
					Delete
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
