import { sql } from "drizzle-orm"
import { boolean, check, pgTable, text } from "drizzle-orm/pg-core"

export const vmrsComponentTable = pgTable(
	"vmrs_component",
	{
		code: text("code").primaryKey(),
		text: text("text").notNull(),
		isActive: boolean("is_active").notNull().default(true),
	},
	(table) => [
		check(
			"vmrs_code_check",
			// VMRS code is in the format 000-000-000
			sql`${table.code} ~ '[0-9]{3}[-][0-9]{3}[-][0-9]{3}'`,
		),
	],
)
