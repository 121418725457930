import { EllipsisIcon, Truck } from "lucide-react"

import { useDetailsState } from "@/lib/hooks/use-details-state"
import type { WorkOrder } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import { Chip, ChipContainer } from "@/components/ui/chip"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { FieldGroup } from "@/components/ui/feild-group"
import { Link } from "@/components/ui/link"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

import { WorkOrderHeaderOptionsMenu } from "../header-options-menu"

export const WorkOrderPageHeader: DetailsHeaderContent<WorkOrder> = ({
	data: workOrder,
}) => {
	const details = useDetailsState()
	return (
		<>
			<DetailsContextBar>
				<DetailsContext feature="workOrder" />
				<DetailsTopRightActions>
					<WorkOrderHeaderOptionsMenu workOrder={workOrder} />
				</DetailsTopRightActions>
			</DetailsContextBar>
			<div className="flex flex-col">
				<div className="flex grow gap-3">
					<div className="hidden size-0 items-center justify-center rounded-md bg-background-weakest text-foreground-weakest @[350px]:flex @[350px]:size-[90px]">
						<Truck size={60} />
					</div>

					<div>
						<Button
							variant="link"
							size="none"
							onClick={() =>
								details.open({
									type: "equipment",
									id: workOrder.equipment.id.toString(),
									parentId: workOrder.id.toString(),
								})
							}
						>
							<DetailsTitle>
								{workOrder.equipment.year +
									" " +
									workOrder.equipment.make +
									" " +
									workOrder.equipment.model}
							</DetailsTitle>
						</Button>

						<DetailsSubtitle>
							{workOrder.equipment.vin}
						</DetailsSubtitle>
						<ChipContainer>
							<Chip variant="primary">
								Bay {workOrder.bayNumber}
							</Chip>
						</ChipContainer>
					</div>
				</div>
				<div className="mt-3 flex flex-wrap gap-3 [&>*:not(:last-child)]:border-r [&>*:not(:last-child)]:border-border-weak [&>*:not(:last-child)]:pr-3">
					<FieldGroup label="Engine">12.7L, MP8, 415hp</FieldGroup>
					<FieldGroup label="Trans">10 MT</FieldGroup>
					<FieldGroup label="Tires">LP22.4</FieldGroup>
					<FieldGroup label="Odometer">257,893</FieldGroup>
				</div>
			</div>
		</>
	)
}
