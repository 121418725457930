import { trpcClient } from "@/trpc/client"
import { InfoIcon, PlusIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import {
	meterTypeCodeOptions,
	workOrderServiceStatusCodeOptions,
	workOrderStatusOptions,
} from "@/lib/utils/data"
import type { NewWorkOrder } from "@/server/schemas"
import {
	AddEditSection,
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const WorkOrderAddEditInfo: AddEditSectionDefinition = {
	label: "Info",
	icon: InfoIcon,
	component: (
		<AddEditSectionsGrouping>
			<Info />
			<Attachments />
		</AddEditSectionsGrouping>
	),
}

function Info() {
	const { control, setValue } = useFormContext<NewWorkOrder>()
	const { data: equipmentResult, isLoading: equipmentIsLoading } =
		trpcClient.equipment.getAllEquipment.useQuery()

	const { data: locationResult, isLoading: locationIsLoading } =
		trpcClient.locations.getAllLocations.useQuery()

	const { data: customerResult, isLoading: customerIsLoading } =
		trpcClient.customers.getAllCustomers.useQuery()

	if (!equipmentIsLoading && equipmentResult?.success === false)
		throw new Error("Error loading equipment in work order add-edit")

	if (!locationIsLoading && locationResult?.success === false)
		throw new Error("Error loading locations in work order add-edit")

	return (
		<AddEditSection>
			<AddEditSectionHeading>Info</AddEditSectionHeading>
			<FormField
				control={control}
				name="equipmentId"
				render={({ field }) =>
					equipmentResult?.success ?
						<FormItem>
							<FormLabel>Equipment</FormLabel>
							<Select
								onValueChange={(value) => {
									field.onChange(Number(value))
								}}
							>
								<FormControl>
									<SelectTrigger>
										<SelectValue placeholder="Select equipment..." />
									</SelectTrigger>
								</FormControl>

								<SelectContent>
									{equipmentResult.value.map((equipment) => (
										<SelectItem
											value={equipment.id.toString()}
											key={equipment.id}
											//onClick={() => prefillMeterType(item.meterType)} If this data becomes available on equipment
										>
											<div className="flex gap-2">
												<Chip
													variant="info"
													className="w-12 justify-center"
												>
													{equipment.unitId}
												</Chip>
												<span>
													{`${equipment.make ?? ""} ${equipment.model ?? ""}`}
												</span>
											</div>
										</SelectItem>
									))}
								</SelectContent>
							</Select>

							<FormMessage />
						</FormItem>
					:	<div>Loading equipment...</div>
				}
			/>

			<div className="flex w-full gap-3">
				<FormField
					control={control}
					name="meterReading"
					render={({ field }) => (
						<FormItem className="grow-[2]">
							<FormLabel>Meter Reading</FormLabel>
							<FormControl>
								<Input
									type="number"
									{...field}
									placeholder="130000"
									value={field.value ?? ""}
									onChange={(value) =>
										field.onChange(
											value.target.valueAsNumber,
										)
									}
								/>
							</FormControl>

							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={control}
					name="meterType"
					render={({ field }) => (
						<FormItem className="grow">
							<FormLabel>Meter Type</FormLabel>
							<Select
								onValueChange={field.onChange}
								defaultValue={field.value}
							>
								<FormControl>
									<SelectTrigger>
										<SelectValue placeholder="Select one..." />
									</SelectTrigger>
								</FormControl>
								<SelectContent>
									{meterTypeCodeOptions.map((option) => {
										return (
											<SelectItem
												value={option}
												key={option}
											>
												{option[0]?.toUpperCase() +
													option
														.slice(1)
														.toLowerCase()}
											</SelectItem>
										)
									})}
								</SelectContent>
							</Select>
							<FormMessage />
						</FormItem>
					)}
				/>
			</div>

			<FormField
				control={control}
				name="status"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Work Order Status</FormLabel>
						<Select
							onValueChange={field.onChange}
							defaultValue={field.value}
						>
							<FormControl>
								<SelectTrigger>
									<SelectValue placeholder="Select a status" />
								</SelectTrigger>
							</FormControl>
							<SelectContent>
								{workOrderStatusOptions.map((option) => {
									return (
										<SelectItem value={option} key={option}>
											{option[0]?.toUpperCase() +
												option.slice(1).toLowerCase()}
										</SelectItem>
									)
								})}
							</SelectContent>
						</Select>
						<FormMessage />
					</FormItem>
				)}
			/>
		</AddEditSection>
	)
}

function Attachments() {
	return (
		<AddEditSection>
			<AddEditSectionHeading>Attachments</AddEditSectionHeading>
			<Button
				variant="placeholder"
				role="button"
				className="w-full gap-1 border-dashed border-border text-info-text"
				onClick={(e) => e.preventDefault()}
			>
				<PlusIcon className="size-5" />
				Add Attachment
			</Button>
		</AddEditSection>
	)
}
