import type { FC } from "react"
// Uncomment above once the schema is available and remove the below import
//import type { FakeEquipment as Equipment } from "@/routes/_main+/resources+/equipment/fake-equipment"
// import type { Part } from "@/server/schemas"
// Uncomment above once the schema is available and remove the below import
// import type { FakePart as Part } from "@/routes/_main+/resources+/parts/fake-parts"
import type { FakePendingWork as PendingWork } from "@/routes/_main+/work+/pending-work/mock-data"
import type { PurchaseOrder } from "@/routes/_main+/work+/purchase-orders/mock-data"
import type { Table as TanstackTable } from "@tanstack/react-table"

import type { View } from "@/lib/hooks/use-page-url-state"
import type {
	Customer,
	Employee,
	Equipment,
	Location,
	Part,
	Service,
	Vendor,
	WorkOrder,
} from "@/server/schemas"
import { Grid } from "@/components/shared/grid"
import { CustomerQueue } from "@/components/features/customer/queue-items"
import { EmployeeQueue } from "@/components/features/employee/queue-items"
import { EquipmentQueue } from "@/components/features/equipment/queue-items"
import { LocationQueue } from "@/components/features/location/queue-items"
import { PartQueue } from "@/components/features/part/queue-items"
import { PendingWorkQueue } from "@/components/features/pending-work/queue-items"
import { PurchaseOrderQueue } from "@/components/features/purchase-order/queue"
// import { PurchaseOrderBoard } from "@/components/features/purchase-order/board"
// import { PurchaseOrderQueue } from "@/components/features/purchase-order/queue"
import { ServicesQueue } from "@/components/features/services/queue-items"
import { VendorQueue } from "@/components/features/vendor/queue-items"
import { WorkOrderBoard } from "@/components/features/work-order/board"
import { WorkOrderQueue } from "@/components/features/work-order/queue-items"

export interface SchemaMap {
	customer: Customer
	employee: Employee
	equipment: Equipment
	services: Service
	location: Location
	part: Part
	pendingWork: PendingWork
	vendor: Vendor
	workOrder: WorkOrder
	purchaseOrder: PurchaseOrder
}

export type Schema = SchemaMap[keyof SchemaMap]

interface ListViewProps<TanstackTableSchema> {
	listDataState: TanstackTable<TanstackTableSchema>
}

export type FeatureListView<TanstackTableSchema extends Schema> = Record<
	"queue",
	FC<ListViewProps<TanstackTableSchema>>
> &
	Partial<
		Record<Exclude<View, "queue">, FC<ListViewProps<TanstackTableSchema>>>
	>

export type ListViewConfig = {
	[K in keyof SchemaMap]: FeatureListView<SchemaMap[K]>
}

export const listViewConfig: ListViewConfig = {
	customer: {
		queue: CustomerQueue,
		grid: Grid,
	},
	employee: {
		queue: EmployeeQueue,
		grid: Grid,
	},
	equipment: {
		queue: EquipmentQueue,
		grid: Grid,
	},
	location: {
		queue: LocationQueue,
		grid: Grid,
	},
	part: {
		queue: PartQueue,
		grid: Grid,
	},
	pendingWork: {
		queue: PendingWorkQueue,
		grid: Grid,
	},
	services: {
		queue: ServicesQueue,
		grid: Grid,
	},
	vendor: {
		queue: VendorQueue,
		grid: Grid,
	},
	workOrder: {
		queue: WorkOrderQueue,
		grid: Grid,
		board: WorkOrderBoard,
	},
	purchaseOrder: {
		queue: PurchaseOrderQueue,
		grid: Grid,
		// board: PurchaseOrderBoard,
	},
}
