/**
 * Parses a CSV file into an list of lines, represented as a list of columns.
 * Optionally skips the first row.
 *
 * @param data CSV file data
 * @param skipFirstRow Skips the first (label) row (default: true)
 * @returns List of lines by columns
 */
export const parseCsvFile = (
	data: string,
	skipFirstRow: boolean = true,
): string[][] => {
	if (data.length === 0) return []

	// Split data into lines
	const lines = data.split(/\r?\n/)

	// Optionally skip the label row
	if (skipFirstRow) {
		lines.shift()
	}

	const res: string[][] = []
	for (const line of lines) {
		// Don't parse empty lines
		line.trim()
		if (!line) continue

		// Split comma separated values
		const columns = line.split(",")
		if (columns.length > 0) {
			res.push(columns)
		}
	}
	return res
}
