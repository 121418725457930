import type { FakePendingWork } from "@/routes/_main+/work+/pending-work/mock-data"
import { InfoIcon } from "lucide-react"

import { DetailsSectionsGrouping } from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

import { OpenWorkOrders } from "./open-work-orders"
import { PendingWork } from "./pending-work"
import { Services } from "./services"

export const PendingWorkInfoSection: DetailsSectionComponent<
	FakePendingWork
> = ({ data: pendingWork }) => (
	<DetailsSectionsGrouping>
		<Notes notes={pendingWork.notes.map((note) => note.note)} />
		<OpenWorkOrders />
		<PendingWork pendingWork={pendingWork} />
		<Services />
	</DetailsSectionsGrouping>
)
PendingWorkInfoSection.label = "Info"
PendingWorkInfoSection.icon = InfoIcon
