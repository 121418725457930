import type { PurchaseOrder } from "@/routes/_main+/work+/purchase-orders/mock-data"

import { Chip, ChipContainer } from "@/components/ui/chip"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

import { PurchaseOrderHeaderOptionsMenu } from "./header-options-menu"

// import { PurchaseOrderHeaderOptionsMenu } from "../header-options-menu"

export const PurchaseOrderPageHeader: DetailsHeaderContent<PurchaseOrder> = ({
	data: purchaseOrder,
}) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="purchaseOrder" />
			<DetailsTopRightActions>
				<PurchaseOrderHeaderOptionsMenu />
			</DetailsTopRightActions>
		</DetailsContextBar>
		<div className="flex flex-col">
			<div>
				<DetailsTitle>PO #{purchaseOrder.id}</DetailsTitle>
				<DetailsSubtitle>
					{purchaseOrder.vendor}
					<span className="mx-1 font-normal opacity-70">|</span>
					{new Date(purchaseOrder.poDate).toLocaleDateString()}
				</DetailsSubtitle>
				<ChipContainer>
					<Chip
						variant={
							purchaseOrder.status === "In Receiving" ? "success"
							: purchaseOrder.status === "Submitted" ?
								"warning"
							: purchaseOrder.status === "Closed" ?
								"primary"
							: purchaseOrder.status === "Backordered" ?
								"info"
							:	"outline"
						}
						className="capitalize"
					>
						{purchaseOrder.status}
					</Chip>
				</ChipContainer>
			</div>
		</div>
	</>
)
