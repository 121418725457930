import type { PurchaseOrder } from "@/routes/_main+/work+/purchase-orders/mock-data"
import type { Table as TanstackTable } from "@tanstack/react-table"

import { Chip } from "@/components/ui/chip"
import { QueueItemRow, QueueItems } from "@/components/ui/queue"
import { QueueView } from "@/components/shared/queue"

export function PurchaseOrderQueue({
	listDataState,
}: {
	listDataState: TanstackTable<PurchaseOrder>
}) {
	return (
		<QueueView>
			<QueueItems
				listDataState={listDataState}
				renderItem={(item) => (
					<>
						<QueueItemRow>
							<span className="truncate font-bold">
								{item.id}
							</span>
							<Chip
								variant={
									item.status === "In Receiving" ? "success"
									: item.status === "Submitted" ?
										"warning"
									: item.status === "Closed" ?
										"primary"
									: item.status === "Backordered" ?
										"info"
									:	"outline"
								}
								className="capitalize"
							>
								{item.status}
							</Chip>
						</QueueItemRow>
						<QueueItemRow variant="secondary">
							<span className="truncate">{item.vendor}</span>
							<span className="opacity-70">
								{new Intl.NumberFormat("en-US", {
									style: "currency",
									currency: "USD",
								}).format(item.total)}
							</span>
						</QueueItemRow>
					</>
				)}
				onRowClick={(purchaseOrder) => {
					listDataState.setRowSelection({
						[purchaseOrder.id]: true,
					})
				}}
				isActive={(purchaseOrder) =>
					listDataState
						.getSelectedRowModel()
						.rows.some(
							(row) => row.original.id === purchaseOrder.id,
						)
				}
			/>
		</QueueView>
	)
}
