export function assertHasIdOrThrow(
	obj: unknown,
	{ errorMessage }: { errorMessage?: string },
): asserts obj is { id: string | number } {
	const hasId =
		obj instanceof Object &&
		"id" in obj &&
		(typeof obj.id === "string" || typeof obj.id === "number")

	if (!hasId) {
		throw new Error(errorMessage ?? "does not contain an id property")
	}
}
