import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildArray,
	jsonBuildObject,
} from "@/lib/utils/data/validators"
import { alternatePartTable, partTable } from "@/server/tables"

import {
	metaSchema,
	newMetaSchema,
	newNoteSchema,
	newTagSchema,
	noteSchema,
	tagSchema,
} from "../shared"
import { partMetaSelectColumns } from "./partMeta"
import { partNoteSelectColumns } from "./partNote"
import { partTagSelectColumns } from "./partTag"
import {
	newPartTypeSchema,
	partTypeSchema,
	partTypeSelectColumns,
} from "./partType"

const partOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
	isDeleted: true,
	deletedDate: true,
} as const
export const basePartSelectColumns = {
	...getTableSelectColumns(partTable, partOmitFields),
}

const alternatePartOmitFields = {
	scopeId: true,
} as const
export const alternatePartSelectColumns = {
	...getTableSelectColumns(alternatePartTable, alternatePartOmitFields),
	alternatePart: jsonBuildObject(basePartSelectColumns),
}
export const partSelectColumns = {
	...basePartSelectColumns,
	type: jsonBuildObject(partTypeSelectColumns),
	alternateParts: jsonBuildArray(alternatePartSelectColumns),
	notes: jsonBuildArray(partNoteSelectColumns),
	tags: jsonBuildArray(partTagSelectColumns),
	metas: jsonBuildArray(partMetaSelectColumns),
}

export const basePartSchema = createSelectSchema(partTable).omit(partOmitFields)
export type BasePart = z.infer<typeof basePartSchema>

export const alternatePartSchema = createSelectSchema(alternatePartTable)
	.omit(alternatePartOmitFields)
	.extend({
		alternatePart: basePartSchema,
	})

export type AlternatePart = z.infer<typeof alternatePartSchema>

export const partSchema = basePartSchema.extend({
	type: partTypeSchema.optional(),
	alternateParts: alternatePartSchema.array().default([]),
	notes: noteSchema.array().default([]),
	tags: tagSchema.array().default([]),
	metas: metaSchema.array().default([]),
})
export type Part = z.infer<typeof partSchema>

export const newAlternatePartSchema = createInsertSchema(
	alternatePartTable,
).omit(alternatePartOmitFields)
export type NewAlternatePart = z.infer<typeof newAlternatePartSchema>

export const newPartSchema = createInsertSchema(partTable)
	.omit(partOmitFields)
	.extend({
		type: newPartTypeSchema.optional(),
		alternateParts: newAlternatePartSchema.array().default([]),
		notes: newNoteSchema.array().default([]),
		tags: newTagSchema.array().default([]),
		metas: newMetaSchema.array().default([]),
	})
export type NewPart = z.infer<typeof newPartSchema>

// Add/Edit form schema
// TODO: Add validation
export const partFormSchema = newPartSchema
