import { InfoIcon } from "lucide-react"

import type { WorkOrder } from "@/server/schemas"
import { DetailsSectionsGrouping } from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

import { WorkOrderServicesSection } from "./services"

export const WorkOrderInfoSection: DetailsSectionComponent<WorkOrder> = ({
	data: workOrder,
}) => (
	<DetailsSectionsGrouping>
		<Notes
			notes={workOrder.notes}
			feature="WORK_ORDER"
			rowId={workOrder.id}
		/>
		<WorkOrderServicesSection workOrder={workOrder} />
	</DetailsSectionsGrouping>
)
WorkOrderInfoSection.label = "Info"
WorkOrderInfoSection.icon = InfoIcon
