import { SettingsIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { NewCustomer } from "@/server/schemas"
import {
	AddEditSection,
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { Checkbox } from "@/components/ui/checkbox"
import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form"
import { Input, InputSlot } from "@/components/ui/input"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const CustomerAddEditSettings: AddEditSectionDefinition = {
	label: "Settings",
	icon: SettingsIcon,
	component: (
		<AddEditSectionsGrouping>
			<EmailNotifications />
			<Rates />
			<Accounting />
		</AddEditSectionsGrouping>
	),
}

function EmailNotifications() {
	const { control } = useFormContext<NewCustomer>()

	return (
		<AddEditSection>
			<AddEditSectionHeading>Email Notifications</AddEditSectionHeading>
			{/* ======== No field exists on the schema for the Send To email  ================*/}
			{/* <FormField
				control={control}
				name="notifyWorkOrderComplete"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Send to</FormLabel>
						<FormControl>
							<Input {...field} placeholder={"Email"}>
								<InputSlot>
									<MailIcon className="text-foreground-weaker" />
								</InputSlot>
							</Input>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/> */}

			<div className="flex gap-2">
				<FormField
					control={control}
					name="notifyWorkOrderComplete"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Work order completion?</FormLabel>
							<Select
								onValueChange={field.onChange}
								defaultValue={field.value}
							>
								<FormControl>
									<SelectTrigger>
										<SelectValue placeholder="Send an email after work order completion?" />
									</SelectTrigger>
								</FormControl>
								<SelectContent>
									<SelectItem value="yes">Yes</SelectItem>
									<SelectItem value="no">No</SelectItem>
									<SelectItem value="ask">Ask</SelectItem>
								</SelectContent>
							</Select>

							<FormDescription>
								When enabled, an email will be sent when a work
								order is done.
							</FormDescription>
						</FormItem>
					)}
				/>
				<FormField
					control={control}
					name="notifyWorkOrderNotes"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Work order completion?</FormLabel>
							<Select
								onValueChange={field.onChange}
								defaultValue={field.value}
							>
								<FormControl>
									<SelectTrigger>
										<SelectValue placeholder="Send an email when work order notes change?" />
									</SelectTrigger>
								</FormControl>
								<SelectContent>
									<SelectItem value="yes">Yes</SelectItem>
									<SelectItem value="no">No</SelectItem>
									<SelectItem value="ask">Ask</SelectItem>
								</SelectContent>
							</Select>

							<FormDescription>
								When enabled, an email will be sent when a work
								order is done.
							</FormDescription>
						</FormItem>
					)}
				/>
			</div>
		</AddEditSection>
	)
}

function Rates() {
	const { control } = useFormContext<NewCustomer>()

	return (
		<AddEditSection columned>
			<AddEditSectionHeading>Rates</AddEditSectionHeading>
			<FormField
				control={control}
				name="billing.termsCode"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Terms</FormLabel>
						<FormControl>
							<Input {...field} placeholder={"Terms"} />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="billing.standardPriceCode"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Standard Price</FormLabel>
						<FormControl>
							<Input {...field} placeholder={"Standard Price"} />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="billing.flatRatePriceCode"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Flat Rate Price</FormLabel>
						<FormControl>
							<Input {...field} placeholder={"Flat Rate Price"} />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="billing.laborRate"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Labor Rate</FormLabel>
						<FormControl>
							<Input
								{...field}
								type="number"
								placeholder="0.00"
								onChange={(e) => {
									const value =
										e.target.value === "" ?
											""
										:	Number(e.target.value)
									field.onChange(value)
								}}
								value={field.value && field.value.toString()}
							>
								<InputSlot side="start">$</InputSlot>
							</Input>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="billing.laborMarkupPercent"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Labor Markup %</FormLabel>
						<FormControl>
							<Input
								{...field}
								type="number"
								placeholder="0"
								onChange={(e) => {
									const value =
										e.target.value === "" ?
											""
										:	Number(e.target.value)
									field.onChange(value)
								}}
								value={field.value && field.value.toString()}
							>
								<InputSlot side="end">%</InputSlot>
							</Input>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="billing.fuelMarkupPercent"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Fuel Markup %</FormLabel>
						<FormControl>
							<Input
								{...field}
								type="number"
								placeholder="0"
								onChange={(e) => {
									const value =
										e.target.value === "" ?
											""
										:	Number(e.target.value)
									field.onChange(value)
								}}
								value={field.value && field.value.toString()}
							>
								<InputSlot side="end">%</InputSlot>
							</Input>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="billing.useWorkOrderSurcharge"
				render={({ field }) => (
					<FormItem className="flex flex-row items-start space-x-3 space-y-0">
						<FormControl>
							<Checkbox
								checked={field.value}
								onCheckedChange={field.onChange}
							/>
						</FormControl>
						<div className="space-y-1 leading-none">
							<FormLabel>Use Work Order Surcharge</FormLabel>
							<FormDescription>
								Use WO surcharge instead of the standard price.
							</FormDescription>
						</div>
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="billing.usePartsInvoiceSurcharge"
				render={({ field }) => (
					<FormItem className="flex flex-row items-start space-x-3 space-y-0">
						<FormControl>
							<Checkbox
								checked={field.value}
								onCheckedChange={field.onChange}
							/>
						</FormControl>
						<div className="space-y-1 leading-none">
							<FormLabel>Use Parts Invoice Surcharge</FormLabel>
							<FormDescription>
								Use parts invoice surcharge instead of the
								standard price.
							</FormDescription>
						</div>
					</FormItem>
				)}
			/>
		</AddEditSection>
	)
}

function Accounting() {
	const { control } = useFormContext<NewCustomer>()

	return (
		<AddEditSection columned>
			<AddEditSectionHeading>Accounting</AddEditSectionHeading>
			<FormField
				control={control}
				name="tax1"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Tax 1</FormLabel>
						<FormControl>
							<Input {...field} placeholder={"Taxes"}>
								<InputSlot
									side="end"
									className="text-foreground-weaker"
								>
									$
								</InputSlot>
							</Input>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			{/* <FormField
				control={control}
				name="tax2"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Tax 1</FormLabel>
						<FormControl>
							<Input {...field} placeholder={"Taxes"}>
								<InputSlot
									side="end"
									className="text-foreground-weaker"
								>
									$
								</InputSlot>
							</Input>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/> */}
			<FormField
				control={control}
				name="accountsReceivable"
				render={({ field }) => (
					<FormItem className="flex flex-row items-start space-x-3 space-y-0">
						<FormControl>
							<Checkbox
								checked={field.value}
								onCheckedChange={field.onChange}
							/>
						</FormControl>
						<FormLabel className="space-y-1 leading-none">
							Accounts Receivable
						</FormLabel>
					</FormItem>
				)}
			/>
		</AddEditSection>
	)
}
