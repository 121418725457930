import { cn } from "@/lib/utils/classnames"
import { formatFriendlyCountFromMinutes } from "@/lib/utils/time-formatting-helpers"

export const TimerProgressBar = ({
	currentTimeSeconds,
	estimatedMinutes,
	isRunning,
	className,
}: {
	currentTimeSeconds: number
	estimatedMinutes: number
	isRunning: boolean
	className?: string
}) => {
	const currentTimeMinutes = parseFloat((currentTimeSeconds / 60).toFixed(1))
	const progressPercentage = Math.min(
		(currentTimeMinutes / estimatedMinutes) * 100,
		100,
	)
	return (
		<div className={cn("flex items-center gap-2 pr-2", className)}>
			<div
				style={{
					flexGrow:
						(
							currentTimeMinutes > estimatedMinutes &&
							estimatedMinutes > 1
						) ?
							`${estimatedMinutes}`
						:	"1",
				}}
				className={cn(
					"flex h-[4px] min-w-[10px] grow overflow-hidden rounded-lg",
					isRunning ? "bg-[rgba(0,0,0,.2)]" : "bg-background",
				)}
			>
				<div
					style={{ width: `${progressPercentage}%` }}
					className={cn(
						isRunning ?
							"bg-success-foreground"
						:	"bg-primary-weak/70",
					)}
				/>
			</div>
			<div className="text-xs">
				{formatFriendlyCountFromMinutes(estimatedMinutes)}
			</div>
			{currentTimeMinutes > estimatedMinutes && (
				<>
					<div
						style={{ flexGrow: `${currentTimeMinutes}` }}
						className={cn(
							"h-[4px] min-w-[10px] max-w-[200px] overflow-hidden rounded-lg",
							isRunning ?
								"bg-success-foreground"
							:	"bg-primary-weak/70",
						)}
					/>
					<div className="text-xs">
						+{formatFriendlyCountFromMinutes(currentTimeMinutes)}
					</div>
				</>
			)}
		</div>
	)
}
