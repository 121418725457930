import { Paperclip, Plus } from "lucide-react"

import type { Service } from "@/server/schemas"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const ServiceAttachmentsSection: DetailsSectionComponent<Service> = ({
	data: service,
}) => (
	<DetailsSection>
		<Accordion type="multiple">
			<AccordionItem value="operator-inspections">
				<AccordionTrigger className="px-0">
					<DetailsSectionHeading>
						Attachments
						{/* {service.attachments && (
							<span className="font-normal text-foreground-weaker">
								({service.attachments.length})
							</span>
						)} */}
					</DetailsSectionHeading>
				</AccordionTrigger>
				<AccordionContent className="mb-5 flex flex-col gap-3 p-0 pt-2">
					{/* {service.attachments ?
						<>
							{service.attachments.map((attachment, i) => (
								<Link
									key={`service-attachment-` + i}
									to={attachment}
								>
									{attachment}
								</Link>
							))}
						</>
					:	<div className="text-sm italic text-foreground-weakest">
							No Attachments
						</div>
					} */}

					<Button variant="placeholder" className="mt-3 gap-1">
						<Plus className="size-5" /> Add Attachment
					</Button>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	</DetailsSection>
)
ServiceAttachmentsSection.label = "Attachments"
ServiceAttachmentsSection.icon = Paperclip
