import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function Invoice() {
	return (
		<DetailsSection>
			<DetailsSectionHeading>Invoice</DetailsSectionHeading>
			<div className="flex flex-col gap-1 rounded-md bg-background-weakest p-5">
				<div className="flex justify-between border-b-2 font-bold">
					<span className="truncate">Line Item</span>
					<span>Price</span>
				</div>
				<div className="flex justify-between">
					<span className="truncate">Labor</span>
					<span>$0.00</span>
				</div>
				<div className="flex justify-between">
					<span className="truncate">Parts</span>
					<span>$0.00</span>
				</div>
				<div className="flex justify-between">
					<span className="truncate">Miscellaneous</span>
					<span>$0.00</span>
				</div>
				<div className="flex justify-between">
					<span className="truncate">Outside Work</span>
					<span>$0.00</span>
				</div>
				<div className="flex justify-between">
					<span className="truncate">Tax</span>
					<span>$0.00</span>
				</div>
			</div>
		</DetailsSection>
	)
}
