import {
	AlertTriangleIcon,
	BoltIcon,
	Calendar,
	CalendarIcon,
	Wrench,
} from "lucide-react"

import { useDetailsState } from "@/lib/hooks/use-details-state"
import type {
	PMAssignedEquipment,
	PMMeterElapsed,
	PMTimeElapsed,
	Service,
} from "@/server/schemas"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardInfoLinesWrapper,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"
import { Tags } from "@/components/shared/details/tags"

//TODO: Type this
interface PreventiveMaintenanceProps {
	service: Service
}

//TODO: Implement this with real data
export function PreventiveMaintenanceCard({
	service,
}: PreventiveMaintenanceProps) {
	const bothMeterAndTime = !!(
		service.pmMeterElapsed?.id && service.pmTimeElapsed?.id
	)

	const details = useDetailsState()
	/* { purchaseOrder }: PreventiveMaintenanceProps */
	return (
		<DetailsCard className="@container">
			<div className="flex justify-between">
				<DetailsCardOverline icon={Calendar}>
					PM Event
				</DetailsCardOverline>
				{/* <Button
				variant="ghost"
				size="icon"
			>
				<EllipsisIcon className="size-4" />
			</Button> */}
			</div>

			<div>
				<div className="flex justify-between">
					<DetailsCardTitle>
						<Button
							variant="link"
							size="none"
							onClick={() =>
								details.open({
									type: "service",
									id: service.id.toString(),
									parentId: "1",
								})
							}
						>
							{service.name}
						</Button>
					</DetailsCardTitle>
					<Tags tags={service.tags} />
				</div>
				<DetailsCardInfoLinesWrapper>
					<DetailsCardInfoLine icon={CalendarIcon}>
						{bothMeterAndTime ?
							`Every ${service.pmMeterElapsed?.dueFrequencyValue} ${service.pmMeterElapsed?.unitOfMeasure} / ${service.pmTimeElapsed?.dueFrequencyValue} ${service.pmTimeElapsed?.unitOfMeasure}${(service.pmTimeElapsed?.dueFrequencyValue ?? 0 > 1) ? "s" : ""}`
						: service.pmMeterElapsed?.id ?
							`Every ${service.pmMeterElapsed?.dueFrequencyValue} ${service.pmMeterElapsed?.unitOfMeasure}`
						:	`Every ${service.pmTimeElapsed?.dueFrequencyValue} ${service.pmTimeElapsed?.unitOfMeasure}${(service.pmTimeElapsed?.dueFrequencyValue ?? 0 > 1) ? "s" : ""}`
						}
					</DetailsCardInfoLine>
				</DetailsCardInfoLinesWrapper>
				<DetailsCardInfoLinesWrapper>
					<DetailsCardInfoLine
						icon={AlertTriangleIcon}
						iconClass="text-error"
					>
						Preventive Maintenance
					</DetailsCardInfoLine>
				</DetailsCardInfoLinesWrapper>
			</div>
			<DetailsCardInfoLinesWrapper>
				<DetailsCardInfoLine icon={BoltIcon}>
					{`${service.parts.length} Part${
						service.parts.length > 1 ? "s" : ""
					}`}
				</DetailsCardInfoLine>
			</DetailsCardInfoLinesWrapper>
		</DetailsCard>
	)
}
