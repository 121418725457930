import type { FakePendingWork } from "@/routes/_main+/work+/pending-work/mock-data"
import { InfoIcon, TimerResetIcon } from "lucide-react"

import {
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

export const PendingWorkHistorySection: DetailsSectionComponent<
	FakePendingWork
> = ({ data: pendingWork }) => (
	<DetailsSectionsGrouping>
		<History pendingWork={pendingWork} />
	</DetailsSectionsGrouping>
)
PendingWorkHistorySection.label = "History"
PendingWorkHistorySection.icon = TimerResetIcon

function History({ pendingWork }: { pendingWork: FakePendingWork }) {
	return (
		<DetailsSection>
			<DetailsSectionHeading>History</DetailsSectionHeading>
			<div className="flex flex-col gap-2">
				{pendingWork.history.map((history, index) => (
					<div className="flex gap-3" key={index}>
						<span className="truncate">{history.date}</span>
						<span>{history.status}</span>
					</div>
				))}
			</div>
		</DetailsSection>
	)
}
