import { pgTable } from "drizzle-orm/pg-core"

import { noteFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { equipmentTable } from "./equipment"

export const equipmentNoteTable = pgTable(
	"equipment_note",
	noteFields(scopeTable, equipmentTable),
)
