import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildArray,
	jsonBuildObject,
} from "@/lib/utils/data/validators"
import { equipmentTable } from "@/server/tables"

import {
	metaSchema,
	newMetaSchema,
	newNoteSchema,
	newTagSchema,
	noteSchema,
	tagSchema,
} from "../shared"
import { equipmentMetaSelectColumns } from "./equipmentMeta"
import {
	equipmentMeterSchema,
	equipmentMeterSelectColumns,
	newEquipmentMeterSchema,
} from "./equipmentMeter"
import { equipmentNoteSelectColumns } from "./equipmentNote"
import {
	equipmentPositionSchema,
	equipmentPositionSelectColumns,
	newEquipmentPositionSchema,
} from "./equipmentPosition"
import { equipmentTagSelectColumns } from "./equipmentTag"
import {
	equipmentTypeSchema,
	equipmentTypeSelectColumns,
	newEquipmentTypeSchema,
} from "./equipmentType"
import {
	newRelatedEquipmentSchema,
	relatedEquipmentSchema,
	relatedEquipmentSelectColumns,
} from "./relatedEquipment"

const equipmentOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
	isDeleted: true,
	deletedDate: true,
} as const
export const baseEquipmentSelectColumns = {
	...getTableSelectColumns(equipmentTable, equipmentOmitFields),
}
export const equipmentSelectColumns = {
	...baseEquipmentSelectColumns,
	type: jsonBuildObject(equipmentTypeSelectColumns),
	relatedEquipment: jsonBuildArray(relatedEquipmentSelectColumns),
	positionHistory: jsonBuildArray(equipmentPositionSelectColumns),
	meters: jsonBuildArray(equipmentMeterSelectColumns),
	notes: jsonBuildArray(equipmentNoteSelectColumns),
	metas: jsonBuildArray(equipmentMetaSelectColumns),
	tags: jsonBuildArray(equipmentTagSelectColumns),
}

export const baseEquipmentSchema =
	createSelectSchema(equipmentTable).omit(equipmentOmitFields)
export type BaseEquipment = z.infer<typeof baseEquipmentSchema>

export const equipmentSchema = baseEquipmentSchema.extend({
	type: equipmentTypeSchema,
	relatedEquipment: relatedEquipmentSchema.array().default([]),
	positionHistory: equipmentPositionSchema.array().default([]),
	meters: equipmentMeterSchema.array().default([]),
	notes: noteSchema.array().default([]),
	metas: metaSchema.array().default([]),
	tags: tagSchema.array().default([]),
})
export type Equipment = z.infer<typeof equipmentSchema>

const equipmentFormValidation = z.object({
	year: z
		.number()
		.min(1900, "Enter a valid year.")
		.max(2100, "Enter a valid year."),
	make: z.string().min(1, "Make is required."),
	model: z.string().min(1, "Model is required."),
	vin: z.string().min(1, "VIN is required."),
	bookValue: z.number().nullable(),
	residualValue: z.number().nullable(),
	monthlyLeaseFee: z.number().nullable(),
})

export const newEquipmentSchema = createInsertSchema(equipmentTable)
	.omit(equipmentOmitFields)
	.extend({
		type: newEquipmentTypeSchema.optional(),
		relatedEquipment: newRelatedEquipmentSchema.array().default([]),
		positionHistory: newEquipmentPositionSchema.array().default([]),
		meters: newEquipmentMeterSchema.array().default([]),
		notes: newNoteSchema.array().default([]),
		metas: newMetaSchema.array().default([]),
		tags: newTagSchema.array().default([]),
	})
	.merge(equipmentFormValidation)
export type NewEquipment = z.infer<typeof newEquipmentSchema>

export const equipmentInfoSelectColumns = {
	id: baseEquipmentSelectColumns.id,
	year: baseEquipmentSelectColumns.year,
	make: baseEquipmentSelectColumns.make,
	model: baseEquipmentSelectColumns.model,
	vin: baseEquipmentSelectColumns.vin,
	name: baseEquipmentSelectColumns.name,
	color: baseEquipmentSelectColumns.color,
}
export const equipmentInfoSchema = baseEquipmentSchema.pick({
	id: true,
	year: true,
	make: true,
	model: true,
	vin: true,
	name: true,
	color: true,
})
export type EquipmentInfo = z.infer<typeof equipmentInfoSchema>
