import { integer, pgTable, serial, text } from "drizzle-orm/pg-core"

import { timestampFields, workOrderStatusOptions } from "@/lib/utils/data"

import { scopeTable } from "../user"
import { workOrderTable } from "./workOrder"

export const workOrderHistoryTable = pgTable("work_order_history", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	workOrderId: integer("work_order_id")
		.references(() => workOrderTable.id, { onDelete: "cascade" })
		.notNull(),
	clerkUserId: text("clerk_user_id"),
	status: text("status", {
		enum: workOrderStatusOptions,
	}).notNull(),
	...timestampFields,
})
