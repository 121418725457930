/* eslint-disable jsx-a11y/no-static-element-interactions */
import type { ColumnDef, RowData, Table } from "@tanstack/react-table"
import { createColumnHelper as createColumnHelperTanstack } from "@tanstack/react-table"

import { Checkbox } from "@/components/ui/checkbox"

declare module "@tanstack/react-table" {
	interface ColumnMeta<TData extends RowData, TValue> {
		dataType?: string
		plural?: string
	}
}

export const SELECT_ALL_COLUMN_ID = "select-all"

const SelectAllHeader = <T,>({ table }: { table: Table<T> }) => {
	return (
		<Checkbox
			onCheckedChange={(value) => {
				table.toggleAllRowsSelected(!!value)
			}}
			checked={table.getIsAllRowsSelected()}
		/>
	)
}

export function createColumnHelper<T>() {
	const columnHelper = createColumnHelperTanstack<T>()

	const selectAllColumn: ColumnDef<T, unknown> = columnHelper.display({
		id: SELECT_ALL_COLUMN_ID,
		header: ({ table }) => <SelectAllHeader table={table} />,
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onClick={(e) => e.stopPropagation()}
				onCheckedChange={(value) => row.toggleSelected(!!value)}
				aria-label="Select row"
				className="translate-y-0.5"
			/>
		),
		enableSorting: false,
		enableHiding: false,
	})

	return {
		selectAllColumn,
		...columnHelper,
	}
}
