"use client"

import { useCallback } from "react"

import type { NumberSliderState } from "@/lib/hooks/use-filter"
import { Input } from "@/components/ui/input"
import { Slider } from "@/components/ui/slider"

import { Button } from "./button"

export default function RangeSlider(numberSliderState: NumberSliderState) {
	const {
		minValue,
		maxValue,
		defaultValue,
		sliderState,
		inputState,
		higherPrecision,
	} = numberSliderState

	const { sliderValue, setSliderValue } = sliderState

	const { inputValues, setInputValues } = inputState

	const validateAndUpdateValue = useCallback(
		(rawValue: string, index: number) => {
			if (rawValue === "" || rawValue === "-") {
				const newInputValues = [...inputValues]
				newInputValues[index] =
					index === 0 ? String(minValue) : String(maxValue)
				setInputValues(newInputValues)

				const newSliderValues = [...sliderValue]
				newSliderValues[index] = index === 0 ? minValue : maxValue
				setSliderValue(newSliderValues)
				return
			}

			const numValue = Number.parseFloat(rawValue)

			if (isNaN(numValue)) {
				const newInputValues = [...inputValues]
				newInputValues[index] = (sliderValue[index] ?? "").toString()
				setInputValues(newInputValues)
				return
			}

			let clampedValue = Math.min(maxValue, Math.max(minValue, numValue))

			if (sliderValue.length > 1) {
				if (index === 0) {
					clampedValue = Math.min(
						clampedValue,
						sliderValue[1] ?? maxValue,
					)
				} else {
					clampedValue = Math.max(
						clampedValue,
						sliderValue[0] ?? minValue,
					)
				}
			}

			const newSliderValues = [...sliderValue]
			newSliderValues[index] = clampedValue
			setSliderValue(newSliderValues)

			const newInputValues = [...inputValues]
			newInputValues[index] = clampedValue.toString()
			setInputValues(newInputValues)
		},
		[sliderValue, inputValues, minValue, maxValue],
	)

	const handleInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, index: number) => {
			const newValue = e.target.value
			if (newValue === "" || /^-?\d*\.?\d*$/.test(newValue)) {
				const newInputValues = [...inputValues]
				newInputValues[index] = newValue
				setInputValues(newInputValues)
			}
		},
		[inputValues],
	)

	const handleSliderChange = useCallback((newValue: number[]) => {
		setSliderValue(newValue)
		setInputValues(newValue.map((v) => v.toString()))
	}, [])

	const resetToDefault = useCallback(() => {
		setSliderValue(defaultValue)
		setInputValues(defaultValue.map((v) => v.toString()))
	}, [defaultValue])

	return (
		<div className="space-x-2 space-y-3 pb-2">
			<Slider
				className="grow pt-2"
				value={sliderValue}
				onValueChange={handleSliderChange}
				min={minValue}
				max={maxValue}
				step={higherPrecision ? 0.01 : 1}
			/>
			<div className="flex items-center justify-between gap-4">
				<Input
					className="h-8"
					type="text"
					inputMode="decimal"
					value={inputValues[0]}
					onChange={(e) => handleInputChange(e, 0)}
					onBlur={() =>
						validateAndUpdateValue(inputValues[0] ?? "", 0)
					}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							validateAndUpdateValue(inputValues[0] ?? "", 0)
						}
					}}
					style={{ textAlign: "center" }}
				/>

				<Input
					className="h-8"
					type="text"
					inputMode="decimal"
					value={inputValues[1]}
					onChange={(e) => handleInputChange(e, 1)}
					onBlur={() =>
						validateAndUpdateValue(inputValues[1] ?? "", 1)
					}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							validateAndUpdateValue(inputValues[1] ?? "", 1)
						}
					}}
					style={{ textAlign: "center" }}
				/>
			</div>
			<div className="flex justify-end">
				<Button
					variant="ghost"
					size="none"
					className="ml-4 text-sm text-info"
					onClick={resetToDefault}
					disabled={
						sliderValue[0] === minValue &&
						sliderValue[1] === maxValue
					}
				>
					Reset
				</Button>
			</div>
		</div>
	)
}
