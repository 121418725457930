import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildArray,
	jsonBuildObject,
} from "@/lib/utils/data/validators"
import { serviceTable } from "@/server/tables"

import {
	newPmMeterElapsedSchema,
	newPmTimeElapsedSchema,
	pmMeterElapsedSchema,
	pmMeterElapsedSelectColumns,
	pmTimeElapsedSchema,
	pmTimeElapsedSelectColumns,
} from "../preventative_maintenance"
import {
	metaSchema,
	newMetaSchema,
	newNoteSchema,
	newTagSchema,
	noteSchema,
	tagSchema,
} from "../shared"
import { serviceMetaSelectColumns } from "./serviceMeta"
import { serviceNoteSelectColumns } from "./serviceNote"
import {
	newServicePartSchema,
	servicePartSchema,
	servicePartSelectColumns,
} from "./servicePart"
import { serviceTagSelectColumns } from "./serviceTag"

const serviceOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
	isDeleted: true,
	deletedDate: true,
} as const
export const baseServiceSelectColumns = {
	...getTableSelectColumns(serviceTable, serviceOmitFields),
}
export const serviceSelectColumns = {
	...baseServiceSelectColumns,
	parts: jsonBuildArray(servicePartSelectColumns),
	pmMeterElapsed: jsonBuildObject(pmMeterElapsedSelectColumns),
	pmTimeElapsed: jsonBuildObject(pmTimeElapsedSelectColumns),
	notes: jsonBuildArray(serviceNoteSelectColumns),
	tags: jsonBuildArray(serviceTagSelectColumns),
	metas: jsonBuildArray(serviceMetaSelectColumns),
}

export const baseServiceSchema =
	createSelectSchema(serviceTable).omit(serviceOmitFields)
export type BaseService = z.infer<typeof baseServiceSchema>

export const serviceSchema = baseServiceSchema.extend({
	parts: servicePartSchema.array().default([]),
	pmMeterElapsed: pmMeterElapsedSchema.optional(),
	pmTimeElapsed: pmTimeElapsedSchema.optional(),
	notes: noteSchema.array().default([]),
	tags: tagSchema.array().default([]),
	metas: metaSchema.array().default([]),
})
export type Service = z.infer<typeof serviceSchema>

const serviceFormValidation = z.object({
	name: z.string().min(3, "Name must be at least 3 characters."),
	flatLaborFee: z.number().nullable(),
	flatPartsFee: z.number().nullable(),
	serviceGroupId: z.number().nullable(),
})

export const newServiceSchema = createInsertSchema(serviceTable)
	.omit(serviceOmitFields)
	.extend({
		parts: newServicePartSchema.array().default([]),
		pmMeterElapsed: newPmMeterElapsedSchema.optional(),
		pmTimeElapsed: newPmTimeElapsedSchema.optional(),
		notes: newNoteSchema.array().default([]),
		tags: newTagSchema.array().default([]),
		metas: newMetaSchema.array().default([]),
	})
	.merge(serviceFormValidation)
export type NewService = z.infer<typeof newServiceSchema>
