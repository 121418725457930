import type { ZodType } from "zod"

import {
	newCustomerSchema,
	newEquipmentSchema,
	newLocationSchema,
	newServiceSchema,
	newVendorSchema,
	newWorkOrderSchema,
} from "@/server/schemas"
import type { AddEditStateProps } from "@/components/shared/add-edit"
import { CustomerAddEdit } from "@/components/features/customer/add-edit"
import { EquipmentAddEdit } from "@/components/features/equipment/add-edit"
import { LocationAddEdit } from "@/components/features/location/add-edit"
import { ServiceAddEdit } from "@/components/features/services/add-edit"
import { VendorAddEdit } from "@/components/features/vendor/add-edit"
import { WorkOrderAddEdit } from "@/components/features/work-order/add-edit"

interface ConfigEntry<T extends ZodType> {
	component: React.FunctionComponent<AddEditStateProps<any>>
	schema: T
}

export const addEditConfig = {
	customer: {
		component: CustomerAddEdit,
		schema: newCustomerSchema,
	},
	// employee: {
	// 	component: EmployeeAddEdit,
	// 	schema: employeeFormSchema,
	// },
	equipment: {
		component: EquipmentAddEdit,
		schema: newEquipmentSchema,
	},
	location: {
		component: LocationAddEdit,
		schema: newLocationSchema,
	},
	vendor: {
		component: VendorAddEdit,
		schema: newVendorSchema,
	},
	workOrder: {
		component: WorkOrderAddEdit,
		schema: newWorkOrderSchema,
	},
	service: {
		component: ServiceAddEdit,
		schema: newServiceSchema,
	},
} as const satisfies {
	[key: string]: ConfigEntry<ZodType>
}

export type AddEditConfigKeys = keyof typeof addEditConfig
