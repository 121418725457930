import type {
	ColumnDef,
	OnChangeFn,
	RowSelectionState,
} from "@tanstack/react-table"
import {
	getCoreRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { create } from "zustand"

import { SELECT_ALL_COLUMN_ID } from "../utils/create-column-helper"
import { assertHasIdOrThrow } from "../utils/has-id"
import { usePageUrlState } from "./use-page-url-state"

interface GlobalFilterStore {
	globalFilter: string
	setGlobalFilter: (filter: string) => void
}

export const useGlobalFilter = create<GlobalFilterStore>((set) => ({
	globalFilter: "",
	setGlobalFilter: (filter: string) => set({ globalFilter: filter }),
}))

interface UseListDataProps<TData, TValue> {
	data: TData[]
	columns: ColumnDef<TData, TValue>[]
	rowSelection?: RowSelectionState
	onRowSelectionChange?: OnChangeFn<RowSelectionState>
	getRowId?: (row: TData) => string
	enableMultiRowSelection?: boolean
}

export function useListData<
	TData extends {
		id: string | number
		[key: string]: any
	},
	TValue,
>({
	data,
	columns,
	rowSelection,
	onRowSelectionChange,
	getRowId,
	enableMultiRowSelection,
}: UseListDataProps<TData, TValue>) {
	const { pageState } = usePageUrlState()

	const filters = pageState.filters
	const { globalFilter } = useGlobalFilter()

	const listDataState = useReactTable({
		data,
		columns,
		state: {
			columnFilters: filters,
			globalFilter,
			...(rowSelection ? { rowSelection } : {}),
		},
		initialState: {
			columnPinning: {
				left: [SELECT_ALL_COLUMN_ID],
			},
		},
		globalFilterFn: (row, columnId, filterValue) => {
			return String(row.original[columnId])
				.toLowerCase()
				.includes(String(filterValue).toLowerCase())
		},
		onRowSelectionChange,
		enableRowSelection: true,

		enableMultiRowSelection: enableMultiRowSelection,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),

		// 1. Use provided getRowId function if available
		// 2. Otherwise expect data with string 'id' property
		// 3. Throw error if neither
		getRowId:
			getRowId ??
			((row) => {
				assertHasIdOrThrow(row, {
					errorMessage:
						"Row data must either:\n" +
						"1. Have an 'id' property, or\n" +
						"2. Be used with a custom getRowId function",
				})
				return row.id.toString()
			}),
	})

	return listDataState
}
