import { trpcClient } from "@/trpc/client"

import { DetailsViewContainer } from "@/components/shared/details/details-view"
import type { DetailsViewComponent } from "@/components/shared/details/details-view"

import { PendingWorkFinancialSection } from "./sections/financial"
import { PendingWorkHistorySection } from "./sections/history"
import { PendingWorkInfoSection } from "./sections/info"
import { PendingWorkPageHeader } from "./sections/page-header"
import { PendingWorkPanelHeader } from "./sections/panel-header"
import { PendingWorkPartsSection } from "./sections/parts"

export const PendingWorkDetailsView: DetailsViewComponent = (props) => {
	const { id: pendingWorkId } = props
	const [result] = trpcClient.pendingWork.getPendingWorkById.useSuspenseQuery(
		{
			id: parseInt(pendingWorkId),
		},
	)

	if (!result.success) {
		throw new Error("Failed to fetch pending work")
	}

	return (
		<DetailsViewContainer
			data={result.value}
			headers={{
				page: PendingWorkPageHeader,
				panel: PendingWorkPanelHeader,
			}}
			sections={{
				info: PendingWorkInfoSection,
				parts: PendingWorkPartsSection,
				financial: PendingWorkFinancialSection,
				history: PendingWorkHistorySection,
			}}
		/>
	)
}
