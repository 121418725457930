import { integer, pgTable, serial, timestamp } from "drizzle-orm/pg-core"

import { workOrderServiceTable, workOrderTable } from "."
import { employeeTable } from "../employee"
import { scopeTable } from "../user"

export const workOrderShopWatchTable = pgTable("work_order_shop_watch", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	workOrderId: integer("work_order_id")
		.references(() => workOrderTable.id, { onDelete: "cascade" })
		.notNull(),
	workOrderServiceId: integer("work_order_service_id")
		.references(() => workOrderServiceTable.id, { onDelete: "cascade" })
		.notNull(),
	employeeId: integer("employee_id")
		.references(() => employeeTable.id, {
			onDelete: "cascade",
		})
		.notNull(),
	startTime: timestamp("start_time", { mode: "string" })
		.notNull()
		.defaultNow(),
	endTime: timestamp("end_time", { mode: "string" }),
})
