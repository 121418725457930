import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildArray,
} from "@/lib/utils/data/validators"
import { vendorTable } from "@/server/tables"

import { metaSchema, newMetaSchema, newTagSchema, tagSchema } from "../shared"
import {
	newVendorContactSchema,
	vendorContactSchema,
	vendorContactSelectColumns,
} from "./vendorContact"
import { vendorMetaSelectColumns } from "./vendorMeta"
import { vendorTagSelectColumns } from "./vendorTag"

const vendorOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
	isDeleted: true,
	deletedDate: true,
} as const

export const vendorSelectColumns = {
	...getTableSelectColumns(vendorTable, vendorOmitFields),
	contacts: jsonBuildArray(vendorContactSelectColumns),
	metas: jsonBuildArray(vendorMetaSelectColumns),
	tags: jsonBuildArray(vendorTagSelectColumns),
}

export const vendorSchema = createSelectSchema(vendorTable)
	.omit(vendorOmitFields)
	.extend({
		contacts: vendorContactSchema.array().default([]),
		metas: metaSchema.array().default([]),
		tags: tagSchema.array().default([]),
	})
export type Vendor = z.infer<typeof vendorSchema>

const vendorFormValidation = z.object({
	name: z.string().min(3, "Name must be at least 3 characters."),
})

export const newVendorSchema = createInsertSchema(vendorTable)
	.omit(vendorOmitFields)
	.extend({
		contacts: newVendorContactSchema.array().default([]),
		metas: newMetaSchema.array().default([]),
		tags: newTagSchema.array().default([]),
	})
	.merge(vendorFormValidation)
export type NewVendor = z.infer<typeof newVendorSchema>
