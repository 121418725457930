import { date, integer, pgTable, serial, text } from "drizzle-orm/pg-core"

import { timestampFields, unitOfMeasureDateUnitOptions } from "@/lib/utils/data"

import { serviceTable } from "../service"
import { scopeTable } from "../user"

export const pmTimeElapsedTable = pgTable("pm_time_elapsed", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	serviceId: integer("service_id")
		.references(() => serviceTable.id, { onDelete: "cascade" })
		.notNull(),
	scheduleBeginDate: date("schedule_begin_date", {
		mode: "string",
	})
		.notNull()
		.defaultNow(),
	dueFrequencyValue: integer("due_frequency_value").notNull(),
	notifyBeforeDue: integer("notify_before_due").notNull(),
	combineOtherPMWithin: integer("combine_other_pm_within").notNull(),
	unitOfMeasure: text("unit_of_measure", {
		enum: unitOfMeasureDateUnitOptions,
	})
		.notNull()
		.default("day"),
	...timestampFields,
})
