import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { workOrderInvoiceTable } from "@/server/tables"

const workOrderInvoiceOmitFields = {
	scopeId: true,
} as const
export const workOrderInvoiceSelectColumns = {
	...getTableSelectColumns(workOrderInvoiceTable, workOrderInvoiceOmitFields),
}

export const workOrderInvoiceSchema = createSelectSchema(
	workOrderInvoiceTable,
).omit(workOrderInvoiceOmitFields)
export type WorkOrderInvoice = z.infer<typeof workOrderInvoiceSchema>

export const newWorkOrderInvoiceSchema = createInsertSchema(
	workOrderInvoiceTable,
).omit(workOrderInvoiceOmitFields)
export type NewWorkOrderInvoice = z.infer<typeof newWorkOrderInvoiceSchema>
