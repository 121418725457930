import { PlusIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function Services() {
	return (
		<DetailsSection>
			<DetailsSectionHeading>Services</DetailsSectionHeading>
			<Button variant="placeholder">
				<PlusIcon className="size-5" />
				Add Service
			</Button>
		</DetailsSection>
	)
}
