import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"

import { useInvalidator } from "@/lib/hooks/use-invalidator"
import type { NewEquipment } from "@/server/schemas"
import { newEquipmentSchema } from "@/server/schemas"
import type { AddEditStateProps } from "@/components/shared/add-edit"
import { AddEdit } from "@/components/shared/add-edit"

import { EquipmentAddEditDevices } from "./sections/devices"
import { EquipmentAddEditFinancial } from "./sections/financial"
import { EquipmentAddEditInfo } from "./sections/info"
import { EquipmentAddEditSettings } from "./sections/settings"
import { EquipmentAddEditSummary } from "./sections/summary"

// TODO: Add equipment form schema
type EquipmentAddEditProps = AddEditStateProps<typeof newEquipmentSchema>
export function EquipmentAddEdit({ ...props }: EquipmentAddEditProps) {
	const { invalidateById } = useInvalidator()
	const { mutateAsync } = trpcClient.equipment.addEditEquipment.useMutation({
		onSuccess: () => {
			invalidateById("EQUIPMENT")
		},
	})

	return (
		<AddEdit
			{...props}
			summary={<EquipmentAddEditSummary />}
			sectionDefinitions={{
				info: EquipmentAddEditInfo,
				devices: EquipmentAddEditDevices,
				financial: EquipmentAddEditFinancial,
				settings: EquipmentAddEditSettings,
			}}
			feature="equipment"
			submitHandlers={mutateAsync}
			schema={newEquipmentSchema}
			defaultValues={defaultEquipment}
		/>
	)
}

const defaultEquipment: NewEquipment = {
	year: 1900,
	make: "",
	model: "",
	vin: "",
	equipmentTypeId: 1,
	relatedEquipment: [],
	metas: [],
	tags: [],
	notes: [],
	positionHistory: [],
	meters: [],
	bookValue: null,
	residualValue: null,
	monthlyLeaseFee: null,
}
