import { trpcClient } from "@/trpc/client"

import { useInvalidator } from "@/lib/hooks/use-invalidator"
import type { NewCustomer } from "@/server/schemas"
import { newCustomerSchema } from "@/server/schemas"
import type { AddEditStateProps } from "@/components/shared/add-edit"
import { AddEdit } from "@/components/shared/add-edit"

import { CustomerAddEditContacts } from "./sections/contacts"
import { CustomerAddEditInfo } from "./sections/info"
import { CustomerAddEditSettings } from "./sections/settings"
import { CustomerAddEditSummary } from "./sections/summary"

type CustomerAddEditProps = AddEditStateProps<typeof newCustomerSchema>
export function CustomerAddEdit({ ...props }: CustomerAddEditProps) {
	const { invalidateById } = useInvalidator()
	const { mutateAsync } = trpcClient.customers.addEditCustomer.useMutation({
		onSuccess: () => {
			invalidateById("CUSTOMER")
		},
	})
	return (
		<AddEdit
			{...props}
			summary={<CustomerAddEditSummary />}
			sectionDefinitions={{
				info: CustomerAddEditInfo,
				contacts: CustomerAddEditContacts,
				settings: CustomerAddEditSettings,
			}}
			feature="customer"
			submitHandlers={mutateAsync}
			schema={newCustomerSchema}
			defaultValues={defaultCustomer}
		/>
	)
}

const defaultCustomer: NewCustomer = {
	name: "",
	pmNotificationMethod: "email",
	notifyWorkOrderComplete: "yes",
	notifyWorkOrderNotes: "yes",
	tax1: "",
	accountsReceivable: false,
	accountCode: "0000",
	billing: {
		billingType: "",
		termsCode: "",
		standardPriceCode: "",
		flatRatePriceCode: "",
		laborRate: 0,
		laborMarkupPercent: 0,
		fuelMarkupPercent: 0,
	},
	contacts: [],
	addresses: [],
	metas: [],
	tags: [],
}
