import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { pmMeterElapsedTable } from "@/server/tables"

const pmMeterElapsedOmitFields = {
	scopeId: true,
	updatedAt: true,
	createdAt: true,
} as const
export const pmMeterElapsedSelectColumns = {
	...getTableSelectColumns(pmMeterElapsedTable, pmMeterElapsedOmitFields),
}

export const pmMeterElapsedSchema = createSelectSchema(
	pmMeterElapsedTable,
).omit(pmMeterElapsedOmitFields)
export type PMMeterElapsed = z.infer<typeof pmMeterElapsedSchema>

export const newPmMeterElapsedSchema = createInsertSchema(
	pmMeterElapsedTable,
).omit(pmMeterElapsedOmitFields)
export type NewPMMeterElapsed = z.infer<typeof newPmMeterElapsedSchema>
