import { Contact, MailIcon, PhoneIcon } from "lucide-react"

import type { Location } from "@/server/schemas"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { IconField } from "@/components/ui/icon-field"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

import { LocationMap } from "../../map.client"
import { LocationDetailsHeaderOptionsMenu } from "../header-options-menu"

export const LocationPageHeader: DetailsHeaderContent<Location> = ({
	data: location,
}) => {
	return (
		<>
			<DetailsContextBar>
				<DetailsContext feature="location" />
				<DetailsTopRightActions>
					<LocationDetailsHeaderOptionsMenu location={location} />
				</DetailsTopRightActions>
			</DetailsContextBar>
			<div className="flex gap-5 pr-3 @container">
				<div className="flex grow gap-3">
					<LocationMap
						location={{
							street1: location.street1,
							street2: location.street2,
							city: location.city,
							state: location.state,
							zip: location.zip,
						}}
						className="h-[120px] w-[150px] overflow-hidden rounded-md @[500px]:w-[200px]"
					/>
					<div className="grow">
						<DetailsTitle>{location.name}</DetailsTitle>
						<DetailsSubtitle className="capitalize">
							<div>
								{location.street1} {location.street2}
							</div>
							<div>
								{location.city}, {location.state} {location.zip}
							</div>
						</DetailsSubtitle>
					</div>
				</div>
				{/* We need to figure out how to handle primary contact. */}
				{location.contacts && location.contacts[0]?.email ?
					<div className="flex flex-col gap-1.5">
						<IconField icon={Contact} variant="sm">
							{location.contacts[0]?.email}
						</IconField>
						<IconField icon={PhoneIcon} variant="sm">
							{location.contacts[0]?.phone}
						</IconField>
						<IconField icon={MailIcon} variant="sm">
							{location.contacts[0]?.name}
						</IconField>
					</div>
				:	<div className="text-sm italic text-foreground-weakest">
						No Primary Contact
					</div>
				}
			</div>
		</>
	)
}
