import { AlertCircle, Bolt, CheckCircle } from "lucide-react"

import {
	DetailsCardInfoLine,
	DetailsCardInfoLinesWrapper,
} from "@/components/ui/details-card"

import { useServiceCard } from "../context"

export const ServiceCardInfoLines = ({ className }: { className?: string }) => {
	const { service } = useServiceCard()

	return (
		<DetailsCardInfoLinesWrapper className={className}>
			<DetailsCardInfoLine icon={AlertCircle} iconClass="text-error">
				Cracked
			</DetailsCardInfoLine>
			<DetailsCardInfoLine icon={CheckCircle} iconClass="text-success">
				{service?.service.isDefault ? "Replace with new" : "No default"}
			</DetailsCardInfoLine>
			{/* <DetailsCardInfoLine icon={Bolt}>
				{service?.service.parts.length} Parts
				{service?.service.isMissingParts && (
					<span className="text-warning">(Some out of stock)</span>
				)}
			</DetailsCardInfoLine> */}
		</DetailsCardInfoLinesWrapper>
	)
}
