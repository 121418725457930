import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { metaFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { equipmentTable } from "./equipment"

export const equipmentMetaTable = pgTable(
	"equipment_meta",
	metaFields(scopeTable, equipmentTable),
	(table) => [primaryKey({ columns: [table.field, table.rowId] })],
)
