import { InfoIcon, Plus } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { NewService } from "@/server/schemas"
import {
	AddEditSection,
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form"
import { Input, InputSlot } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const ServiceAddEditInfo: AddEditSectionDefinition = {
	label: "Info",
	icon: InfoIcon,
	component: (
		<AddEditSectionsGrouping>
			<InfoGroup />
			<LaborGroup />
			<PartsGroup />
		</AddEditSectionsGrouping>
	),
}

function InfoGroup() {
	const { control } = useFormContext<NewService>()
	return (
		<AddEditSection>
			<AddEditSectionHeading>Info</AddEditSectionHeading>
			<FormField
				control={control}
				name="type"
				render={({ field }) => (
					<FormItem>
						{/* This will become a dropdown when we have a list of service types */}
						<FormLabel>Service Type</FormLabel>
						<FormControl>
							<Input {...field} placeholder={"Service Type"} />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="name"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Service Name</FormLabel>
						<FormControl>
							<Input {...field} placeholder={"Service Name"} />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="version"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Version Name</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder={"eg, Mack Anthem 2019-2025"}
								value={field.value ?? ""}
							/>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="description"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Long Description</FormLabel>
						<FormControl>
							<Textarea {...field} placeholder={"Type here..."} />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
		</AddEditSection>
	)
}

function LaborGroup() {
	const { control } = useFormContext<NewService>()
	return (
		<AddEditSection>
			<AddEditSectionHeading>Labor</AddEditSectionHeading>
			<FormField
				control={control}
				name="estimatedLaborTimeMinutes"
				render={({ field }) => (
					<FormItem className="max-w-[50%]">
						<FormLabel>Estimated Labor</FormLabel>
						<FormControl>
							<Input
								{...field}
								type="number"
								placeholder="100"
								value={field.value !== null ? field.value : ""}
								onChange={(value) =>
									field.onChange(value.target.valueAsNumber)
								}
							>
								<InputSlot side="end">Min</InputSlot>
							</Input>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<div className="flex items-end gap-3">
				<FormField
					control={control}
					name="flatLaborFee"
					render={({ field }) => (
						<FormItem className="max-w-[50%]">
							<FormLabel>Flat Labor Fee</FormLabel>
							<FormControl>
								<Input
									{...field}
									type="number"
									placeholder="100.00"
									value={field.value ?? ""}
									onChange={(value) =>
										field.onChange(
											value.target.valueAsNumber,
										)
									}
								>
									<InputSlot side="start">$</InputSlot>
								</Input>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={control}
					name="useFlatLaborFee"
					render={({ field }) => (
						<FormItem className="flex flex-row items-center space-x-3 space-y-0">
							<FormControl>
								<Checkbox
									checked={field.value}
									onCheckedChange={field.onChange}
								/>
							</FormControl>

							<FormLabel>Use Flat Labor Fee</FormLabel>
						</FormItem>
					)}
				/>
			</div>
		</AddEditSection>
	)
}

function PartsGroup() {
	const { control } = useFormContext<NewService>()
	return (
		<AddEditSection>
			<AddEditSectionHeading>Parts</AddEditSectionHeading>
			<div className="flex items-end gap-3">
				<FormField
					control={control}
					name="flatPartsFee"
					render={({ field }) => (
						<FormItem className="max-w-[50%]">
							<FormLabel>Flat Parts Fee</FormLabel>
							<FormControl>
								<Input
									{...field}
									type="number"
									placeholder="10000.00"
									value={field.value ?? ""}
									onChange={(value) =>
										field.onChange(
											value.target.valueAsNumber,
										)
									}
								>
									<InputSlot side="start">$</InputSlot>
								</Input>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={control}
					name="useFlatPartsFee"
					render={({ field }) => (
						<FormItem className="flex flex-row items-center space-x-3 space-y-0">
							<FormControl>
								<Checkbox
									checked={field.value}
									onCheckedChange={field.onChange}
								/>
							</FormControl>

							<FormLabel>Use Flat Parts Fee</FormLabel>
						</FormItem>
					)}
				/>
			</div>
			<Button
				variant="placeholder"
				className="mt-3 gap-2"
				onClick={(e) => e.preventDefault()}
			>
				<Plus size={18} /> Add Part
			</Button>
		</AddEditSection>
	)
}
