import {
	boolean,
	date,
	integer,
	pgTable,
	serial,
	text,
} from "drizzle-orm/pg-core"

import { meterTypeCodeOptions } from "@/lib/utils/data/meterType"
import { activeFields, timestampFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { equipmentTable } from "./equipment"

export const equipmentMeterTable = pgTable("equipment_meter", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	equipmentId: integer("equipment_id")
		.references(() => equipmentTable.id, { onDelete: "cascade" })
		.notNull(),
	meterType: text("meter_type", { enum: meterTypeCodeOptions }).notNull(),
	meterSource: text("meter_source"),

	initialMeterValue: integer("initial_meter_value").notNull(),
	currentMeterValue: integer("current_meter_value").notNull(),
	oldMeterValue: integer("old_meter_value"),
	newMeterStartDate: date("new_meter_start_date", { mode: "string" }),

	isPrimaryMeter: boolean("is_primary_meter").notNull().default(true),
	...activeFields,
	...timestampFields,
})
