import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildArray,
	jsonBuildObject,
} from "@/lib/utils/data/validators"
import { workOrderServiceTable } from "@/server/tables"

import {
	baseServiceSchema,
	baseServiceSelectColumns,
	servicePartSchema,
	servicePartSelectColumns,
} from "../service"
import {
	workOrderServiceEmployeeSchema,
	workOrderServiceEmployeeSelectColumns,
} from "./workOrderServiceEmployee"

const workOrderServiceOmitFields = {
	scopeId: true,
} as const
export const workOrderServiceSelectColumns = {
	...getTableSelectColumns(workOrderServiceTable, workOrderServiceOmitFields),
	service: jsonBuildObject(baseServiceSelectColumns).as(
		"work_order_service_service",
	),
	// Parts and employees MUST be aliased to work with JSON_AGG
	// Hours spent trying to not alias: 19.5h
	// If you try to undo this, make sure to increment the counter :)
	parts: jsonBuildArray(servicePartSelectColumns).as(
		"work_order_service_parts",
	),
	employees: jsonBuildArray(workOrderServiceEmployeeSelectColumns).as(
		"work_order_service_employees",
	),
}

export const workOrderServiceSchema = createSelectSchema(workOrderServiceTable)
	.omit(workOrderServiceOmitFields)
	.extend({
		service: baseServiceSchema,
		parts: servicePartSchema.array().default([]),
		employees: workOrderServiceEmployeeSchema.array().default([]),
	})
export type WorkOrderService = z.infer<typeof workOrderServiceSchema>

export const newWorkOrderServiceSchema = createInsertSchema(
	workOrderServiceTable,
)
	.omit(workOrderServiceOmitFields)
	.extend({
		employeeIds: z.number().array().default([]),
	})
export type NewWorkOrderService = z.infer<typeof newWorkOrderServiceSchema>
