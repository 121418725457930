import {
	decimal,
	integer,
	pgTable,
	serial,
	timestamp,
} from "drizzle-orm/pg-core"

import { scopeTable } from "../user"
import { equipmentTable } from "./equipment"

export const equipmentPositionTable = pgTable("equipment_position", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	equipmentId: integer("equipment_id")
		.references(() => equipmentTable.id, { onDelete: "cascade" })
		.notNull(),
	latitude: decimal("latitude", { scale: 6 }).$type<number>().notNull(),
	longitude: decimal("longitude", { scale: 6 }).$type<number>().notNull(),
	time: timestamp("time", { mode: "string" }).notNull().defaultNow(),
})
