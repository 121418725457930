import type { Feature } from "./features"

interface InternalPageConfig {
	id: string
	feature: Feature
	path: string
	meta?: {
		title?: string
		description?: string
	}
}

interface InternalPageGroup {
	id: string
	label: string
	pages: InternalPageConfig[]
}

export const pagesConfig = [
	{
		id: "work",
		label: "Work",
		pages: [
			{
				id: "work.dashboard",
				feature: "dashboard",
				path: "/work/dashboard",
				meta: {
					title: "Work Dashboard",
					description: "Overview of work activities and metrics",
				},
			},
			{
				id: "work.inventory",
				feature: "inventory",
				path: "/work/inventory",
			},
			{
				id: "work.pending-work",
				feature: "pendingWork",
				path: "/work/pending-work",
			},
			{
				id: "work.work-orders",
				feature: "workOrder",
				path: "/work/work-orders",
			},
			{
				id: "work.purchase-orders",
				feature: "purchaseOrder",
				path: "/work/purchase-orders",
			},
			{
				id: "work.maintenance",
				feature: "maintenance",
				path: "/work/maintenance",
			},
		],
	},
	{
		id: "resources",
		label: "Resources",
		pages: [
			{
				id: "resources.customers",
				feature: "customer",
				path: "/resources/customers",
			},
			{
				id: "resources.employees",
				feature: "employee",
				path: "/resources/employees",
			},
			{
				id: "resources.equipment",
				feature: "equipment",
				path: "/resources/equipment",
			},
			{
				id: "resources.locations",
				feature: "location",
				path: "/resources/locations",
			},
			{
				id: "resources.parts",
				feature: "part",
				path: "/resources/parts",
			},
			{
				id: "resources.services",
				feature: "service",
				path: "/resources/services",
			},
			{
				id: "resources.vendors",
				feature: "vendor",
				path: "/resources/vendors",
			},
		],
	},
	{
		id: "admin",
		label: "Admin",
		pages: [
			{
				id: "admin.accounting",
				feature: "accounting",
				path: "/admin/accounting",
			},
			{
				id: "admin.settings",
				feature: "settings",
				path: "/admin/settings",
			},
		],
	},
] as const satisfies InternalPageGroup[]

export type PageGroup = (typeof pagesConfig)[number]
export type PageConfig = (typeof pagesConfig)[number]["pages"][number]
export type PageId = (typeof pagesConfig)[number]["pages"][number]["id"]

export function getPageFromPathname(pathname: string): PageConfig | undefined {
	for (const group of pagesConfig) {
		const matchingPage = group.pages.find((page) => {
			// Skip external URLs
			if (page.path.startsWith("http")) return false
			// Exact match
			if (page.path === pathname) return true
			// Match dynamic routes by checking if pathname starts with page path
			// Only match if page path ends with / to avoid partial matches
			if (page.path.endsWith("/") && pathname.startsWith(page.path))
				return true
			return false
		})
		if (matchingPage) return matchingPage
	}
	return undefined
}
