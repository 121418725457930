import { DetailsInfoGroup, DetailsSection } from "@/components/ui/details-view"
import { LocationMap } from "@/components/features/location/map.client"

export function CurrentDetails() {
	return (
		<DetailsSection>
			<div className="flex flex-wrap gap-x-5 gap-y-3">
				<LocationMap
					className="size-[90px] max-w-[200px] grow overflow-hidden rounded-md"
					location={{ city: "Salt Lake City" }}
				/>
				<div className="mt-1 flex flex-col gap-3">
					<DetailsInfoGroup label="Location">
						Salt Lake City, UT
					</DetailsInfoGroup>
					<div className="flex flex-wrap gap-3">
						<DetailsInfoGroup label="Odometer">
							54,132 <span>miles</span>
						</DetailsInfoGroup>
						<DetailsInfoGroup label="Fuel">
							204 <span>gal</span>
						</DetailsInfoGroup>
					</div>
				</div>
			</div>
		</DetailsSection>
	)
}
