import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { relatedEquipmentTable } from "@/server/tables"

const relatedEquipmentOmitFields = {
	scopeId: true,
} as const
export const relatedEquipmentSelectColumns = {
	...getTableSelectColumns(relatedEquipmentTable, relatedEquipmentOmitFields),
}

export const relatedEquipmentSchema = createSelectSchema(
	relatedEquipmentTable,
).omit(relatedEquipmentOmitFields)
export type RelatedEquipment = z.infer<typeof relatedEquipmentSchema>

export const newRelatedEquipmentSchema = createInsertSchema(
	relatedEquipmentTable,
).omit(relatedEquipmentOmitFields)
export type NewRelatedEquipment = z.infer<typeof newRelatedEquipmentSchema>
