import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"

import { newVendorSchema } from "@/server/schemas"
import type { NewVendor } from "@/server/schemas"
import { AddEdit } from "@/components/shared/add-edit"
import type { AddEditStateProps } from "@/components/shared/add-edit"

import { VendorAddEditContacts } from "./sections/contacts"
import { VendorAddEditInfo } from "./sections/info"
import { VendorAddEditSettings } from "./sections/settings"
import { VendorAddEditSummary } from "./sections/summary"

type VendorAddEditProps = AddEditStateProps<typeof newVendorSchema>

export function VendorAddEdit({ ...props }: VendorAddEditProps) {
	const { revalidate } = useRevalidator()
	const utils = trpcClient.useUtils()
	const { mutateAsync } = trpcClient.vendors.addEditVendor.useMutation({
		onSuccess: () => {
			utils.vendors.getVendorById.invalidate()
			revalidate()
		},
	})
	return (
		<AddEdit
			{...props}
			summary={<VendorAddEditSummary />}
			sectionDefinitions={{
				info: VendorAddEditInfo,
				contacts: VendorAddEditContacts,
				settings: VendorAddEditSettings,
			}}
			submitHandlers={mutateAsync}
			feature="vendor"
			schema={newVendorSchema}
			defaultValues={defaultVendor}
		/>
	)
}

const defaultVendor: NewVendor = {
	name: "",
	performanceRank: 0,
	street1: "",
	city: "",
	state: "",
	contacts: [],
	metas: [],
	tags: [],
}
