import { GaugeIcon, PlusIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const EquipmentAddEditDevices: AddEditSectionDefinition = {
	label: "Devices",
	icon: GaugeIcon,
	component: <DevicesFormSection />,
}

function DevicesFormSection() {
	return (
		<Button
			variant="placeholder"
			className="w-full gap-1 border-dashed text-primary"
		>
			<PlusIcon className="size-5" />
			Add Device
		</Button>
	)
}
