import { Settings2Icon } from "lucide-react"

import type { Equipment } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const EquipmentSettingsSection: DetailsSectionComponent<Equipment> = ({
	data: equipment,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Settings</DetailsSectionHeading>
		<DetailsInfoGroup label="List all mfg">Yes</DetailsInfoGroup>
	</DetailsSection>
)
EquipmentSettingsSection.label = "Settings"
EquipmentSettingsSection.icon = Settings2Icon
