import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { metaFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { workOrderTable } from "./workOrder"

export const workOrderMetaTable = pgTable(
	"work_order_meta",
	metaFields(scopeTable, workOrderTable),
	(table) => [primaryKey({ columns: [table.field, table.rowId] })],
)
