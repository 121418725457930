import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { workOrderBillingTable } from "@/server/tables"

const workOrderBillingOmitFields = {
	scopeId: true,
} as const
export const workOrderBillingSelectColumns = {
	...getTableSelectColumns(workOrderBillingTable, workOrderBillingOmitFields),
}

export const workOrderBillingSchema = createSelectSchema(
	workOrderBillingTable,
).omit(workOrderBillingOmitFields)
export type WorkOrderBilling = z.infer<typeof workOrderBillingSchema>

export const newWorkOrderBillingSchema = createInsertSchema(
	workOrderBillingTable,
).omit(workOrderBillingOmitFields)
export type NewWorkOrderBilling = z.infer<typeof newWorkOrderBillingSchema>
