import { useEffect, useState } from "react"
import { useTheme } from "@/routes/utils+/theme-switch"
import {
	APIProvider,
	Map,
	Marker,
	useMarkerRef,
} from "@vis.gl/react-google-maps"

import { cn } from "@/lib/utils/classnames"

export interface MapLocation {
	street1?: string | null
	street2?: string | null
	city?: string | null
	state?: string | null
	zip?: string | null
	lat?: number | null
	lng?: number | null
}

export function LocationMap({
	location,
	zoom = 11,
	className,
}: {
	location?: MapLocation
	zoom?: number
	className?: string
}) {
	const [position, setPosition] = useState<
		{ lat: number; lng: number } | undefined
	>()
	const apiKey = "AIzaSyAvpju7-ECc-tmPxRek_NLrl56z33JvRrU"
	const [markerRef, marker] = useMarkerRef()
	const theme = useTheme()

	useEffect(() => {
		if (!marker) {
			return
		}
	}, [marker])

	useEffect(() => {
		if (!location) return

		const addressParts = [
			location.street1,
			location.street2,
			location.city,
			location.state,
			location.zip,
		].filter(Boolean) as string[]

		const address = addressParts.join(", ")

		const geocodeAddress = async () => {
			try {
				const response = await fetch(
					`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`,
				)

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const data: any = await response.json()

				if (data.status === "OK" && data.results.length > 0) {
					const { lat, lng } = data.results[0].geometry.location
					setPosition({ lat: lat, lng: lng })
				} else {
					console.error("Geocoding failed:", data.status)
				}
			} catch (error) {
				console.error("Error geocoding address:", error)
			}
		}

		if (location.lat && location.lng) {
			setPosition({ lat: location.lat, lng: location.lng })
		} else {
			geocodeAddress()
		}
	}, [location])

	const handleMapClick = () => {
		if (!position) return
		const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${position.lat},${position.lng}`
		window.open(mapsUrl, "_blank", "noopener,noreferrer")
	}

	return (
		<APIProvider apiKey={apiKey}>
			<Map
				fullscreenControl={false}
				center={position || { lat: 38.409, lng: -98.45 }} // Fallback center to Kansas somewhere
				zoom={position ? zoom : 2} // Zoom closer when position exists
				className={cn("size-32", className)}
				colorScheme={theme == "dark" ? "DARK" : "LIGHT"}
				draggableCursor={"pointer"}
				disableDefaultUI={false}
				onClick={handleMapClick}
			>
				<Marker ref={markerRef} position={position} />
			</Map>
		</APIProvider>
	)
}
