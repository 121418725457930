import { z } from "zod"

export const tagColorNameOptions = [
	"GRAY",
	"RED",
	"ORANGE",
	"YELLOW",
	"GREEN",
	"TEAL",
	"PURPLE",
	"BERRY",
] as const
export type TagColor = (typeof tagColorNameOptions)[number]

export const tagColorHexOptions = [
	{ color: "GRAY", hex: "#737E97" },
	{ color: "RED", hex: "#C82C2C" },
	{ color: "ORANGE", hex: "#FF9F1A" },
	{ color: "YELLOW", hex: "#FFE81A" },
	{ color: "GREEN", hex: "#62CC4D" },
	{ color: "TEAL", hex: "#218380" },
	{ color: "PURPLE", hex: "#7634A5" },
	{ color: "BERRY", hex: "#BF1792" },
] as const

export function getTagColorHex(color: TagColor): string {
	return (
		tagColorHexOptions.find((elem) => elem.color === color)?.hex ??
		"#737E97"
	)
}

export const tagOptionFeatureOptions = [
	"EQUIPMENT",
	"CUSTOMER",
	"EMPLOYEE",
	"LOCATION",
	"WORK_ORDER",
	"PURCHASE_ORDER",
	"SERVICE",
	"PART",
	"VENDOR",
] as const
export type TagOptionFeature = (typeof tagOptionFeatureOptions)[number]
export const tagOptionFeatureSchema = z.enum(tagOptionFeatureOptions)
