import type { WorkOrder } from "@/routes/_main+/work+/work-orders/columns"
import { Receipt } from "lucide-react"

import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const WorkOrderInvoiceSection: DetailsSectionComponent<WorkOrder> = ({
	data: workOrder,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Invoice</DetailsSectionHeading>
		<div className="flex flex-col rounded-md bg-darken-weaker p-3">
			<div className="mb-1 flex justify-between border-b pb-1 text-sm font-bold">
				<div>Line Item</div>
				<div>Price</div>
			</div>
			<div className="flex justify-between">
				<div>Labor</div>
				<div>$212.44</div>
			</div>
			<div className="flex justify-between">
				<div>Parts</div>
				<div>$499.30</div>
			</div>
			<div className="flex justify-between">
				<div>Misc</div>
				<div>$0</div>
			</div>
			<div className="flex justify-between">
				<div>Outside work</div>
				<div>$0</div>
			</div>
			<div className="flex justify-between">
				<div>Tax</div>
				<div>$43.69</div>
			</div>
			<div className="mt-1.5 flex justify-between border-t pt-0.5">
				<div />
				<div className="font-bold">$755.53</div>
			</div>
		</div>
	</DetailsSection>
)
WorkOrderInvoiceSection.label = "Invoice"
WorkOrderInvoiceSection.icon = Receipt
