import { integer, pgTable, serial, text, unique } from "drizzle-orm/pg-core"

import { scopeTable } from "../user"

export const serviceGroupTable = pgTable(
	"service_group",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		code: text("code").notNull(),
	},
	(table) => [unique().on(table.scopeId, table.code)],
)
