import { User } from "lucide-react"

import type { WorkOrderService } from "@/server/schemas"
import { Button } from "@/components/ui/button"

import { ServiceCardProvider, useServiceCard } from "./context"
import { ServiceCardHeader } from "./shared/header"
import { ServiceCardInfoLines } from "./shared/info-lines"
import { ServiceCardAssignedUsers } from "./shared/manage-assignment"
import { ServiceCardTimeTotal } from "./shared/time-completed"
import { ServiceCardTimeTracker } from "./shared/time-tracker"
import { ServiceCardTitles } from "./shared/titles"

export function ServiceCard({ service }: { service: WorkOrderService }) {
	return (
		<ServiceCardProvider initialService={service}>
			<div className="rounded-lg border p-2.5 pt-1">
				<ServiceCardHeader />
				<ServiceCardTitles />
				<ServiceCardInfoLines />
				{service?.status == "IN PROGRESS" ?
					<ServiceCardAssignedFooter />
				:	<ServiceCardUnassignedFooter />}
			</div>
		</ServiceCardProvider>
	)
}

function ServiceCardAssignedFooter() {
	const { service } = useServiceCard()

	return (
		<div className="mt-1 flex items-end justify-between gap-3">
			<ServiceCardAssignedUsers />

			{service?.status == "IN PROGRESS" && (
				<div className="flex flex-col items-end gap-1">
					<ServiceCardTimeTracker />
				</div>
			)}

			{service?.status == "COMPLETE" && (
				<div className="flex flex-col items-end">
					<ServiceCardTimeTotal />
				</div>
			)}
		</div>
	)
}

function ServiceCardUnassignedFooter() {
	return (
		<div className="mt-3 flex items-end justify-between gap-3">
			<Button size="sm">
				<User size={16} className="mr-2" /> Assign
			</Button>
			<div className="shrink-0">
				est: <b>1h 30m</b>
			</div>
		</div>
	)
}
