import { decimal, integer, pgTable, serial } from "drizzle-orm/pg-core"

import { partTable } from "../part"
import { scopeTable } from "../user"
import { serviceTable } from "./service"

export const servicePartTable = pgTable("service_part", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	serviceId: integer("service_id")
		.references(() => serviceTable.id, {
			onDelete: "cascade",
		})
		.notNull(),
	partId: integer("part_id")
		.references(() => partTable.id, { onDelete: "cascade" })
		.notNull(),
	quantity: integer("quantity").notNull(),
	unitCost: decimal("unit_cost", { scale: 2 }).$type<number>().notNull(),
})
