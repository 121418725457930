import { useFormContext } from "react-hook-form"

import { areAnyStringsDefined } from "@/lib/utils/any-strings-defined"
import { cn } from "@/lib/utils/classnames"
import type { NewLocation } from "@/server/schemas"
import { AddEditSummary } from "@/components/ui/add-edit"
import { AddTagButton } from "@/components/shared/add-edit/add-tag"
import { Tags } from "@/components/shared/details/tags"

import { LocationMap } from "../../map.client"
import type { MapLocation } from "../../map.client"

export const LocationAddEditSummary = () => {
	const { watch } = useFormContext<NewLocation>()
	const formValues = watch()
	const anyAddress = areAnyStringsDefined(
		formValues.street1,
		formValues.street2,
		formValues.city,
		formValues.state,
		formValues.zip,
	)

	const location: MapLocation = {
		street1: formValues.street1,
		street2: formValues.street2 || "",
		city: formValues.city,
		state: formValues.state,
		zip: formValues.zip || "",
	}

	return (
		<AddEditSummary>
			<LocationMap
				className="aspect-square w-full"
				location={formValues.city || formValues.zip ? location : {}}
			/>
			<Tags tags={formValues.tags} centered />
			<AddTagButton feature="LOCATION" />
			<p
				className={cn(
					"font-semibold",
					formValues.name ?
						"text-foreground-strong"
					:	"text-foreground-weak",
				)}
			>
				{formValues.name ? formValues.name : <>Location Name</>}
			</p>
			<p
				className={
					formValues.id ?
						"text-foreground-strong"
					:	"text-foreground-weaker"
				}
			>
				{formValues.id ? formValues.id : <>ID</>}
			</p>
			<section
				className={cn(
					"text-center font-semibold",
					anyAddress ?
						"text-foreground-strong"
					:	"text-foreground-weaker",
				)}
			>
				{anyAddress ?
					<>
						<p>
							{formValues.street1} {formValues.street2}
						</p>
						<p>
							{formValues.city}
							{formValues.city && formValues.state && ", "}
							{formValues.state} {formValues.zip}
						</p>
					</>
				:	<p>Address</p>}
			</section>
		</AddEditSummary>
	)
}
