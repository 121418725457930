import { pgTable } from "drizzle-orm/pg-core"

import { noteFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { serviceTable } from "./service"

export const serviceNoteTable = pgTable(
	"service_note",
	noteFields(scopeTable, serviceTable),
)
