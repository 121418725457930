import { forwardRef } from "react"
import { useLocation } from "@remix-run/react"
import type { LucideIcon } from "lucide-react"
import { ChevronLeft, XIcon } from "lucide-react"
import { motion } from "motion/react"

import type { Feature } from "@/config/features"
import { featureConfig } from "@/config/features"
import { getPageFromPathname } from "@/config/pages"
import { usePageUrlState } from "@/lib/hooks/use-page-url-state"
import { cn } from "@/lib/utils/classnames"

import { useDetailsState } from "../../lib/hooks/use-details-state"
import { Button } from "./button"
import { Separator } from "./separator"
import { Skeleton } from "./skeleton"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./tabs"
import { OverlineSmall } from "./typography"

/**
 * @description			A container for the header portion of a details page/panel. Usually contains a {@link DetailsContextBar}.
 */
const DetailsHeader = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div ref={ref} {...props} className={cn("px-6 pt-1", className)}>
		{children}
	</div>
))
DetailsHeader.displayName = "DetailsHeader"

/**
 * @description 		Large, bold heading used for the most prominent info on a details page/panel. e.g. A customer's name.
 */
const DetailsTitle = forwardRef<
	HTMLHeadingElement,
	React.HTMLProps<HTMLHeadingElement>
>(({ children, className, ...props }, ref) => (
	<h1
		ref={ref}
		{...props}
		className={cn(
			"truncate text-xl font-extrabold max-sm:text-lg",
			className,
		)}
	>
		{children}
	</h1>
))
DetailsTitle.displayName = "DetailsTitle"

/**
 * @description 		Small, uppercase heading used for secondary info on a details page/panel. e.g. A customer's ID.
 */
const DetailsSubtitle = forwardRef<
	HTMLHeadingElement,
	React.HTMLProps<HTMLHeadingElement>
>(({ children, className, ...props }, ref) => (
	<h2
		ref={ref}
		{...props}
		className={cn(
			"truncate text-sm uppercase text-foreground-weak",
			className,
		)}
	>
		{children}
	</h2>
))
DetailsSubtitle.displayName = "DetailSubtitle"

/**
 * @description 		This is meant to be used as a wrapper around {@link DetailsContext} and {@link DetailsTopRightActions}.
 * @example
 * <DetailsContextBar>
 * 	<DetailsContext feature="customer" />
 * 	<DetailsTopRightActions>
 * 		<DropdownMenu />
 * 	</DetailsTopRightActions>
 * </DetailsContextBar>
 *
 */
const DetailsContextBar = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn(
			"mb-2 flex w-full items-center justify-between",
			className,
		)}
	>
		{children}
	</div>
))
DetailsContextBar.displayName = "DetailsContextBar"

interface DetailsContextProps {
	feature: Feature
}
/**
 * @description 		Displays a details' feature name and Icon. Supports a nested heirarchy if details feature doesn't match the current page.
 * @param feature 		The feature represented in this details page/panel.
 */
export const DetailsContext = ({ feature }: DetailsContextProps) => {
	const { pathname } = useLocation()
	const page = getPageFromPathname(pathname)
	if (!page) return null

	const CurrentPageIcon = featureConfig[page.feature].icon
	const NestedPageIcon = featureConfig[feature].icon

	return (
		<div className="flex items-center gap-1">
			{page.feature == feature ?
				<>
					<CurrentPageIcon className="size-4 text-foreground-weak" />
					<span className="text-sm font-medium uppercase tracking-wider text-primary dark:text-primary-text">
						{featureConfig[page.feature].labels.singular}
					</span>
				</>
			:	<>
					<NestedPageIcon className="size-4 text-foreground-weak" />
					<span className="text-sm font-medium uppercase tracking-wider text-primary">
						{featureConfig[feature].labels.singular}
					</span>
				</>
			}
		</div>
	)
}

/**
 * @description 		Displays a {@link DetailsPanelCloseButton} unless in a queue view. Allows for additional items to be added.
 * @example
 * //The close button is baked in automatically, no need to add it manually.
 * <DetailsTopRightActions>
 *		<DropdownMenu />
 * </DetailsTopRightActions>
 */
const DetailsTopRightActions = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
	const { pageState } = usePageUrlState()

	return (
		<div
			ref={ref}
			{...props}
			className={cn("flex items-center gap-2", className)}
		>
			{children}
			{pageState.view !== "queue" ?
				<DetailsPanelCloseButton />
			:	<DetailsPanelCloseButton className="md:hidden" />}
		</div>
	)
})
DetailsTopRightActions.displayName = "DetailsTopRightActions"

/**
 * @description 		A button to close the details panel. It is baked into the {@link DetailsTopRightActions} component. You shouldn't import this component directly.
 */
const DetailsPanelCloseButton = ({ className }: { className?: string }) => {
	const details = useDetailsState()

	return (
		<Button
			variant="ghost"
			size="icon"
			onClick={() => {
				details.close()
			}}
			className={cn(className)}
		>
			<XIcon className="size-4" />
		</Button>
	)
}

/**
 * @description 		A container to hold multiple {@link DetailsSection} components and add a gap between them. Also serves as the individual columns in a {@link DetailsPageBody} component.
 * @example
 * //The first DetailsContent holds multiple sections, while the next just acts as a column.
 * <DetailsPageBody>
 * 	<DetailsContent>
 * 		<InfoSection />
 * 		<Notes />
 * 	</DetailsContent>
 * 	<DetailsContent>
 * 		<HistorySection />
 * 	</DetailsContent>
 * </DetailsPageBody>
 */
const DetailsContent = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn("flex h-full flex-col gap-4", className)}
	/>
))
DetailsContent.displayName = "DetailsContent"

/**
 * @description  		A wrapper for a group of sections you want to wrap together so that they move between columns as a whole instead of individually as a lone DetailSection, keeps larger gaps/spacing
 */
const DetailsSectionsGrouping = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ className, ...props }, ref) => {
	return (
		<div
			className={cn(`flex flex-col gap-6`, className)}
			{...props}
			ref={ref}
		/>
	)
})
DetailsSectionsGrouping.displayName = "DetailsSectionsGrouping"

/**
 * @description  		A container for a single section of information. Can optionally be columned.
 * @param columned 		Whether or not the section should be displayed in 2 columns.
 */
const DetailsSection = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement> & { columned?: boolean }
>(({ className, columned = false, ...props }, ref) => {
	return (
		<div
			className={cn(
				`flex flex-col gap-2`,
				columned && "grid grid-cols-2",
				className,
			)}
			{...props}
			ref={ref}
		/>
	)
})
DetailsSection.displayName = "DetailsSection"

/**
 * @description			A large, bold heading used to label a {@link DetailsSection}. Can optionally include an icon.
 * @param icon 			An optional icon to be displayed to the left of the heading.
 */
const DetailsSectionHeading = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement> & { icon?: LucideIcon }
>(({ children, className, icon: Icon, ...props }, ref) => (
	<div
		className={cn(
			"mb-1 flex items-center gap-2 text-lg font-bold text-foreground",
			className,
		)}
		{...props}
		ref={ref}
	>
		{Icon && <Icon className="size-6" />}
		{children}
	</div>
))
DetailsSectionHeading.displayName = "DetailsSectionHeading"

/**
 * @description			Secondary text used inside a {@link DetailsSectionHeading}.
 */

const DetailsSectionHeadingCount = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		className={cn("font-normal text-foreground-weaker", className)}
		{...props}
		ref={ref}
	>
		{children}
	</div>
))
DetailsSectionHeadingCount.displayName = "DetailsSectionHeadingCount"

/**
 * @description			A large, bold heading used to label a {@link DetailsSection}. Can optionally include an icon.
 * @param icon 			An optional icon to be displayed to the left of the heading.
 */
const DetailsSectionSubheading = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement> & { icon?: LucideIcon }
>(({ children, className, icon: Icon, ...props }, ref) => (
	<div
		className={cn(
			"mb-3 flex items-center gap-2 font-bold text-foreground",
			className,
		)}
		{...props}
		ref={ref}
	>
		{Icon && <Icon className="size-6" />}
		{children}
	</div>
))
DetailsSectionSubheading.displayName = "DetailsSectionSubheading"

const DetailsTabs = forwardRef<
	React.ElementRef<typeof Tabs>,
	React.ComponentPropsWithoutRef<typeof Tabs>
>(({ className, ...props }, ref) => (
	<Tabs ref={ref} className={cn(className)} {...props} />
))
DetailsTabs.displayName = "DetailsTabs"

const DetailsTabsList = forwardRef<
	React.ElementRef<typeof TabsList>,
	React.ComponentPropsWithoutRef<typeof TabsList>
>(({ className, ...props }, ref) => (
	<>
		<TabsList
			ref={ref}
			className={cn("relative z-10 flex w-full justify-start", className)}
			{...props}
		/>
		<Separator className="z-0 mt-1 h-[4px] translate-y-[-4px] bg-border-weak" />
	</>
))
DetailsTabsList.displayName = "DetailsTabsList"

interface DetailsTabsTriggerProps
	extends React.ComponentPropsWithRef<typeof TabsTrigger> {
	Icon?: LucideIcon
}
export const DetailsTabsTrigger = ({
	className,
	Icon,
	ref,
	children,
	...props
}: DetailsTabsTriggerProps) => {
	return (
		<TabsTrigger
			ref={ref}
			className={cn("grow gap-1 @container", className)}
			{...props}
		>
			{Icon && <Icon className="size-6 shrink-0" />}
			<span className="hidden @[90px]:block">{children}</span>
		</TabsTrigger>
	)
}

const DetailsTabsContent = forwardRef<
	React.ElementRef<typeof TabsContent>,
	React.ComponentPropsWithoutRef<typeof TabsContent>
>(({ className, children, ...props }, ref) => (
	<TabsContent ref={ref} className={cn("flex-1 p-0", className)} {...props}>
		<DetailsContent>{children}</DetailsContent>
	</TabsContent>
))
DetailsTabsContent.displayName = "DetailsTabsContent"

interface DetailsInfoGroupsWrapperProps
	extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode
}
/**
 * @description 		A wrapper of multiple DetailsInfoGroups
 * @children 			The text to display.
 * @example
 * <DetailsInfoGroupsWrapper>
 * 	<DetailsInfoGroup label="Email">
 * 	 test_email@domain.com
 *  </DetailsInfoGroup>
 *  <DetailsInfoGroup label="Phone">
 * 	 (281) 330-8004
 *  </DetailsInfoGroup>
 * </DetailsInfoGroupsWrapper>
 */
export const DetailsInfoGroupsWrapper: React.FC<
	DetailsInfoGroupsWrapperProps
> = ({ children, className }) => {
	return (
		<div className={cn("flex flex-col gap-3", className)}>{children}</div>
	)
}

interface DetailsInfoGroupProps {
	label: string
	children: React.ReactNode
	className?: string
	inline?: boolean
	indicateEmpty?: boolean
}
/**
 * @description 		A helper function to show information displayable as a label and a value.
 * @param label 		The label to display.
 * @children 			The text to display.
 * @example
 * <DetailsInfoGroup label="Email">
 * 	test_email@domain.com
 * </DetailsInfoGroup>
 */
export const DetailsInfoGroup: React.FC<DetailsInfoGroupProps> = ({
	label,
	children,
	className,
	inline = false,
	indicateEmpty = true,
}) => {
	const classes = inline ? "justify-between items-center" : "flex-col"
	return (
		<div className={cn(classes, className, "flex gap-0.5")}>
			<OverlineSmall className="shrink-0">{label}</OverlineSmall>
			<div
				className={cn(
					"truncate capitalize",
					indicateEmpty &&
						"[&:empty]:after:text-sm [&:empty]:after:text-foreground-weakest [&:empty]:after:content-['--']",
				)}
			>
				{children}
			</div>
		</div>
	)
}

// ============================================================================
// Panel-specific components
// ============================================================================

const DetailsPanel = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn("flex size-full flex-col overflow-hidden", className)}
	>
		{children}
	</div>
))
DetailsPanel.displayName = "DetailsPanel"

//TODO: move this somewhere else maybe + make one for pages
function DetailsPanelSkeleton() {
	return (
		<DetailsPanel>
			<Skeleton className="h-6 w-32" />

			<Skeleton className="h-4 w-full" />
			<Skeleton className="h-4 w-3/4" />
			<Skeleton className="h-4 w-1/2" />
		</DetailsPanel>
	)
}

export const DetailsPanelBody: React.FC<
	React.HTMLAttributes<HTMLDivElement>
> = ({ className, ...props }) => {
	return (
		<div
			className={cn(
				"flex flex-1 flex-col gap-2 overflow-auto px-6 py-3",
				className,
			)}
			{...props}
		/>
	)
}

// ============================================================================
// Page-specific components
// ============================================================================

export const DetailsPage = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn("flex flex-col overflow-hidden", className)}
	>
		{children}
	</div>
))
DetailsPage.displayName = "DetailsPage"

export const DetailsPageBody: React.FC<
	React.HTMLAttributes<HTMLDivElement>
> = ({ className, children }) => {
	return (
		<div
			className={cn(
				"overflow-auto p-6 [scrollbar-width:thin]",
				className,
			)}
		>
			{children}
		</div>
	)
}

const breadcrumbVariants = {
	hidden: {
		y: 40,
		opacity: 0,
		height: 0,
	},
	visible: {
		y: 0,
		height: 40,
		opacity: 1,
		transition: {
			type: "spring",
			stiffness: 120,
			damping: 6,
			mass: 0.15,
		},
	},
	exit: {
		y: 40,
		height: 40,
		opacity: 0,
	},
}

export function DetailsReturn() {
	const { open, rootDetails } = useDetailsState()

	return (
		<motion.div
			className={cn(
				"z-0 mb-[-5px] flex h-[40px] shrink-0 cursor-pointer items-center gap-1.5 truncate rounded-t-md bg-primary px-5 pb-[5px] capitalize text-primary-foreground hover:bg-primary-hover max-md:rounded-none [&:hover>*[data-back-arrow]]:translate-x-[-1.5px] [&:hover>*[data-back-arrow]]:scale-110",
			)}
			variants={breadcrumbVariants}
			initial={breadcrumbVariants.hidden}
			animate={breadcrumbVariants.visible}
			exit={breadcrumbVariants.exit}
			onClick={() => {
				if (rootDetails.id) {
					open(rootDetails)
				}
			}}
		>
			<ChevronLeft
				data-back-arrow
				className="size-4 shrink-0 translate-x-0 scale-100 transition duration-300 ease-in-out"
			/>
			<div className="truncate">{`${rootDetails.type} #${rootDetails.id}`}</div>
		</motion.div>
	)
}

export {
	DetailsContent,
	DetailsContextBar,
	DetailsHeader,
	DetailsPanel,
	DetailsPanelSkeleton,
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionHeadingCount,
	DetailsSectionsGrouping,
	DetailsSectionSubheading,
	DetailsSubtitle,
	DetailsTabs,
	DetailsTabsContent,
	DetailsTabsList,
	DetailsTitle,
	DetailsTopRightActions,
}
