import type { Dispatch, SetStateAction } from "react"
import { useEffect, useMemo, useState } from "react"
import { trpcClient } from "@/trpc/client"
import type { Table as TanstackTable } from "@tanstack/react-table"
import { parseISO } from "date-fns"
import type { DateRange } from "react-day-picker"
import { create } from "zustand"

import type { SavedViewName } from "../utils/data/savedView"
import { usePageId } from "./use-page-id"
import { usePageUrlState } from "./use-page-url-state"

// The maximum number of filter items that can be displayed in a collapsed state.
export const MAX_COLLAPSED_FILTERS = 5

interface FilterPanel {
	isOpen: boolean
	toggleFilter: () => void
	open: () => void
	close: () => void
}

interface ColumnFilter {
	id: string
	value: any
}

export const useFilterPanel = create<FilterPanel>((set) => ({
	isOpen: false,
	toggleFilter: () => set((state) => ({ isOpen: !state.isOpen })),
	open: () => set({ isOpen: true }),
	close: () => set({ isOpen: false }),
}))

interface SavedFilterState {
	selectedSavedFilter: SelectedSavedFilter | "toggled" | undefined
	modified: boolean
	setModified: (modified: boolean) => void
	setSelectedSavedFilter: (
		filter: SelectedSavedFilter | "toggled" | undefined,
	) => void
}

type ToggleExpand = Record<string, boolean>

interface ColumnFilter {
	id: string
	value: any
}

interface SelectedSavedFilter {
	id: number
	filterName: string
	filters: ColumnFilter[]
	route: string
}

interface FilterOptions {
	columnId: string
	header: string
	plural: string
	dataType: string
	uniqueValues: {
		value: any
		count: number
	}[]
}

interface DateState {
	date: DateRange
	setDate: Dispatch<SetStateAction<DateRange>>
}

export interface DateFilterState {
	dateState: DateState
	earliestDate: Date
	latestDate: Date
	dates: Date[]
}

type DateFilter = Record<string, DateFilterState>

interface InputValueState {
	inputValues: string[]
	setInputValues: Dispatch<SetStateAction<string[]>>
}

interface SliderValueState {
	sliderValue: number[]
	setSliderValue: Dispatch<SetStateAction<number[]>>
}

export interface NumberSliderState {
	minValue: number
	maxValue: number
	initialValue: number[]
	defaultValue: number[]
	sliderState: SliderValueState
	inputState: InputValueState
	higherPrecision: boolean
}

type NumberFilter = Record<string, NumberSliderState>

export interface FilterSaveStore {
	filterName: string | undefined
	setFilterName: (filterName: string | undefined) => void
}

export const useSavedFilterStore = create<SavedFilterState>((set) => ({
	selectedSavedFilter: undefined,
	modified: false,
	setModified: (modified) => set({ modified }),
	setSelectedSavedFilter: (filter) => set({ selectedSavedFilter: filter }),
}))

export const useFilterSaveStore = create<FilterSaveStore>((set) => ({
	filterName: undefined,
	setFilterName: (filterName) => set({ filterName }),
}))

interface UpdateSavedFilterState {
	updateSavedFilter: boolean
	setUpdateSavedFilter: (update: boolean) => void
}

export const useUpdateSavedFilterStore = create<UpdateSavedFilterState>(
	(set) => ({
		updateSavedFilter: false,
		setUpdateSavedFilter: (update) => set({ updateSavedFilter: update }),
	}),
)

export function useSavedFilter() {
	const {
		modified,
		setModified,
		selectedSavedFilter,
		setSelectedSavedFilter,
	} = useSavedFilterStore()
	const { filterName, setFilterName } = useFilterSaveStore()

	const { updateSavedFilter, setUpdateSavedFilter } =
		useUpdateSavedFilterStore()

	const { data: savedFiltersData, refetch } =
		trpcClient.savedViews.getAllSavedFilters.useQuery()

	const route = usePageId().split(".")[1]

	const savedFilters = useMemo(() => {
		return (savedFiltersData ?? [])
			.filter(
				(savedFilter) =>
					savedFilter.isActive && savedFilter.viewName === route,
			)
			.map((savedFilter) => ({
				id: savedFilter.id,
				route: route,
				filterName: savedFilter.filterName,
				filters: savedFilter.columns.map(
					(filter) =>
						JSON.parse(filter) as { id: string; value: any },
				),
			}))
	}, [savedFiltersData, refetch, route])

	return {
		selectedSavedFilter,
		setSelectedSavedFilter,
		modified,
		setModified,
		filterName,
		setFilterName,
		savedFilters,
		route,
		updateSavedFilter,
		setUpdateSavedFilter,
		refetch,
	}
}

// Custom hook for managing and applying filters
export function useFilter<TanstackTableSchema>({
	listDataState,
}: {
	listDataState?: TanstackTable<TanstackTableSchema>
}) {
	const { pageState, setFilters } = usePageUrlState()
	const { isOpen } = useFilterPanel()
	const {
		selectedSavedFilter,
		setSelectedSavedFilter,
		setModified,
		route,
		filterName,
		setFilterName,
		modified,
		savedFilters,
		updateSavedFilter,
		setUpdateSavedFilter,
		refetch,
	} = useSavedFilter()

	const [newSavedId, setNewSavedId] = useState(-1)
	const existingFilters = pageState.filters

	const [columnFilters, setColumnFilters] =
		useState<ColumnFilter[]>(existingFilters)

	const [filterCount, setFilerCount] = useState(0)

	// Used to determine if the apply button should be disabled
	const [disableApply, setDisableApply] = useState(false)

	const saveFilterMutation = trpcClient.savedViews.saveFilter.useMutation({
		onSuccess: () => {
			refetch()
		},
	})
	const updateFilterMutation = trpcClient.savedViews.updateFilter.useMutation(
		{
			onSuccess: () => {
				refetch()
			},
		},
	)

	// const deleteFilterMutation = trpcClient.savedViews.deleteFilter.useMutation({
	// 	onSuccess: () => {
	// 		setShouldRefetch(true)
	// 	},
	// })

	/**
	 * Obtains all the valid filterable columns and all the unique values stored in them.
	
	 * Map  the filtered columns to an object containing:
	 *    - `columnId`: The unique identifier of the column.
	 *    - `header`: The header name of the column.
	 *    - `dataType`: The data type of the column, defaulting to "string" if not specified.
	 *    - `uniqueValues`: An array of unique values in the column, sorted primarily by descending count and secondarily by alphabetical order.
	 *
	 * The `uniqueValues` array contains objects with:
	 * - `value`: The unique value in the column.
	 * - `count`: The count of occurrences of the value.
	 *
	 */
	const filterOptions: FilterOptions[] = useMemo(
		() =>
			listDataState
				?.getAllColumns()
				.filter((column) => column.columnDef.enableColumnFilter)
				.map((column) => {
					const uniqueValues = column.getFacetedUniqueValues()
					return {
						columnId: column.id as string,
						header: column.columnDef.header as string,
						plural:
							column.columnDef.meta?.plural ??
							`${column.columnDef.header}s`,
						dataType: column.columnDef.meta?.dataType ?? "string",
						uniqueValues: Array.from(uniqueValues.entries())
							.sort((a, b) => {
								if (b[1] !== a[1]) {
									return b[1] - a[1] // Primary sort: Descending count
								}
								return String(a[0])
									.toString()
									.localeCompare(String(b[0]).toString()) // Secondary sort: Alphabetical value
							})
							.map(([value, count]) => ({
								value,
								count,
							})),
					}
				}) ?? [],
		[listDataState, route],
	)

	// list of all the columnIds from the data.
	const filterCategories = useMemo(
		() => filterOptions.map((filter) => filter.columnId),
		[filterOptions],
	)

	// Manages the state of show/hide excess list items when list size exceeds MAX_COLLAPSED_FILTERS
	const [isExpanded, setIsExpanded] = useState<ToggleExpand>(() =>
		filterCategories.reduce<ToggleExpand>((acc, key) => {
			acc[key] = false
			return acc
		}, {}),
	)

	// Determines the columns that store date values and extracts relevant details.
	const dateFilter: DateFilter = Object.fromEntries(
		filterOptions
			.filter(
				(filter) =>
					filter.dataType === "date" &&
					filter.uniqueValues.length > 0,
			)
			.map((filter) => {
				const dates = filter.uniqueValues.map((date) =>
					parseISO(date.value),
				)

				const earliest = new Date(
					Math.min(...dates.map((date) => date.getTime())),
				)

				const latest = new Date(
					Math.max(...dates.map((date) => date.getTime())),
				)

				const existingDateFilterValue = existingFilters.find(
					(item) => item.id == filter.columnId,
				)?.value

				const existingFrom =
					existingDateFilterValue ?
						parseISO(existingDateFilterValue[0])
					:	undefined
				const existingTo =
					existingDateFilterValue ?
						parseISO(existingDateFilterValue[1])
					:	undefined

				// eslint-disable-next-line
				const [newDate, setNewDate] = useState<DateRange>({
					from: existingFrom,
					to: existingTo,
				})

				return [
					filter.columnId,
					{
						dateState: { date: newDate, setDate: setNewDate },
						earliestDate: earliest,
						latestDate: latest,
						dates: dates,
					},
				]
			}),
	)

	// Determines the columns that store number values and extracts relevant details.
	const numberFilter: NumberFilter = Object.fromEntries(
		filterOptions
			.filter((filter) => filter.dataType === "number")
			.map((filter) => {
				const numbers = filter.uniqueValues.map((item) => item.value)

				const existingNumberFilterValue = existingFilters.find(
					(item) => item.id == filter.columnId,
				)?.value

				const minValue = Math.min(...numbers)
				const maxValue = Math.max(...numbers)

				const higherPrecision = numbers.some((num) => num % 1 !== 0)

				const initialValue = [
					existingNumberFilterValue ?
						Number(existingNumberFilterValue[0])
					:	minValue,
					existingNumberFilterValue ?
						Number(existingNumberFilterValue[1])
					:	maxValue,
				]
				const defaultValue = [minValue, maxValue]

				// eslint-disable-next-line
				const [sliderValue, setSliderValue] = useState(initialValue)
				// eslint-disable-next-line
				const [inputValues, setInputValues] = useState(
					initialValue.map((v) => v.toString()),
				)

				return [
					filter.columnId,
					{
						minValue: minValue,
						maxValue: maxValue,
						initialValue: initialValue,
						defaultValue: defaultValue,
						sliderState: { sliderValue, setSliderValue },
						inputState: { inputValues, setInputValues },
						higherPrecision: higherPrecision,
					},
				]
			}),
	)

	// Toggles the state of show/hide excess list items when list size exceeds MAX_COLLAPSED_FILTERS
	const toggleExpand = (column: keyof ToggleExpand) => {
		setIsExpanded((prevState) => ({
			...prevState,
			[column]: !prevState[column],
		}))
	}

	const resetFilters = () => {
		Object.values(dateFilter).forEach((dateFilterState) => {
			resetDate(dateFilterState)
		})
		Object.values(numberFilter).forEach((numberFilterState) => {
			resetSlider(numberFilterState)
		})
		setSelectedSavedFilter(undefined)
		setColumnFilters([])
	}

	// Update columnFilters with a checkbox is toggled
	const toggleFilter = (
		columnId: string,
		value: number | string | boolean,
		checked: boolean,
	): void => {
		setColumnFilters((prevFilters) => {
			if (checked) {
				const existingFilterIndex = prevFilters.findIndex(
					(filter) => filter.id === columnId,
				)

				if (existingFilterIndex !== -1) {
					const updatedFilter = {
						id: columnId,
						value:
							prevFilters[existingFilterIndex] ?
								(
									Array.isArray(
										prevFilters[existingFilterIndex].value,
									)
								) ?
									[
										...prevFilters[existingFilterIndex]
											.value,
										value,
									]
								:	[prevFilters[existingFilterIndex].value, value]
							:	[value],
					}

					return [
						...prevFilters.filter(
							(filter) => filter.id !== columnId,
						),
						updatedFilter,
					]
				} else {
					return [...prevFilters, { id: columnId, value: [value] }]
				}
			} else {
				return prevFilters
					.map((filter) =>
						filter.id === columnId && Array.isArray(filter.value) ?
							{
								...filter,
								value: filter.value.filter((v) => v !== value),
							}
						:	filter,
					)
					.filter((filter) => filter.value.length > 0) // Remove filter objects with empty value arrays
			}
		})
	}

	const resetDate = (dateFilterState: DateFilterState) => {
		dateFilterState.dateState.setDate({
			from: undefined,
			to: undefined,
		})
	}

	const resetSlider = (numberSliderState: NumberSliderState) => {
		numberSliderState.sliderState.setSliderValue([
			numberSliderState.minValue,
			numberSliderState.maxValue,
		])
		numberSliderState.inputState.setInputValues([
			numberSliderState.minValue.toString(),
			numberSliderState.maxValue.toString(),
		])
	}

	// function to update columnFilter with dateFilter
	const updateColumnFiltersWithDateRange = () => {
		if (Object.keys(dateFilter).length > 0) {
			for (const key in dateFilter) {
				const dateFilterState = dateFilter[key]
				if (dateFilterState) {
					const { dateState, earliestDate, latestDate } =
						dateFilterState
					if (dateState && dateState.date) {
						const { from, to } = dateState.date

						if (
							(from?.getTime() !== earliestDate.getTime() ||
								to?.getTime() !== latestDate.getTime()) &&
							from &&
							to
						) {
							const newDateFilter = {
								id: key,
								value: [
									from?.toISOString().split("T")[0],
									to?.toISOString().split("T")[0],
								],
							}

							setColumnFilters((prevItems) =>
								prevItems.some((item) => item.id === key) ?
									prevItems.map((item) =>
										item.id === key ?
											{
												...item,
												value: newDateFilter.value,
											}
										:	item,
									)
								:	[...prevItems, newDateFilter],
							)
						} else {
							setColumnFilters((prevItems) =>
								prevItems.filter((item) => item.id !== key),
							)
						}
					}
				}
			}
		}
	}

	// function to update columnFilter with numberFilter
	const updateColumnFiltersWithNumberRange = () => {
		if (Object.keys(numberFilter).length > 0) {
			for (const key in numberFilter) {
				const numberFilterState = numberFilter[key]
				if (numberFilterState) {
					const { sliderState, minValue, maxValue } =
						numberFilterState
					const [min, max] = sliderState.sliderValue

					if (
						(min !== minValue || max !== maxValue) &&
						min !== undefined &&
						max !== undefined
					) {
						const newNumberFilter = {
							id: key,
							value: [min, max],
						}

						setColumnFilters((prevItems) =>
							prevItems.some((item) => item.id === key) ?
								prevItems.map((item) =>
									item.id === key ?
										{
											...item,
											value: newNumberFilter.value,
										}
									:	item,
								)
							:	[...prevItems, newNumberFilter],
						)
					} else {
						setColumnFilters((prevItems) =>
							prevItems.filter((item) => item.id !== key),
						)
					}
				}
			}
		}
	}

	// function to update dateFilter values from saved filter
	const updateDateFiltersWithColumnFilters = (filters: ColumnFilter[]) => {
		if (Array.isArray(filters) && filters.length > 0) {
			filters.forEach((filter) => {
				if (dateFilter[filter.id]) {
					const dateFilterState = dateFilter[filter.id]
					const [from, to] = filter.value.map((date: string) =>
						parseISO(date),
					)

					if (dateFilterState) {
						dateFilterState.dateState.setDate({
							from,
							to,
						})
					}
				}
			})
		}

		Object.keys(dateFilter).forEach((key) => {
			const dateFilterState = dateFilter[key]
			const filterExists = filters.some((filter) => filter.id === key)

			if (!filterExists && dateFilterState) {
				dateFilterState.dateState.setDate({
					from: undefined,
					to: undefined,
				})
			}
		})
	}

	const setSelectedSavedFilterById = (id: number) => {
		const savedFilter = savedFilters.find((filter) => filter.id === id)
		if (savedFilter) {
			setSelectedSavedFilter({
				...savedFilter,
				route: savedFilter.route ?? route ?? "",
			})
		}
	}

	const saveFilter = async (filterName: string) => {
		if (!route) return
		const data = {
			filterName: filterName,
			viewName: route as SavedViewName,
			columns: columnFilters.map((filter) => JSON.stringify(filter)),
		}

		const id = await saveFilterMutation.mutateAsync(data)

		if (id) setNewSavedId(id)
	}

	const updateFilter = async () => {
		if (!selectedSavedFilter || selectedSavedFilter === "toggled" || !route)
			return

		const id = selectedSavedFilter.id
		const data = {
			id: selectedSavedFilter.id,
			filterName: selectedSavedFilter?.filterName,
			viewName: route as SavedViewName,
			columns: columnFilters.map((filter) => JSON.stringify(filter)),
			isActive: true,
		}

		await updateFilterMutation.mutateAsync(data)

		setNewSavedId(id)
	}

	// const deleteFilter = async () => {
	// 	deleteFilterMutation.mutateAsync()

	// }

	const checkAndSetSelectedSavedFilter = () => {
		if (existingFilters.length > 0) {
			for (const savedFilter of savedFilters) {
				if (savedFilter.filters.length !== existingFilters.length)
					continue

				const sortedSavedFilters = [...savedFilter.filters].sort(
					(a, b) => a.id.localeCompare(b.id),
				)
				const sortedExistingFilters = [...existingFilters].sort(
					(a, b) => a.id.localeCompare(b.id),
				)

				let isEqual = true
				for (let i = 0; i < sortedSavedFilters.length; i++) {
					const obj1 = sortedSavedFilters[i]
					const obj2 = sortedExistingFilters[i]

					if (!obj1 || !obj2 || obj1.id !== obj2.id) {
						isEqual = false
						break
					}

					const value1 = obj1.value
						.map((item: any) => String(item))
						.sort()
					const value2 = obj2.value
						.map((item: any) => String(item))
						.sort()

					if (
						value1.length !== value2.length ||
						!value1.every(
							(v: string, index: number) => v === value2[index],
						)
					) {
						isEqual = false
						break
					}
				}

				if (isEqual) {
					setSelectedSavedFilter({
						id: savedFilter.id,
						filterName: savedFilter.filterName,
						filters: savedFilter.filters,
						route: route ?? "",
					})
					return
				}
			}
		}
	}

	// Update columnFilters if there is a change in numberFilter
	useEffect(() => {
		updateColumnFiltersWithNumberRange()
	}, [JSON.stringify(numberFilter)])

	// Enable Saved Filter if it matches current selection or existingFilters
	useEffect(() => {
		checkAndSetSelectedSavedFilter()
	}, [existingFilters, route, savedFilters])

	// Restore the filters state after closing the filters Panel
	useEffect(() => {
		if (!isOpen) {
			setColumnFilters(existingFilters)
			updateDateFiltersWithColumnFilters(existingFilters)
		}
	}, [isOpen])

	// Update Saved filter after making changes
	useEffect(() => {
		if (newSavedId !== -1) {
			setSelectedSavedFilterById(newSavedId)
			setNewSavedId(-1)
		}
	}, [savedFilters])

	// Run Update filter
	useEffect(() => {
		if (updateSavedFilter) {
			updateFilter()
			setUpdateSavedFilter(false)
		}
	}, [updateSavedFilter])

	// Update columnFilters if there is a change in dateFilter
	useEffect(() => {
		updateColumnFiltersWithDateRange()
	}, [JSON.stringify(dateFilter)])

	// Compare if local filters and search param filters are the same
	const comparefilterStates = () => {
		if (existingFilters.length !== columnFilters.length) return false

		const sortedExistingFilters = [...existingFilters].sort((a, b) =>
			a.id.localeCompare(b.id),
		)
		const sortedColumnFilters = [...columnFilters].sort((a, b) =>
			a.id.localeCompare(b.id),
		)

		for (let i = 0; i < sortedExistingFilters.length; i++) {
			const obj1 = sortedExistingFilters[i]
			const obj2 = sortedColumnFilters[i]

			if (!obj1 || !obj2 || obj1.id !== obj2.id) return false

			const value1 = obj1.value.map((item: any) => String(item)).sort()
			const value2 = obj2.value.map((item: any) => String(item)).sort()

			if (
				value1.length !== value2.length ||
				!value1.every((v: string, index: number) => v === value2[index])
			) {
				return false
			}
		}

		return true
	}

	const compareSelectedFilterStates = () => {
		if (!selectedSavedFilter || selectedSavedFilter === "toggled")
			return false
		if (selectedSavedFilter.filters.length !== columnFilters.length)
			return false

		const sortedSelectedFilters = [...selectedSavedFilter.filters].sort(
			(a, b) => a.id.localeCompare(b.id),
		)
		const sortedColumnFilters = [...columnFilters].sort((a, b) =>
			a.id.localeCompare(b.id),
		)

		for (let i = 0; i < sortedSelectedFilters.length; i++) {
			const obj1 = sortedSelectedFilters[i]
			const obj2 = sortedColumnFilters[i]

			if (!obj1 || !obj2 || obj1.id !== obj2.id) return false

			const value1 = obj1.value.map((item: any) => String(item)).sort()
			const value2 = obj2.value.map((item: any) => String(item)).sort()

			if (
				value1.length !== value2.length ||
				!value1.every((v: string, index: number) => v === value2[index])
			) {
				return false
			}
		}

		return true
	}

	// Update disableApply using comparefilterStates()
	useEffect(() => {
		setDisableApply(comparefilterStates)
	}, [existingFilters, columnFilters])

	const getTotalFilterCount = () => {
		const totalCount = columnFilters.reduce(
			(acc, filter) =>
				acc +
				((
					Object.prototype.hasOwnProperty.call(
						dateFilter,
						filter.id,
					) ||
					Object.prototype.hasOwnProperty.call(
						numberFilter,
						filter.id,
					)
				) ?
					1
				:	filter.value.length),
			0,
		)

		return totalCount
	}

	// Update filterCount
	useEffect(() => {
		setFilerCount(getTotalFilterCount())
	}, [columnFilters, route])

	// Remove saved filter when all filters are reset
	useEffect(() => {
		if (columnFilters.length === 0) {
			setSelectedSavedFilter(undefined)
		}
	}, [columnFilters, route])

	// Update columnFilters based on saved filters
	useEffect(() => {
		if (
			selectedSavedFilter &&
			selectedSavedFilter !== "toggled" &&
			selectedSavedFilter.route === route
		) {
			setColumnFilters(selectedSavedFilter.filters)
			setFilters(selectedSavedFilter.filters)
			updateDateFiltersWithColumnFilters(selectedSavedFilter.filters)
		} else if (selectedSavedFilter === "toggled") {
			resetFilters()
			setFilters([])
			setModified(false)
		}
	}, [selectedSavedFilter, route])

	const refreshSavedFilterSelection = () => {
		if (savedFilters.length === 0) {
			setSelectedSavedFilter(undefined)
			return false
		}

		for (const savedFilter of savedFilters) {
			if (savedFilter.filters.length !== columnFilters.length) continue

			const sortedSavedFilters = [...savedFilter.filters].sort((a, b) =>
				a.id.localeCompare(b.id),
			)
			const sortedColumnFilters = [...columnFilters].sort((a, b) =>
				a.id.localeCompare(b.id),
			)

			let isEqual = true
			for (let i = 0; i < sortedSavedFilters.length; i++) {
				const obj1 = sortedSavedFilters[i]
				const obj2 = sortedColumnFilters[i]

				if (!obj1 || !obj2 || obj1.id !== obj2.id) {
					isEqual = false
					break
				}

				const value1 = obj1.value
					.map((item: any) => String(item))
					.sort()
				const value2 = obj2.value
					.map((item: any) => String(item))
					.sort()

				if (
					value1.length !== value2.length ||
					!value1.every(
						(v: string, index: number) => v === value2[index],
					)
				) {
					isEqual = false
					break
				}
			}

			if (isEqual) {
				setSelectedSavedFilter({
					id: savedFilter.id,
					filterName: savedFilter.filterName,
					filters: savedFilter.filters,
					route: route ?? "",
				})
				return true
			}
		}

		setSelectedSavedFilter(undefined)
		return false
	}

	// Check if a savedFilter has been applied when returning back to route
	useEffect(() => {
		refreshSavedFilterSelection()
	}, [route])

	// Handle changes to filters after a saved filter has been applied
	useEffect(() => {
		if (
			selectedSavedFilter &&
			selectedSavedFilter !== "toggled" &&
			!compareSelectedFilterStates()
		) {
			setModified(true)
		} else {
			setModified(false)
		}
	}, [columnFilters, route])

	// Save Filter
	useEffect(() => {
		if (filterName) {
			saveFilter(filterName)
			setFilterName(undefined)
		}
	}, [filterName])

	const disableSave = useMemo(() => {
		return (
				columnFilters.length === 0 || (selectedSavedFilter && !modified)
			) ?
				true
			:	false
	}, [columnFilters, selectedSavedFilter, modified])

	return {
		isExpanded,
		toggleExpand,
		columnFilters,
		setColumnFilters,
		resetFilters,
		toggleFilter,
		filterCategories,
		filterOptions,
		dateFilter,
		resetDate,
		setFilters,
		disableApply,
		filterCount,
		saveFilter,
		disableSave,
		numberFilter,
	}
}
