import { useState } from "react"
import { trpcClient } from "@/trpc/client"
import { PlusIcon, WrenchIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type {
	NewWorkOrder,
	ServicePart,
	WorkOrderService,
	WorkOrderServiceEmployee,
} from "@/server/schemas"
import {
	AddEditSection,
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import {
	Command,
	CommandEmpty,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"
import { ServiceCard } from "@/components/features/services/details-cards"

export const WorkOrderAddEditServices: AddEditSectionDefinition = {
	label: "Services",
	icon: WrenchIcon,
	component: (
		<AddEditSectionsGrouping>
			<Services />
			{/* <PreventiveMaintenance /> */}
		</AddEditSectionsGrouping>
	),
}

function Services() {
	const { data: servicesResult, isLoading: servicesAreLoading } =
		trpcClient.services.getAllServices.useQuery()

	const { control, watch, setValue } = useFormContext<NewWorkOrder>()

	const formServices = watch("services")

	// Ensure servicesResult is a success result before mapping
	const fullServices: (WorkOrderService | undefined)[] = formServices.map(
		(service) => {
			const matchingService =
				servicesResult?.success === true ?
					servicesResult.value.find((s) => s.id === service.serviceId)
				:	undefined

			if (!matchingService) return undefined
			return {
				...service,
				employees: [] as WorkOrderServiceEmployee[],
				parts: [] as ServicePart[],
				id: 0,
				service: matchingService, // Plug in the matching service or an empty string if not found
			}
		},
	)

	return (
		<AddEditSection>
			<AddEditSectionHeading>Services</AddEditSectionHeading>

			{fullServices.map((service, index) => {
				if (!service) return null
				return <ServiceCard key={index} service={service} />
			})}

			<ServicesDropdown />
		</AddEditSection>
	)
}

function ServicesDropdown() {
	const [open, setOpen] = useState(false)
	const { data: servicesResult, isLoading: servicesAreLoading } =
		trpcClient.services.getAllServices.useQuery()
	const { control, watch, setValue } = useFormContext<NewWorkOrder>()
	const formServices = watch("services")

	const fullServices: (WorkOrderService | undefined)[] = formServices.map(
		(service) => {
			const matchingService =
				servicesResult?.success === true ?
					servicesResult.value.find((s) => s.id === service.serviceId)
				:	undefined

			if (!matchingService) return undefined
			return {
				...service,
				employees: [] as WorkOrderServiceEmployee[],
				parts: [] as ServicePart[],
				id: 0,
				service: matchingService, // Plug in the matching service or an empty string if not found
			}
		},
	)

	return (
		<Popover open={open} onOpenChange={setOpen} modal>
			<PopoverTrigger asChild>
				<Button variant="placeholder" role="button" size="sm">
					<PlusIcon className="size-5" />
					Add Service
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[calc(var(--radix-popover-trigger-width)*.9)] p-0">
				<Command>
					<CommandInput placeholder="Search" />
					{servicesAreLoading ?
						<CommandEmpty>Loading...</CommandEmpty>
					: (
						servicesResult?.success &&
						servicesResult.value.length > 0
					) ?
						<>
							<CommandList className="[scrollbar-width:thin]">
								{servicesResult.value.map((service) => (
									<CommandItem
										key={service.id}
										value={service.name}
										onSelect={() => {
											setValue("services", [
												...formServices,
												{
													status: "NOT STARTED",
													workOrderId: 0,
													serviceId: service.id,
													employeeIds: [],
												},
											])
											setOpen(false)
										}}
									>
										<div className="flex gap-2">
											<span>{service.name}</span>
										</div>
									</CommandItem>
								))}
							</CommandList>
							<CommandEmpty>No results</CommandEmpty>
						</>
					:	<CommandEmpty>No results</CommandEmpty>}
				</Command>
			</PopoverContent>
		</Popover>
	)
}
