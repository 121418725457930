import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"

import { newServiceSchema } from "@/server/schemas"
import type { NewService } from "@/server/schemas"
import { AddEdit } from "@/components/shared/add-edit"
import type { AddEditStateProps } from "@/components/shared/add-edit"

import { ServiceAddEditInfo } from "./sections/info"
import { ServiceAddEditPM } from "./sections/pm"
import { ServiceAddEditSettings } from "./sections/settings"
import { ServiceAddEditSummary } from "./sections/summary"

type ServiceAddEditProps = AddEditStateProps<typeof newServiceSchema>

export function ServiceAddEdit({ ...props }: ServiceAddEditProps) {
	const { revalidate } = useRevalidator()
	const utils = trpcClient.useUtils()
	const { mutateAsync } = trpcClient.services.addEditService.useMutation({
		onSuccess: () => {
			utils.customers.getCustomerById.invalidate()
			revalidate()
		},
	})

	return (
		<AddEdit
			{...props}
			summary={<ServiceAddEditSummary />}
			sectionDefinitions={{
				info: ServiceAddEditInfo,
				settings: ServiceAddEditSettings,
				pm: ServiceAddEditPM,
			}}
			submitHandlers={mutateAsync}
			feature="service"
			schema={newServiceSchema}
			defaultValues={defaultService}
		/>
	)
}

const defaultService: NewService = {
	name: "",
	code: "",
	description: "",
	notes: [],

	type: "",
	version: "",
	estimatedLaborTimeMinutes: 0,
	flatLaborFee: 0,
	useFlatLaborFee: false,
	flatPartsFee: 0,
	useFlatPartsFee: false,
	permitMultipleEntry: false,
	parts: [],
	tags: [],
	metas: [],
	isActive: true,
	serviceGroupId: null,
}
