import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildArray,
} from "@/lib/utils/data/validators"
import { employeeTable } from "@/server/tables"

import { metaSchema, newMetaSchema, newTagSchema, tagSchema } from "../shared"
import {
	employeeContactSchema,
	employeeContactSelectColumns,
	newEmployeeContactSchema,
} from "./employeeContact"
import { employeeMetaSelectColumns } from "./employeeMeta"
import { employeeTagSelectColumns } from "./employeeTag"

const employeeOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
	isDeleted: true,
	deletedDate: true,
} as const
export const baseEmployeeSelectColumns = {
	...getTableSelectColumns(employeeTable, employeeOmitFields),
}
export const employeeSelectColumns = {
	...baseEmployeeSelectColumns,
	contacts: jsonBuildArray(employeeContactSelectColumns),
	metas: jsonBuildArray(employeeMetaSelectColumns),
	tags: jsonBuildArray(employeeTagSelectColumns),
}

export const baseEmployeeSchema =
	createSelectSchema(employeeTable).omit(employeeOmitFields)
export const employeeSchema = baseEmployeeSchema.extend({
	contacts: employeeContactSchema.array().default([]),
	metas: metaSchema.array().default([]),
	tags: tagSchema.array().default([]),
})
export type Employee = z.infer<typeof employeeSchema>

export const newEmployeeSchema = createInsertSchema(employeeTable)
	.omit(employeeOmitFields)
	.extend({
		contacts: newEmployeeContactSchema.array().default([]),
		metas: newMetaSchema.array().default([]),
		tags: newTagSchema.array().default([]),
	})
export type NewEmployee = z.infer<typeof newEmployeeSchema>

// Add/Edit form schema
// TODO: Add validation
export const employeeFormSchema = newEmployeeSchema
