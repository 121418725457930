import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { tagFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { serviceTable } from "./service"

export const serviceTagTable = pgTable(
	"service_tag",
	tagFields(scopeTable, serviceTable),
	(table) => [primaryKey({ columns: [table.rowId, table.tag] })],
)
