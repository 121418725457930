import { useState } from "react"
import { trpcClient } from "@/trpc/client"
import { InfoIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import { convertCsvToVinList } from "@/lib/utils/data/motorApi"
import { parseCsvFile } from "@/lib/utils/data/readCsv"
import type { Result } from "@/lib/utils/result"
import type { Equipment, NewEquipment } from "@/server/schemas"
import { AddEditSection, AddEditSectionHeading } from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import DragDropCSVButton from "@/components/ui/drag-drop-csv"
import { FieldGroup } from "@/components/ui/feild-group"
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Link } from "@/components/ui/link"
import { OverlineSmall } from "@/components/ui/typography"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const EquipmentAddEditInfo: AddEditSectionDefinition = {
	label: "Info",
	icon: InfoIcon,
	component: <EquipmentInfoGroup />,
}

function EquipmentInfoGroup() {
	const { getValues, setValue, control } = useFormContext()

	const [batchResults, setBatchResults] = useState<NewEquipment[]>([])

	const handleDecodedVin = (data: Result<NewEquipment>) => {
		if (data.success) {
			setValue("make", data.value.make)
			setValue("model", data.value.model)
			setValue("year", data.value.year)
		}
	}

	const handleBatchDecodedVins = (data: Result<NewEquipment[]>) => {
		if (data.success) {
			setBatchResults(data.value)
		}
	}

	const handleBatchVinSave = (data: Result<Equipment[]>) => {
		if (data.success) {
			console.log(
				`Successfully created batch of ${data.value.length} equipment`,
			)
		} else {
			console.log(data.error)
		}
	}

	const { mutate: vinDecode } = trpcClient.motorAPI.vinDecode.useMutation({
		onSuccess: handleDecodedVin,
	})
	const { mutate: batchVinDecode } =
		trpcClient.motorAPI.batchVinDecode.useMutation({
			onSuccess: handleBatchDecodedVins,
		})
	const { mutate: batchVinSave } =
		trpcClient.motorAPI.batchVinSave.useMutation({
			onSuccess: handleBatchVinSave,
		})

	const onVinLookup = async () => {
		vinDecode({ vin: getValues("vin") })
	}

	const onFileDrop = async (data: string) => {
		batchVinDecode({ vinList: convertCsvToVinList(parseCsvFile(data)) })
	}

	const onBatchSave = async () => {
		batchVinSave(batchResults)
	}

	return (
		<AddEditSection>
			<AddEditSectionHeading>Info</AddEditSectionHeading>
			<FormField
				control={control}
				name="vin"
				render={({ field }) => (
					<FormItem>
						<FormLabel>VIN</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder={"eg. 1234567890ABCDEF"}
								value={field.value?.toString()}
							/>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<div className="flex items-center justify-between rounded-md border border-primary bg-background-weaker p-3">
				<div className="flex flex-wrap">
					<div>
						Look up VIN to automatically add equipment details?
					</div>
					<Link to="/">Learn more</Link>
				</div>
				<div className="flex flex-col items-center gap-px">
					<Button
						className="text-info"
						onClick={(e) => {
							e.preventDefault()
							onVinLookup()
						}}
					>
						Look up VIN
					</Button>
					<OverlineSmall>Or</OverlineSmall>
					<DragDropCSVButton onFileDrop={onFileDrop}>
						Drop CSV
					</DragDropCSVButton>
				</div>
			</div>
			<div className="grid grid-cols-3 gap-2">
				<FormField
					control={control}
					name="year"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Year</FormLabel>
							<FormControl>
								<Input
									{...field}
									type="number"
									placeholder="2010"
									onChange={(e) => {
										const value =
											e.target.value === "" ?
												""
											:	Number(e.target.value)
										field.onChange(value)
									}}
									value={field.value?.toString()}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={control}
					name="make"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Make</FormLabel>
							<FormControl>
								<Input
									{...field}
									placeholder={"eg. Freightliner"}
									value={field.value?.toString()}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={control}
					name="model"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Model</FormLabel>
							<FormControl>
								<Input
									{...field}
									placeholder={"eg. Cascadia"}
									value={field.value?.toString()}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			</div>
			{batchResults.length > 0 && (
				<>
					<FieldGroup label="Batch Decode Results">
						{batchResults.map((elem) => (
							<div key={elem.vin}>
								{elem.name}: {elem.year} {elem.make}{" "}
								{elem.model} ({elem.vin})
							</div>
						))}
					</FieldGroup>
					<Button variant="primary" onClick={onBatchSave}>
						Save Batch Results
					</Button>
				</>
			)}
		</AddEditSection>
	)
}
