import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { pmTimeElapsedTable } from "@/server/tables"

const pmTimeElapsedOmitFields = {
	scopeId: true,
	updatedAt: true,
	createdAt: true,
} as const
export const pmTimeElapsedSelectColumns = {
	...getTableSelectColumns(pmTimeElapsedTable, pmTimeElapsedOmitFields),
}

export const pmTimeElapsedSchema = createSelectSchema(pmTimeElapsedTable).omit(
	pmTimeElapsedOmitFields,
)
export type PMTimeElapsed = z.infer<typeof pmTimeElapsedSchema>

export const newPmTimeElapsedSchema = createInsertSchema(
	pmTimeElapsedTable,
).omit(pmTimeElapsedOmitFields)
export type NewPMTimeElapsed = z.infer<typeof newPmTimeElapsedSchema>
