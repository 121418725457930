import type { Equipment } from "@/server/schemas"
import {
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function EquipmentSpecsSection({ equipment }: { equipment: Equipment }) {
	return (
		<AccordionItem value="specs">
			<AccordionTrigger className="px-0">
				<DetailsSectionHeading>Specifications</DetailsSectionHeading>
			</AccordionTrigger>
			<AccordionContent className="mb-5 p-0 pt-2">
				<DetailsSection columned>
					<DetailsInfoGroup label="Fuel type">
						{equipment?.fuelType}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Oil type">
						{equipment?.oilType}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Fuel card #">
						{equipment?.fuelCardNumber}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Fuel group">
						{equipment?.fuelGroup}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="MPG">
						{equipment?.mpg}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="MPG last updated">
						{equipment?.mpgLastUpdated}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Limit between fueling">
						{equipment?.limitBetweenFueling}
						<span className="ml-0.5 text-sm text-foreground-weak">
							miles
						</span>
					</DetailsInfoGroup>
					<DetailsInfoGroup label="EXT color">
						{equipment?.color}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Vocation">
						{equipment?.vocation}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Engine test group #">
						{equipment?.engineTestGroupNumber}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Axles">
						{equipment?.axles}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Wheels">
						{equipment?.wheels}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Brake type">
						{equipment?.brakeType}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Suspension type">
						{equipment?.suspensionType}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Equipment unit barcode">
						{equipment?.equipmentUnitBarcode}
					</DetailsInfoGroup>
					<DetailsInfoGroup label="Specific gross weight">
						{equipment?.grossWeight}
						<span className="ml-0.5 text-sm text-foreground-weak">
							lbs
						</span>
					</DetailsInfoGroup>
				</DetailsSection>
			</AccordionContent>
		</AccordionItem>
	)
}
