import { Settings2 } from "lucide-react"

import type { Service } from "@/server/schemas"
import {
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
} from "@/components/ui/details-view"
import { FieldGroup } from "@/components/ui/feild-group"
import { GridRow } from "@/components/ui/gridRow"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const ServiceSettingsSection: DetailsSectionComponent<Service> = ({
	data: service,
}) => (
	<>
		<DetailsSectionsGrouping>
			<DetailsSection>
				<DetailsSectionHeading>Settings</DetailsSectionHeading>
				<div className="flex flex-col gap-5">
					<div className="flex flex-wrap gap-x-5 gap-y-3 rounded-md border border-border-weak bg-darken-weakest p-3 py-2">
						<FieldGroup label="Serv Grp">
							{service?.serviceGroupId}
						</FieldGroup>
						<FieldGroup label="Serv Type">
							{service?.type}
						</FieldGroup>
						<FieldGroup label="Serv Code">
							{service?.code}
						</FieldGroup>
					</div>
					<FieldGroup label="VMRS">{service?.vmrsCode}</FieldGroup>
					<GridRow columnSize="sm">
						<FieldGroup label="Default for service">
							{service?.isDefault ? "Yes" : "No"}
						</FieldGroup>
						<FieldGroup label="Permit Mult. Entry">
							{service?.permitMultipleEntry ? "Yes" : "No"}
						</FieldGroup>
					</GridRow>
				</div>
			</DetailsSection>
			<DetailsSection>
				<DetailsSectionHeading>Accounting</DetailsSectionHeading>
				<GridRow columnSize="sm">
					<FieldGroup label="Location Code">
						{/* {service.locationCode} */}
					</FieldGroup>
					<FieldGroup label="Department Code">
						{/* {service.departmentCode} */}
					</FieldGroup>
				</GridRow>
			</DetailsSection>
		</DetailsSectionsGrouping>
	</>
)
ServiceSettingsSection.label = "Settings"
ServiceSettingsSection.icon = Settings2
