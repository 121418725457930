import { trpcClient } from "@/trpc/client"

import { DetailsViewContainer } from "@/components/shared/details/details-view"
import type { DetailsViewComponent } from "@/components/shared/details/details-view"

import { PurchaseOrderHistorySection } from "./sections/history"
import { PurchaseOrderInfoSection } from "./sections/info"
import { PurchaseOrderPageHeader } from "./sections/page-header"
import { PurchaseOrderPanelHeader } from "./sections/panel-header"
import { PurchaseOrderSettingsSection } from "./sections/settings"

export const PurchaseOrderDetailView: DetailsViewComponent = (props) => {
	const { id: purchaseOrderId } = props
	const [result] =
		trpcClient.purchaseOrders.getPurchaseOrderById.useSuspenseQuery({
			id: purchaseOrderId,
		})

	if (!result.success) {
		throw new Error("Failed to fetch purchase order")
	}

	return (
		<DetailsViewContainer
			data={result.value}
			headers={{
				page: PurchaseOrderPageHeader,
				panel: PurchaseOrderPanelHeader,
			}}
			sections={{
				info: PurchaseOrderInfoSection,
				settings: PurchaseOrderSettingsSection,
				history: PurchaseOrderHistorySection,
			}}
		/>
	)
}
