import { SearchIcon, TimerResetIcon } from "lucide-react"

import type { Equipment } from "@/server/schemas"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { Input, InputSlot } from "@/components/ui/input"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const EquipmentHistorySection: DetailsSectionComponent<Equipment> = ({
	data: equipment,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Full History</DetailsSectionHeading>
		<Input placeholder="Search...">
			<InputSlot>
				<SearchIcon />
			</InputSlot>
		</Input>
	</DetailsSection>
)
EquipmentHistorySection.label = "History"
EquipmentHistorySection.icon = TimerResetIcon
