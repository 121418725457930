import { Truck } from "lucide-react"

import type { Equipment } from "@/server/schemas"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsInfoGroup,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { Separator } from "@/components/ui/separator"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"
import { Tags } from "@/components/shared/details/tags"

import { EquipmentHeaderOptionsMenu } from "../header-options-menu"

export const EquipmentPageHeader: DetailsHeaderContent<Equipment> = ({
	data: equipment,
}) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="equipment" />
			<DetailsTopRightActions>
				<EquipmentHeaderOptionsMenu equipment={equipment} />
			</DetailsTopRightActions>
		</DetailsContextBar>

		<div className="flex justify-between pr-3">
			<div className="flex gap-3">
				{equipment.avatarUrl ?
					<div
						className="flex size-[90px] shrink-0 items-center rounded-md bg-background-weakest bg-cover bg-no-repeat"
						style={{
							backgroundImage: `url(${equipment.avatarUrl})`,
							backgroundPosition: "center",
						}}
					/>
				:	<div className="flex size-[90px] shrink-0 items-center justify-center rounded-md bg-background-weakest text-foreground-weakest">
						<Truck size={60} />
					</div>
				}
				<div>
					<DetailsTitle>
						{equipment.unitId}
						<span className="mx-1 font-normal opacity-70">|</span>
						{`${equipment.year} ${equipment.make} ${equipment.model}`}
					</DetailsTitle>
					<DetailsSubtitle>
						<div>
							{equipment.vin} |{" "}
							{equipment.registrationLicensePlate}
						</div>
					</DetailsSubtitle>
					<Tags tags={equipment.tags} />
				</div>
			</div>
			<div className="flex flex-col items-end gap-3">
				<div className="flex w-fit gap-2 rounded-md border bg-background-weak px-2">
					<DetailsInfoGroup
						label="Fleet"
						className="items-center p-1"
					>
						{equipment.fleetId}
					</DetailsInfoGroup>
					<Separator orientation="vertical" className="flex-none" />
					<DetailsInfoGroup
						label="Group"
						className="items-center p-1"
					>
						{equipment.groupId}
					</DetailsInfoGroup>
					<Separator orientation="vertical" className="flex-none" />
					<DetailsInfoGroup label="Unit" className="items-center p-1">
						{equipment.unitId}
					</DetailsInfoGroup>
				</div>
				<div className="flex justify-between gap-2">
					<DetailsInfoGroup label="Owner">
						{equipment.registeredOwnerCustomerId}
					</DetailsInfoGroup>
					<DetailsInfoGroup
						label="Base Location"
						className="text-right"
					>
						{equipment.baseLocationId}
					</DetailsInfoGroup>
				</div>
			</div>
		</div>
	</>
)
