import { InfoIcon, PlusIcon } from "lucide-react"

import type { Customer } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"
import { ContactDetailsCard } from "@/components/features/contact/details/card"

const CustomerInfoSection: DetailsSectionComponent<Customer> = ({
	data: customer,
}) => {
	return (
		<DetailsSection>
			<Notes
				notes={[]} // Customer notes are not yet implemented in the schema
				feature="CUSTOMER"
				rowId={customer.id}
				className="mb-2"
			/>

			<DetailsSectionHeading>Contacts</DetailsSectionHeading>
			<div className="flex flex-col gap-2">
				{customer.contacts.map((contact) => (
					<ContactDetailsCard key={contact.id} contact={contact} />
				))}
			</div>
			{customer.contacts.length === 0 && (
				<div className="w-full py-5 text-center text-sm text-foreground-weakest">
					No Contacts
				</div>
			)}

			<Button
				variant="outline"
				className="mt-2 w-full gap-1 border-dashed text-primary shadow-none"
			>
				<PlusIcon className="size-4" />
				Add Contact
			</Button>
		</DetailsSection>
	)
}
CustomerInfoSection.label = "Info"
CustomerInfoSection.icon = InfoIcon

export { CustomerInfoSection }
