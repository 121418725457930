import { EllipsisIcon, Truck } from "lucide-react"

import type { Equipment } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import { Chip, ChipContainer } from "@/components/ui/chip"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsInfoGroup,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"
import { Tags } from "@/components/shared/details/tags"

import { EquipmentHeaderOptionsMenu } from "../header-options-menu"

export const EquipmentPanelHeader: DetailsHeaderContent<Equipment> = ({
	data: equipment,
}) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="equipment" />
			<DetailsTopRightActions>
				<EquipmentHeaderOptionsMenu equipment={equipment} />
			</DetailsTopRightActions>
		</DetailsContextBar>
		<div className="flex flex-col gap-3">
			<div className="flex grow gap-3">
				{equipment.avatarUrl ?
					<div
						className="flex size-[50px] shrink-0 items-center rounded-md bg-background-weakest bg-cover bg-no-repeat @[350px]:size-[90px]"
						style={{
							backgroundImage: `url(${equipment.avatarUrl})`,
							backgroundPosition: "center",
						}}
					/>
				:	<div className="flex size-0 items-center justify-center rounded-md bg-background-weakest text-foreground-weakest @[350px]:size-[90px]">
						<Truck size={60} />
					</div>
				}

				<div>
					<DetailsTitle>
						{equipment.unitId}
						<span className="mx-1 font-normal opacity-70">|</span>
						{`${equipment.year} ${equipment.make} ${equipment.model}`}
					</DetailsTitle>
					<DetailsSubtitle>
						<div>
							{equipment.vin} |{" "}
							{equipment.registrationLicensePlate}
						</div>
					</DetailsSubtitle>
					<Tags tags={equipment.tags} />
				</div>
			</div>
			<EquipmentOwnerInfoSection equipment={equipment} />
		</div>
	</>
)

function EquipmentOwnerInfoSection({ equipment }: { equipment: Equipment }) {
	return (
		<section className="flex grow flex-row flex-wrap items-center justify-between gap-3 gap-y-1.5">
			<div className="flex gap-3">
				<DetailsInfoGroup label="Owner">
					{equipment.registeredOwnerCustomerId}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Base Location">
					{equipment.baseLocationId}
				</DetailsInfoGroup>
			</div>
			<div className="flex items-center rounded-md border border-border-strong bg-background-weak">
				<DetailsInfoGroup
					label="Fleet"
					className="items-center px-2 py-1"
				>
					{equipment.fleetId}
				</DetailsInfoGroup>

				<DetailsInfoGroup
					label="Group"
					className="items-center border-x px-2 py-1"
				>
					{equipment.groupId}
				</DetailsInfoGroup>
				<DetailsInfoGroup
					label="Unit"
					className="items-center px-2 py-1 text-right"
				>
					{equipment.unitId}
				</DetailsInfoGroup>
			</div>
		</section>
	)
}
