import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"

export const useInvalidator = () => {
	const utils = trpcClient.useUtils()
	const { revalidate } = useRevalidator()

	const featureInvalidateMap = {
		CUSTOMER: {
			byId: (id?: number) =>
				utils.customers.getCustomerById.invalidate({ id }),
			all: () => utils.customers.getAllCustomers.invalidate(),
		},
		EMPLOYEE: {
			byId: (id?: number) =>
				utils.employees.getEmployeeById.invalidate({ id }),
			all: () => {}, // TODO: Implement invalidateAll for employees
		},
		EQUIPMENT: {
			byId: (id?: number) =>
				utils.equipment.getEquipmentById.invalidate({ id }),
			all: () => utils.equipment.getAllEquipment.invalidate(),
		},

		LOCATION: {
			byId: (id?: number) =>
				utils.locations.getLocationById.invalidate({ id }),
			all: () => utils.locations.getAllLocations.invalidate(),
		},
		PART: {
			byId: (id?: number) => utils.parts.getPartById.invalidate({ id }),
			all: () => utils.parts.getAllParts.invalidate(),
		},
		SERVICE: {
			byId: (id?: number) =>
				utils.services.getServiceById.invalidate({ id }),
			all: () => utils.services.getAllServices.invalidate(),
		},
		VENDOR: {
			byId: (id?: number) =>
				utils.vendors.getVendorById.invalidate({ id }),
			all: () => {}, // TODO: Implement invalidateAll for vendors
		},
		WORK_ORDER: {
			byId: (id?: number) =>
				utils.workOrders.getWorkOrderById.invalidate({ id }),
			all: () => utils.workOrders.getAllWorkOrders.invalidate(),
		},
	} as const

	const invalidateById = (
		feature: keyof typeof featureInvalidateMap,
		id?: number,
	) => {
		const invalidateFn = featureInvalidateMap[feature]?.byId
		if (invalidateFn) {
			invalidateFn(id)
		}
		revalidate()
	}

	const invalidateAll = (feature: keyof typeof featureInvalidateMap) => {
		const invalidateFn = featureInvalidateMap[feature]?.all
		if (invalidateFn) {
			invalidateFn()
		}
		revalidate()
	}

	return { invalidateById, invalidateAll }
}
