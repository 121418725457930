import type { FakePendingWork } from "@/routes/_main+/work+/pending-work/mock-data"
import type { Table as TanstackTable } from "@tanstack/react-table"

import { QueueItemRow, QueueItems } from "@/components/ui/queue"
import { QueueView } from "@/components/shared/queue"

export function PendingWorkQueue({
	listDataState,
}: {
	listDataState: TanstackTable<FakePendingWork>
}) {
	return (
		<QueueView>
			<QueueItems
				listDataState={listDataState}
				renderItem={(pendingWork) => (
					<>
						<QueueItemRow>
							<div className="truncate font-bold">
								#{pendingWork.id} | {pendingWork.equipment.make}{" "}
								{pendingWork.equipment.model}
							</div>
							<div className="shrink-0 text-sm text-foreground-weak">
								{pendingWork.dateCreated}
							</div>
						</QueueItemRow>
						<QueueItemRow variant="secondary">
							<div className="shrink-0 text-foreground-weak">
								{pendingWork.workRequests.length} WR,{" "}
								{pendingWork.preventiveMaintenance.length} PM
							</div>
						</QueueItemRow>
					</>
				)}
				onRowClick={(part) => {
					listDataState.setRowSelection({
						[part.id]: true,
					})
				}}
				isActive={(part) =>
					listDataState
						.getSelectedRowModel()
						.rows.some((row) => row.id === part.id.toString())
				}
			/>
		</QueueView>
	)
}
