import { GaugeIcon, PlusIcon } from "lucide-react"

import type { Equipment } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

import { MeterDetailsCard } from "../../../../meter/details-card"

export const EquipmentDevicesSection: DetailsSectionComponent<Equipment> = ({
	data: equipment,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Devices</DetailsSectionHeading>
		<MeterDetailsCard />
		<MeterDetailsCard />
		<MeterDetailsCard />
		<Button variant="placeholder" className="mt-2 w-full gap-1">
			<PlusIcon className="size-5" />
			Add Device
		</Button>
	</DetailsSection>
)
EquipmentDevicesSection.label = "Devices"
EquipmentDevicesSection.icon = GaugeIcon
