import { sql } from "drizzle-orm"
import { check, pgTable } from "drizzle-orm/pg-core"

import { vmrsCodeFields } from "@/lib/utils/data/schema"

export const vmrsPositionCodeTable = pgTable(
	"vmrs_position_code",
	vmrsCodeFields,
	(table) => [
		check(
			"vmrs_code_check",
			// VMRS code is in the format 1, 00, or Z9
			sql`${table.code} ~ '[0-9a-zA-Z]{1,2}'`,
		),
	],
)
