import type { ReactNode } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import type { UseMutateAsyncFunction } from "@tanstack/react-query"
import type { LucideIcon } from "lucide-react"
import type { DefaultValues, FieldErrors } from "react-hook-form"
import { useForm } from "react-hook-form"
import type { ZodType } from "zod"
import { z } from "zod"

import type { Feature } from "@/config/features"
import { featureConfig } from "@/config/features"
import {
	AddEditBody,
	AddEditDialog,
	AddEditEditor,
	AddEditFooter,
	AddEditHeader,
	AddEditTitle,
} from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import { Form } from "@/components/ui/form"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

export type AddEditMode = "add" | "edit"

export interface AddEditSectionDefinition {
	label: string
	icon: LucideIcon
	component: ReactNode
}

export interface AddEditStateProps<TSchema extends ZodType> {
	open: boolean
	onOpenChange: (open: boolean) => void
	mode: AddEditMode
	initialValue?: DefaultValues<z.infer<TSchema>>
}

export type AddEditProps<TSchema extends ZodType> =
	AddEditStateProps<TSchema> & {
		summary: ReactNode
		sectionDefinitions: Record<string, AddEditSectionDefinition>
		submitHandlers: UseMutateAsyncFunction<any, any, any>
		schema: TSchema
		defaultValues: any
		feature?: Feature
	}

export const AddEdit = <TSchema extends ZodType>({
	mode,
	open,
	onOpenChange,
	initialValue,
	sectionDefinitions,
	summary,
	submitHandlers,
	feature,
	schema,
	defaultValues,
}: AddEditProps<TSchema>) => {
	type FormShape = z.infer<TSchema>

	const form = useForm<TSchema>({
		resolver: zodResolver(schema),
		defaultValues: initialValue ?? defaultValues,
	})

	const onSubmit = async (data: FormShape) => {
		console.log("Submitting form:", data)
		await submitHandlers(data)
		onOpenChange(false)
	}

	const onError = (errors: FieldErrors<FormShape>) => {
		console.error("Form validation errors:", errors)
		console.log("Form values:", form.watch())
	}

	return (
		<Form {...form}>
			<Tabs defaultValue={Object.keys(sectionDefinitions)[0]}>
				<AddEditDialog open={open} onOpenChange={onOpenChange}>
					<AddEditHeader>
						<AddEditTitle>
							{mode === "add" ? "New " : "Edit "}
							{feature ?
								featureConfig[feature].labels.singular
							:	"Item"}
						</AddEditTitle>
					</AddEditHeader>

					<AddEditBody>
						{summary}
						<AddEditEditor>
							{sectionDefinitions && (
								<TabsList className="flex h-[50px] w-full border-b-4 border-border-weaker pt-2">
									{Object.entries(sectionDefinitions).map(
										([key, Section]) => (
											<TabsTrigger
												key={key}
												value={key}
												className="grow gap-1"
											>
												<Section.icon className="size-6" />
												<span className="hidden @[60px]:block">
													{Section.label}
												</span>
											</TabsTrigger>
										),
									)}
								</TabsList>
							)}

							<div className="size-full overflow-auto px-1 [scrollbar-width:thin]">
								{Object.entries(sectionDefinitions).map(
									([key, Section]) => (
										<TabsContent
											key={key}
											value={key}
											className="size-full p-4 data-[state='inactive']:hidden"
											forceMount
										>
											{Section.component}
										</TabsContent>
									),
								)}
							</div>
						</AddEditEditor>
					</AddEditBody>
					<form onSubmit={form.handleSubmit(onSubmit, onError)}>
						<AddEditFooter>
							<Button
								onClick={(e) => {
									e.preventDefault()
									onOpenChange(false)
								}}
								size="sm"
								className="px-5"
							>
								Cancel
							</Button>
							<Button
								type="submit"
								variant="primary"
								size="sm"
								className="px-5"
								//disabled={!methods.formState.isValid}
							>
								Save and close
							</Button>
						</AddEditFooter>
					</form>
				</AddEditDialog>
			</Tabs>
		</Form>
	)
}
