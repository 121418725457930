import { integer, pgTable, serial, unique } from "drizzle-orm/pg-core"

import { employeeTable } from "../employee"
import { scopeTable } from "../user"
import { workOrderServiceTable } from "./workOrderService"

export const workOrderServiceEmployeeTable = pgTable(
	"work_order_service_employee",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		workOrderServiceId: integer("work_order_service_id")
			.references(() => workOrderServiceTable.id, { onDelete: "cascade" })
			.notNull(),
		employeeId: integer("employee_id")
			.references(() => employeeTable.id, { onDelete: "cascade" })
			.notNull(),
	},
	(table) => [
		unique().on(table.scopeId, table.workOrderServiceId, table.employeeId),
	],
)
