import { InfoIcon, PlusIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { NewCustomer } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { OverlineSmall } from "@/components/ui/typography"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const CustomerAddEditInfo: AddEditSectionDefinition = {
	label: "Info",
	icon: InfoIcon,
	component: (
		<div className="flex flex-col gap-4 p-2">
			<NameGroup />
			{/* <AddressGroup /> */}
			<AddFieldButton />
		</div>
	),
}

function NameGroup() {
	const { control } = useFormContext<NewCustomer>()
	return (
		<FormField
			control={control}
			name="name"
			render={({ field }) => (
				<FormItem>
					<FormLabel>Name</FormLabel>
					<FormControl>
						<Input {...field} placeholder={"Acme Corporation"} />
					</FormControl>
					<FormDescription>
						Enter the name of the company.
					</FormDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
		// <div className="grid grid-cols-8 gap-1">
		// 	<div className="col-span-8">
		// 		<OverlineSmall>Name</OverlineSmall>
		// 		<Input {...register("name")} placeholder={"Acme Corporation"} />
		// 	</div>
		// 	{/* <div className="col-span-2">
		// 		<OverlineSmall>ID</OverlineSmall>
		// 		<Input {...register("id")} placeholder={"ACME"} />
		// 	</div> */}
		// </div>
	)
}

function AddressGroup() {
	const { register } = useFormContext<NewCustomer>()
	return (
		<div className="">
			<OverlineSmall>Address</OverlineSmall>
			<section className="grid grid-cols-8 gap-1">
				<Input
					{...register("addresses.0.street1")}
					placeholder={"Street 1"}
					className="col-span-full"
				/>
				<Input
					{...register("addresses.0.street2")}
					placeholder={"Street 2"}
					className="col-span-full"
				/>
				<Input
					{...register("addresses.0.city")}
					placeholder={"City"}
					className="col-span-5"
				/>
				<Input
					{...register("addresses.0.state")}
					placeholder={"ST"}
					className="col-span-1"
				/>
				<Input
					{...register("addresses.0.zip")}
					placeholder={"Zip"}
					className="col-span-2"
				/>
			</section>
		</div>
	)
}

function AddFieldButton() {
	return (
		<Button
			className="w-full gap-2 border-dashed border-border text-info-text"
			variant="outline"
			onClick={() => {
				console.log("TODO: Add field")
			}}
		>
			<PlusIcon className="size-4" />
			Add Field
		</Button>
	)
}
