import { trpcClient } from "@/trpc/client"

import { DetailsViewContainer } from "@/components/shared/details/details-view"
import type { DetailsViewComponent } from "@/components/shared/details/details-view"

import { WorkOrderHistorySection } from "./sections/history"
import { WorkOrderInfoSection } from "./sections/info"
import { WorkOrderInvoiceSection } from "./sections/invoice"
import { WorkOrderMessagesSection } from "./sections/messages"
import { WorkOrderPageHeader } from "./sections/page-header"
import { WorkOrderPanelHeader } from "./sections/panel-header"
import { WorkOrderPartsSection } from "./sections/parts"

export const WorkOrdersDetailView: DetailsViewComponent = (props) => {
	const { id: workOrderId } = props
	const [result] = trpcClient.workOrders.getWorkOrderById.useSuspenseQuery({
		id: parseInt(workOrderId),
	})

	if (!result.success) {
		throw new Error("Failed to fetch work order")
	}

	return (
		<DetailsViewContainer
			data={result.value}
			headers={{ page: WorkOrderPageHeader, panel: WorkOrderPanelHeader }}
			sections={{
				info: WorkOrderInfoSection,
				parts: WorkOrderPartsSection,
				invoice: WorkOrderInvoiceSection,
				history: WorkOrderHistorySection,
				messages: WorkOrderMessagesSection,
			}}
		/>
	)
}
