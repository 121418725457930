import {
	decimal,
	integer,
	pgTable,
	serial,
	text,
	unique,
} from "drizzle-orm/pg-core"

import { workOrderBillingCategoryOptions } from "@/lib/utils/data"

import { scopeTable } from "../user"
import { workOrderTable } from "./workOrder"

export const workOrderBillingTable = pgTable(
	"work_order_billing",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		workOrderId: integer("work_order_id")
			.references(() => workOrderTable.id, { onDelete: "cascade" })
			.notNull(),
		category: text("category", {
			enum: workOrderBillingCategoryOptions,
		}).notNull(),
		baseCost: decimal("base_cost", { scale: 2 }).$type<number>().notNull(),
		markup: decimal("markup", { scale: 2 })
			.$type<number>()
			.notNull()
			.default(0.0),
		tax1: decimal("tax1", { scale: 2 })
			.$type<number>()
			.notNull()
			.default(0.0),
		tax2: decimal("tax2", { scale: 2 })
			.$type<number>()
			.notNull()
			.default(0.0),
	},
	(table) => [unique().on(table.workOrderId, table.category)],
)
