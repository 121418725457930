import type { Vendor } from "@/server/schemas"
import { Chip, ChipContainer } from "@/components/ui/chip"

interface VendorTagsSectionProps extends React.HTMLAttributes<HTMLDivElement> {
	vendor: Vendor
}
export function VendorTagsSection({
	vendor,
	className,
}: VendorTagsSectionProps) {
	//TODO: Replace with real tags
	return (
		<ChipContainer className={className}>
			<Chip variant="primary">PREFERRED</Chip>
			<Chip variant="success">NET30</Chip>
			<Chip variant="info">FAST SHIPPING</Chip>
			<Chip variant="info">ONLINE</Chip>
			<Chip variant="info">REGIONAL</Chip>
		</ChipContainer>
	)
}
