import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { serviceMetaTable } from "@/server/tables"

export const serviceMetaSelectColumns = {
	...getTableSelectColumns(serviceMetaTable, {
		scopeId: true,
		createdAt: true,
		updatedAt: true,
	}),
}
