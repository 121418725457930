import { Wrench } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { NewService } from "@/server/schemas"
import { AddEditSummary } from "@/components/ui/add-edit"
import { AddTagButton } from "@/components/shared/add-edit/add-tag"
import { Tags } from "@/components/shared/details/tags"

export const ServiceAddEditSummary = () => {
	const { watch } = useFormContext<NewService>()

	const formValues = watch()

	return (
		<AddEditSummary>
			<section className="flex w-full flex-col items-center">
				<div className="relative mb-3 grid aspect-square w-full place-items-center rounded-md border border-border-weak bg-background-weaker text-foreground-weaker">
					<Wrench size={200} fill="rgba(255,255,255,.1)" />
				</div>
			</section>
			<Tags tags={formValues.tags} centered />
			<AddTagButton feature="SERVICE" />

			<p className="font-semibold text-foreground-strong">
				{formValues.name}
			</p>
		</AddEditSummary>
	)
}
