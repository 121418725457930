import { pgTable } from "drizzle-orm/pg-core"

import { noteFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { partTable } from "./part"

export const partNoteTable = pgTable(
	"part_note",
	noteFields(scopeTable, partTable),
)
