import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"
import { EllipsisIcon } from "lucide-react"

import { useDetailsState } from "@/lib/hooks/use-details-state"
import type { Vendor } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useAddEdit } from "@/components/shared/add-edit/provider"

export function VendorHeaderOptionsMenu({ vendor }: { vendor: Vendor }) {
	const addEdits = useAddEdit()
	const details = useDetailsState()
	const { revalidate } = useRevalidator()
	const utils = trpcClient.useUtils()
	const { mutateAsync: deleteVendor } =
		trpcClient.vendors.deleteVendor.useMutation({
			onSuccess: () => {
				details.close()
				utils.vendors.getVendorById.invalidate({ id: vendor.id })

				revalidate()
			},
		})

	const handleDelete = async () => {
		await deleteVendor({ id: vendor.id })
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-5" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start">
				<DropdownMenuGroup>
					<DropdownMenuLabel>Settings</DropdownMenuLabel>
				</DropdownMenuGroup>
				<DropdownMenuItem
					onSelect={() => addEdits.openDialog("vendor", vendor)}
				>
					Edit
				</DropdownMenuItem>
				<DropdownMenuItem onSelect={() => handleDelete()}>
					Delete
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
