import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildObject,
} from "@/lib/utils/data/validators"
import { servicePartTable } from "@/server/tables"

import { basePartSchema, basePartSelectColumns } from "../part"

const servicePartOmitFields = {
	scopeId: true,
} as const
export const servicePartSelectColumns = {
	...getTableSelectColumns(servicePartTable, servicePartOmitFields),
	part: jsonBuildObject(basePartSelectColumns).as("service_part_part"),
}

export const servicePartSchema = createSelectSchema(servicePartTable)
	.omit(servicePartOmitFields)
	.extend({ part: basePartSchema })
export type ServicePart = z.infer<typeof servicePartSchema>

export const newServicePartSchema = createInsertSchema(servicePartTable).omit(
	servicePartOmitFields,
)
export type NewServicePart = z.infer<typeof newServicePartSchema>
