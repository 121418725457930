import { SettingsIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { NewVendor } from "@/server/schemas"
import {
	AddEditSection,
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { DetailsSectionHeading } from "@/components/ui/details-view"
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { OverlineSmall } from "@/components/ui/typography"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const VendorAddEditSettings: AddEditSectionDefinition = {
	label: "Settings",
	icon: SettingsIcon,
	component: (
		<AddEditSectionsGrouping>
			<SettingsGroup />
			<Accounting />
		</AddEditSectionsGrouping>
	),
}

function SettingsGroup() {
	const { control } = useFormContext<NewVendor>()
	return (
		<AddEditSection className="grid grid-cols-2">
			<AddEditSectionHeading>Settings</AddEditSectionHeading>
			{/* IsActive is not in the schema */}
			{/* <FormField
					control={control}
					name="isActive"
					render={({ field }) => (
						<FormItem className="flex flex-row items-center space-x-3 space-y-0">
							<FormControl>
								<Checkbox
									checked={field.value}
									onCheckedChange={field.onChange}
								/>
							</FormControl>

							<FormLabel>Use Flat Labor Fee</FormLabel>
						</FormItem>
					)}
				/> */}
			<FormField
				control={control}
				name="performanceRank"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Performance Rank</FormLabel>
						<FormControl>
							<Input {...field} placeholder={"0"} />
						</FormControl>

						<FormMessage />
					</FormItem>
				)}
			/>
		</AddEditSection>
	)
}

function Accounting() {
	const { register } = useFormContext<NewVendor>()

	return (
		<AddEditSection>
			<AddEditSectionHeading>Accounting</AddEditSectionHeading>
		</AddEditSection>
	)
}
