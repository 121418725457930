import { integer, pgTable, serial, unique } from "drizzle-orm/pg-core"

import { scopeTable } from "../user"
import { partTable } from "./part"

export const alternatePartTable = pgTable(
	"alternate_part",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		partId: integer("part_id")
			.references(() => partTable.id, { onDelete: "cascade" })
			.notNull(),
		alternatePartId: integer("alternate_part_id")
			.references(() => partTable.id, { onDelete: "cascade" })
			.notNull(),
	},
	(table) => [
		unique().on(table.scopeId, table.partId, table.alternatePartId),
	],
)
