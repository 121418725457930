import { useCallback, useEffect, useRef, useState } from "react"
import type { Table as TanstackTable } from "@tanstack/react-table"
import { flexRender } from "@tanstack/react-table"
import { FilterIcon, SearchIcon } from "lucide-react"

import { useFilterPanel } from "@/lib/hooks/use-filter"
import { useGlobalFilter } from "@/lib/hooks/use-list-data"
import { cn } from "@/lib/utils/classnames"
import { isTextTruncated } from "@/lib/utils/is-truncated"
import { Button } from "@/components/ui/button"
import { Input, InputSlot } from "@/components/ui/input"
import { PageActions, PageHeader } from "@/components/ui/page"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { Info } from "@/components/shared/page-info"

import { SavedFilters } from "../filters"
import { ViewSwitcherButtons } from "../layout/view-switcher"

export function Grid<TanstackTableSchema>({
	listDataState,
}: {
	listDataState: TanstackTable<TanstackTableSchema>
}): JSX.Element {
	const { isOpen, toggleFilter } = useFilterPanel()
	const { globalFilter, setGlobalFilter } = useGlobalFilter()
	const lastSelectedRowRef = useRef<string | null>(null)

	const [truncatedHeaders, setTruncatedHeaders] = useState<
		Record<string, boolean>
	>({})
	const headerRefs = useRef<Record<string, HTMLElement | null>>({})

	// Check if headers are truncated after rendering
	useEffect(() => {
		const newTruncatedHeaders: Record<string, boolean> = {}
		Object.entries(headerRefs.current).forEach(([id, element]) => {
			if (element) {
				newTruncatedHeaders[id] = isTextTruncated(element)
			}
		})
		setTruncatedHeaders(newTruncatedHeaders)
	}, [listDataState]) // Runs when table data updates

	useEffect(() => {
		setGlobalFilter("")
	}, [listDataState])

	const filters = useFilterPanel()

	// Close the detail panel automatically if it is not part of the filtered data
	useEffect(() => {
		const visibleRowIds = new Set(
			listDataState.getRowModel().rows.map((row) => row.id),
		)
		const rowSelection = listDataState.getState().rowSelection
		if (!Object.keys(rowSelection).some((id) => visibleRowIds.has(id))) {
			listDataState.resetRowSelection()
		}
	}, [
		listDataState.getState().columnFilters,
		listDataState.getState().globalFilter,
	])

	const handleRowClick = useCallback(
		(event: React.MouseEvent, rowId: string) => {
			const rows = listDataState.getRowModel().rows
			const currentRowIndex = rows.findIndex((row) => row.id === rowId)

			if (event.shiftKey && lastSelectedRowRef.current) {
				const lastRowIndex = rows.findIndex(
					(row) => row.id === lastSelectedRowRef.current,
				)

				// Calculate the range of rows to select
				const start = Math.min(currentRowIndex, lastRowIndex)
				const end = Math.max(currentRowIndex, lastRowIndex)

				// Create a new selection object
				const newSelection: Record<string, boolean> = {}
				for (let i = start; i <= end; i++) {
					if (rows[i] && rows[i]?.id) newSelection[rows[i]!.id] = true
				}

				// Merge with existing selection
				listDataState.setRowSelection((prev) => ({
					...prev,
					...newSelection,
				}))
			} else if (event.ctrlKey || event.metaKey) {
				// Check if the row is already selected
				listDataState.getRow(rowId).toggleSelected()
			} else {
				// Handle single selection
				listDataState.setRowSelection({ [rowId]: true })
			}

			lastSelectedRowRef.current = rowId
		},
		[listDataState],
	)

	const isRowSelected =
		Object.keys(listDataState.options.state.rowSelection ?? {}).length > 0 ?
			true
		:	false

	return (
		<TooltipProvider delayDuration={300}>
			<PageHeader>
				<Info />
				<PageActions
					className={cn(isRowSelected ? "md:hidden lg:flex" : "")}
				>
					<Input
						placeholder="Search..."
						value={globalFilter}
						className={cn(
							"w-full",
							isRowSelected ?
								"md:hidden lg:flex lg:max-w-[100px] xl:max-w-[200px]"
							:	"xl:min-w-[500px] xl:max-w-[500px]",
						)}
						onChange={(e) => setGlobalFilter(e.target.value)}
					>
						<InputSlot>
							<SearchIcon />
						</InputSlot>
					</Input>
					{!filters.isOpen && (
						<div
							className={cn(
								"w-full xl:max-w-[200px]",
								isRowSelected ?
									"md:hidden lg:hidden lg:max-w-[100px] xl:flex xl:max-w-[120px]"
								:	"xl:max-w-[200px]",
							)}
						>
							<SavedFilters />
						</div>
					)}
					<Button
						variant={isOpen ? "primary" : "ghost"}
						className=""
						size="icon"
						onClick={toggleFilter}
					>
						<FilterIcon className="size-4" />
					</Button>
				</PageActions>
				<div
					className={cn(
						isRowSelected && filters.isOpen ?
							"md:hidden lg:flex"
						:	"",
					)}
				>
					<ViewSwitcherButtons />
				</div>
			</PageHeader>
			<Table>
				<TableHeader>
					{listDataState.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header, index) => {
								const columnHeader =
									header.isPlaceholder ? null : (
										flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)
									)

								return (
									<Tooltip key={header.id}>
										<TooltipTrigger asChild>
											<TableHead
												key={header.id}
												className={cn(
													"sticky top-0 z-20 min-w-[40px] max-w-[100px] truncate bg-background-weak px-2 capitalize",
													index === 0 &&
														"left-0 z-30",
												)}
												ref={(element) =>
													(headerRefs.current[
														header.id
													] = element)
												}
											>
												{columnHeader}
											</TableHead>
										</TooltipTrigger>
										{typeof columnHeader === "string" &&
											truncatedHeaders[header.id] && (
												<TooltipContent side="bottom">
													{columnHeader}
												</TooltipContent>
											)}
									</Tooltip>
								)
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{listDataState.getRowModel().rows.map((row) => (
						<TableRow
							key={row.id}
							data-state={row.getIsSelected() && "selected"}
							onClick={(e) => handleRowClick(e, row.id)}
							className="group cursor-pointer"
						>
							{row.getVisibleCells().map((cell, index) => (
								<TableCell
									key={cell.id}
									className={cn(
										"min-w-[50px] truncate bg-background px-3 group-hover:bg-background-weaker group-data-[state=selected]:bg-selected",
										index === 0 && "sticky left-0 z-10",
									)}
								>
									{flexRender(
										cell.column.columnDef.cell,
										cell.getContext(),
									)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TooltipProvider>
	)
}
