import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { tagFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { equipmentTable } from "./equipment"

export const equipmentTagTable = pgTable(
	"equipment_tag",
	tagFields(scopeTable, equipmentTable),
	(table) => [primaryKey({ columns: [table.rowId, table.tag] })],
)
