import { forwardRef } from "react"
import TextareaAutosize from "react-textarea-autosize"

import { cn } from "@/lib/utils/classnames"

export interface TextareaProps
	extends React.ComponentPropsWithoutRef<typeof TextareaAutosize> {}

const Textarea = forwardRef<
	React.ElementRef<typeof TextareaAutosize>,
	TextareaProps
>(({ className, ...props }, ref) => {
	return (
		<TextareaAutosize
			className={cn(
				"flex min-h-[60px] w-full rounded-md border px-3 py-2 text-sm shadow-sm placeholder:text-foreground-weak focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus disabled:cursor-not-allowed disabled:opacity-50",
				className,
			)}
			ref={ref}
			{...props}
		/>
	)
})
Textarea.displayName = "Textarea"

export { Textarea }
