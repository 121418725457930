import { trpcClient } from "@/trpc/client"

import type { DetailsViewComponent } from "@/components/shared/details/details-view"
import { DetailsViewContainer } from "@/components/shared/details/details-view"

import { ServicesDetailProvider } from "./context"
import { ServiceInfoSection } from "./sections/info"
import { ServicesPageHeader } from "./sections/page-header"
import { ServicesPanelHeader } from "./sections/panel-header"
import { ServicePMSection } from "./sections/pm"
import { ServiceSettingsSection } from "./sections/settings"

export const ServicesDetailsView: DetailsViewComponent = (props) => {
	const { id: serviceId } = props
	const [result] = trpcClient.services.getServiceById.useSuspenseQuery({
		id: parseInt(serviceId),
	})
	if (!result.success) {
		throw new Error("Failed to fetch equipment")
	}

	return (
		<ServicesDetailProvider>
			<DetailsViewContainer
				data={result.value}
				headers={{
					page: ServicesPageHeader,
					panel: ServicesPanelHeader,
				}}
				sections={{
					info: ServiceInfoSection,
					settings: ServiceSettingsSection,
					pm: ServicePMSection,
				}}
			/>
		</ServicesDetailProvider>
	)
}
