import {
	decimal,
	integer,
	pgTable,
	serial,
	text,
	unique,
} from "drizzle-orm/pg-core"

import { customerTable } from "../customer"
import { scopeTable } from "../user"
import { workOrderTable } from "./workOrder"

export const workOrderInvoiceTable = pgTable(
	"work_order_invoice",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		workOrderId: integer("work_order_id")
			.references(() => workOrderTable.id, { onDelete: "cascade" })
			.notNull(),
		customerId: integer("customer_id")
			.references(() => customerTable.id, { onDelete: "cascade" })
			.notNull(),
		code: text("code").notNull(),
		total: decimal("total", { scale: 2 }).$type<number>().notNull(),
	},
	(table) => [unique().on(table.code, table.scopeId)],
)
