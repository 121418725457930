import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"
import { CheckIcon, PencilIcon, PlusIcon } from "lucide-react"

import type { Equipment } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { PreventiveMaintenanceCard } from "@/components/features/preventive-maintenance/details-card"

export function EquipmentSubscribedPMSection({
	equipment,
}: {
	equipment: Equipment
}) {
	const utils = trpcClient.useUtils()
	const { revalidate } = useRevalidator()

	const { data: servicesResult, isLoading: servicesAreLoading } =
		trpcClient.services.getAllServices.useQuery()

	const { data: pmAssignedEquipmentResult, isLoading: pmAssignedAreLoading } =
		trpcClient.maintenance.getPMAssignedEquipmentByEquipmentId.useQuery(
			equipment.id,
		)

	const addPmAssignedEquipment =
		trpcClient.maintenance.createPmAssignedEquipment.useMutation({
			onSuccess: () => {
				utils.maintenance.getPMAssignedEquipmentByEquipmentId.invalidate(
					equipment.id,
				)
				revalidate()
			},
		})

	const deletePmAssignedEquipment =
		trpcClient.maintenance.deletePMAssignedEquipment.useMutation({
			onSuccess: () => {
				utils.maintenance.getPMAssignedEquipmentByEquipmentId.invalidate(
					equipment.id,
				)
				revalidate()
			},
		})

	if (!servicesAreLoading && !servicesResult?.success)
		throw new Error("Error loading services in equipment add-edit")

	const pmServices =
		servicesResult?.success ?
			servicesResult.value.filter(
				(service) =>
					service.pmTimeElapsed?.id || service.pmMeterElapsed?.id,
			)
		:	[]

	const subscribedServiceIds = new Set(
		pmAssignedEquipmentResult?.success ?
			pmAssignedEquipmentResult.value.map((pm) => pm.serviceId)
		:	[],
	)

	const handleSelectService = (serviceId: number) => {
		if (subscribedServiceIds.has(serviceId)) {
			// Find the PM assignment to delete
			const pmToDelete =
				pmAssignedEquipmentResult?.success ?
					pmAssignedEquipmentResult.value.find(
						(pm) => pm.serviceId === serviceId,
					)
				:	null

			if (pmToDelete) {
				deletePmAssignedEquipment.mutate(pmToDelete.id)
			}
		} else {
			addPmAssignedEquipment.mutate({
				equipmentId: equipment.id,
				serviceId: serviceId,
			})
		}
	}

	return (
		<DetailsSection>
			<DetailsSectionHeading className="flex justify-between">
				<DropdownMenu>
					Subscribed PM Services
					<DropdownMenuTrigger asChild>
						<Button variant="ghost" size="icon">
							<PencilIcon className="size-5" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent
						align="end"
						className="max-h-[350px] w-64 overflow-auto"
					>
						{pmServices.map((service) => (
							<DropdownMenuItem
								key={service.id}
								onClick={() => handleSelectService(service.id)}
								className="flex items-center gap-2 py-3 pr-2"
							>
								<div className="flex grow flex-col">
									<span className="font-semibold">
										{service.name}
									</span>
									<span className="text-sm text-foreground-weak">
										{service.pmTimeElapsed?.id ?
											`Every ${service.pmTimeElapsed.dueFrequencyValue} ${service.pmTimeElapsed.unitOfMeasure}`
										:	`Every ${service.pmMeterElapsed?.dueFrequencyValue} ${service.pmMeterElapsed?.unitOfMeasure}`
										}
									</span>
								</div>
								{subscribedServiceIds.has(service.id) && (
									<CheckIcon className="size-4 shrink-0 text-primary" />
								)}
							</DropdownMenuItem>
						))}
						{pmServices.length === 0 && (
							<DropdownMenuItem disabled>
								No PM services available
							</DropdownMenuItem>
						)}
					</DropdownMenuContent>
				</DropdownMenu>
			</DetailsSectionHeading>
			{pmAssignedAreLoading ?
				<div>Loading...</div>
			: pmAssignedEquipmentResult?.success ?
				<div className="flex flex-col gap-2">
					{pmAssignedEquipmentResult.value.map((pm, index) => {
						const correspondingService = pmServices.find(
							(serv) => serv.id === pm.serviceId,
						)
						return (
							correspondingService && (
								<PreventiveMaintenanceCard
									key={index}
									service={correspondingService}
								/>
							)
						)
					})}
				</div>
			:	<div className="grid place-items-center rounded-md bg-background-weak p-10 font-semibold text-foreground-weak">
					No PM services subscribed
				</div>
			}
		</DetailsSection>
	)
}
