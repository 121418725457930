import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { ServiceCardSuggestedPM } from "@/components/features/services/details-cards/suggested-pm"

export function EquipmentNextDueSection() {
	return (
		<DetailsSection>
			<DetailsSectionHeading>Next PM Service Due</DetailsSectionHeading>
			<div className="grid place-items-center rounded-md bg-background-weak p-10 font-semibold text-foreground-weak">
				No upcoming PM services
			</div>
		</DetailsSection>
	)
}
