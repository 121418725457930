import { DetailsSection } from "@/components/ui/details-view"
import { Link } from "@/components/ui/link"

export function OpenWorkOrders() {
	return (
		<DetailsSection>
			<div className="flex flex-col gap-2 rounded-md border border-primary p-4">
				<div>
					There are open work orders for this equipment. Select one to
					open and add services.
				</div>
				<Link to="/">18-00044, 3 services, In progress</Link>
				<Link to="/">18-00052, 1 service, To do</Link>
			</div>
		</DetailsSection>
	)
}
