import React from "react"
import { DayPicker } from "react-day-picker"

import { cn } from "@/lib/utils/classnames"

import { buttonClasses } from "./button"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
	className,
	classNames,
	showOutsideDays = true,
	...props
}: CalendarProps) {
	return (
		<DayPicker
			showOutsideDays={showOutsideDays}
			className={cn("flex p-3", className)}
			classNames={{
				months: "relative flex flex-col sm:flex-row gap-4",
				month: "w-full",
				month_caption:
					"relative mx-10 mb-1 flex h-9 items-center justify-center z-20 pb-2",
				caption_label: "hidden",
				nav: "absolute top-0 flex w-full justify-between z-10",
				button_previous: cn(
					buttonClasses({ variant: "outline" }),
					"h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
				),
				button_next: cn(
					buttonClasses({ variant: "outline" }),
					"h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
				),
				weekday:
					"size-9 p-0 text-xs font-medium text-muted-foreground/80",
				day_button:
					"relative flex size-9 items-center justify-center whitespace-nowrap rounded-lg p-0 text-foreground outline-offset-2 group-[[data-selected]:not(.range-middle)]:[transition-property:color,background-color,border-radius,box-shadow] group-[[data-selected]:not(.range-middle)]:duration-150 focus:outline-none group-data-[disabled]:pointer-events-none focus-visible:z-10 hover:bg-accent group-data-[selected]:bg-primary hover:text-foreground group-data-[selected]:text-primary-foreground group-data-[disabled]:text-foreground/30 group-data-[disabled]:line-through group-data-[outside]:text-foreground/30 group-data-[outside]:group-data-[selected]:text-primary-foreground focus-visible:outline focus-visible:outline-2 focus-visible:outline-ring/70 group-[.range-start:not(.range-end)]:rounded-e-none group-[.range-end:not(.range-start)]:rounded-s-none group-[.range-middle]:rounded-none group-data-[selected]:group-[.range-middle]:bg-accent group-data-[selected]:group-[.range-middle]:text-foreground",
				day: "group size-9 px-0 text-sm",
				range_start: "range-start",
				range_end: "range-end",
				range_middle: "range-middle",
				today: "bg-accent text-accent-foreground rounded-full",
				outside:
					"text-muted-foreground data-selected:bg-accent/50 data-selected:text-muted-foreground",
				hidden: "invisible",
				disabled: "text-muted-foreground opacity-50",
				week_number:
					"size-9 p-0 text-xs font-medium text-muted-foreground/80",
				...classNames,
			}}
			{...props}
		/>
	)
}
Calendar.displayName = "Calendar"

export { Calendar }
