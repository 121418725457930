import { createContext, useContext, useEffect, useRef, useState } from "react"

import { useDetailsState } from "@/lib/hooks/use-details-state"
import { useFilterPanel } from "@/lib/hooks/use-filter"
import { useGlobalView } from "@/lib/hooks/use-global-view"
import { cn } from "@/lib/utils/classnames"
import { Page } from "@/components/ui/page"
import { useNavbar } from "@/components/global/navbar/context"

import { Details } from "../details"
import { NothingSelected } from "../details/nothing-selected"

export type ListView = "queue" | "board" | "grid"
export const DEFAULT_LIST_VIEW: ListView = "queue"

interface ListViewContextValue {
	availableViews: ListView[]
}

const ListViewContext = createContext<ListViewContextValue>({
	availableViews: [DEFAULT_LIST_VIEW],
})

interface ViewComponents {
	queue: React.ReactNode
	active: React.ReactNode
}

export function useLayoutContext() {
	const context = useContext(ListViewContext)
	if (!context) {
		throw new Error("useListView must be used within a ListViewProvider")
	}
	return context
}

interface LayoutProps {
	listViews: ViewComponents
	filterComponent: React.ReactNode
	children?: React.ReactNode
}

export function Layout({ listViews, filterComponent, children }: LayoutProps) {
	const details = useDetailsState()
	const { view, availableViews } = useGlobalView()
	const { isExpanded, toggleNavbar } = useNavbar()
	const filters = useFilterPanel()
	const restoreNavbar = useRef(false)

	const [pageWidth, setPageWidth] = useState(
		typeof window !== "undefined" ? window.innerWidth : 0,
	)

	useEffect(() => {
		if (typeof window === "undefined") return

		const handleResize = () => {
			setPageWidth(window.innerWidth)
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	useEffect(() => {
		if (filters.isOpen) {
			if (isExpanded && !restoreNavbar.current && pageWidth >= 1024) {
				restoreNavbar.current = true
				toggleNavbar()
			} else if (isExpanded && restoreNavbar.current) {
				restoreNavbar.current = false
			}
		} else if (restoreNavbar.current) {
			restoreNavbar.current = false
			toggleNavbar()
		}

		if (pageWidth < 1024) {
			restoreNavbar.current = false
		}
	}, [filters.isOpen, isExpanded, toggleNavbar, pageWidth])

	return (
		<ListViewContext.Provider
			value={{
				availableViews,
			}}
		>
			{/* Detail Container */}
			{view === "grid" ?
				<Page
					className={cn(
						`${filters.isOpen ? "" : "hidden"}`,
						"min-w-[310px] max-w-[310px]",
						"max-md:absolute max-md:inset-0 max-md:w-full",
					)}
				>
					{filterComponent}
				</Page>
			:	null}
			{/* List Mobile */}
			<Page className={cn("w-full md:hidden")}>{listViews.queue}</Page>

			{/* List Desktop */}
			<Page
				className={cn(
					"max-md:hidden",
					view === "queue" ?
						"shrink-0 grow-0 sm:w-[320px]"
					:	"grow basis-[1600px]",
				)}
			>
				{listViews.active}
			</Page>

			{/* Detail Container */}
			{details.isOpen ?
				<Page
					className={cn(
						view === "queue" ?
							"grow flex-col sm:flex"
						:	"sm:min-w-96 3xl:min-w-[650px] 3xl:basis-1/2",
						"max-md:absolute max-md:inset-0 max-md:w-full",
					)}
				>
					<Details />
				</Page>
			: view === "queue" ?
				<NothingSelected />
			:	null}

			{children}
		</ListViewContext.Provider>
	)
}
