import { useState } from "react"
import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"
import { PlusIcon } from "lucide-react"

import type { WorkOrder } from "@/server/schemas"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import {
	Command,
	CommandEmpty,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command"
import {
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionHeadingCount,
} from "@/components/ui/details-view"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"
import { ServiceCard } from "@/components/features/services/details-cards"

export function WorkOrderServicesSection({
	workOrder,
}: {
	workOrder: WorkOrder
}) {
	return (
		<DetailsSection>
			<Accordion type="multiple" defaultValue={["services"]}>
				<AccordionItem value="services">
					<AccordionTrigger className="px-0">
						<DetailsSectionHeading>
							Services
							<DetailsSectionHeadingCount>
								({workOrder.services.length})
							</DetailsSectionHeadingCount>
						</DetailsSectionHeading>
					</AccordionTrigger>
					<AccordionContent className="mb-5 flex flex-col gap-3 p-0 pt-1">
						{workOrder.services.map((service, index) => (
							<ServiceCard key={index} service={service} />
						))}
					</AccordionContent>
				</AccordionItem>
			</Accordion>
			<ServicesDropdown workOrderId={workOrder.id} />
		</DetailsSection>
	)
}

function ServicesDropdown({ workOrderId }: { workOrderId: number }) {
	const [open, setOpen] = useState(false)
	const { data: servicesResult, isLoading: servicesAreLoading } =
		trpcClient.services.getAllServices.useQuery()

	const utils = trpcClient.useUtils()
	const { revalidate } = useRevalidator()

	const { mutateAsync: addService } =
		trpcClient.workOrders.createWorkOrderService.useMutation({
			onSuccess: () => {
				utils.workOrders.getWorkOrderById.invalidate()
				revalidate()
			},
		})

	return (
		<Popover open={open} onOpenChange={setOpen} modal>
			<PopoverTrigger asChild>
				<Button variant="placeholder" role="button" size="sm">
					<PlusIcon className="size-5" />
					Add Service
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[calc(var(--radix-popover-trigger-width)*.9)] p-0">
				<Command>
					<CommandInput placeholder="Search" />
					{servicesAreLoading ?
						<CommandEmpty>Loading...</CommandEmpty>
					: (
						servicesResult?.success &&
						servicesResult.value.length > 0
					) ?
						<>
							<CommandList className="[scrollbar-width:thin]">
								{servicesResult.value.map((service) => (
									<CommandItem
										key={service.id}
										value={service.name}
										onSelect={() =>
											addService({
												workOrderId,
												serviceId: service.id,
												status: "NOT STARTED",
											})
										}
									>
										<div className="flex gap-2">
											<span>{service.name}</span>
										</div>
									</CommandItem>
								))}
							</CommandList>
							<CommandEmpty>No results</CommandEmpty>
						</>
					:	<CommandEmpty>No results</CommandEmpty>}
				</Command>
			</PopoverContent>
		</Popover>
	)
}
