import { InfoIcon, PlusIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { Location, NewLocation } from "@/server/schemas"
import {
	AddEditSection,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import {
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { OverlineSmall } from "@/components/ui/typography"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const LocationAddEditInfo: AddEditSectionDefinition = {
	label: "Info",
	icon: InfoIcon,
	component: (
		<AddEditSectionsGrouping>
			<NameGroup />
			<AddressGroup />
			{/* <AddFieldButton /> */}
		</AddEditSectionsGrouping>
	),
}

function NameGroup() {
	const { control } = useFormContext<NewLocation>()
	return (
		<AddEditSection className="grid grid-cols-8">
			<FormField
				control={control}
				name="name"
				render={({ field }) => (
					<FormItem className="col-span-6">
						<FormLabel>Name</FormLabel>
						<Input {...field} placeholder={"Warehouse 1"} />
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				control={control}
				name="workstationName"
				render={({ field }) => (
					<FormItem className="col-span-2">
						<FormLabel>ID</FormLabel>
						<Input
							{...field}
							placeholder={"WH1"}
							value={field.value ?? ""}
						/>
						<FormMessage />
					</FormItem>
				)}
			/>
		</AddEditSection>
	)
}

function AddressGroup() {
	const { control } = useFormContext<NewLocation>()
	return (
		<AddEditSection className="grid grid-cols-8">
			<FormField
				control={control}
				name="street1"
				render={({ field }) => (
					<FormItem className="col-span-full">
						<FormLabel>Address</FormLabel>
						<Input {...field} placeholder={"Street 1"} />
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				control={control}
				name="street2"
				render={({ field }) => (
					<FormItem className="col-span-full">
						<Input
							{...field}
							value={field.value ?? ""}
							placeholder={"Street 2"}
						/>
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				control={control}
				name="city"
				render={({ field }) => (
					<FormItem className="col-span-5">
						<Input
							{...field}
							value={field.value ?? ""}
							placeholder={"City"}
						/>
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				control={control}
				name="state"
				render={({ field }) => (
					<FormItem className="col-span-1">
						<Input
							{...field}
							value={field.value ?? ""}
							placeholder={"State"}
						/>
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				control={control}
				name="zip"
				render={({ field }) => (
					<FormItem className="col-span-2">
						<Input
							{...field}
							value={field.value ?? ""}
							placeholder={"Zip"}
						/>
						<FormMessage />
					</FormItem>
				)}
			/>
		</AddEditSection>
	)
}

function AddFieldButton() {
	return (
		<Button
			className="w-full gap-2 border-dashed border-border text-info-text"
			variant="outline"
			onClick={() => {
				console.log("TODO: Add field")
			}}
		>
			<PlusIcon className="size-4" />
			Add Field
		</Button>
	)
}
