export const unitOfMeasureAreaUnitOptions = ["sqm", "sqf"] as const

export const unitOfMeasureTimeUnitOptions = ["sec", "min", "hr"] as const
export const unitOfMeasureDateUnitOptions = ["day", "week", "month"] as const

export const unitOfMeasureDistanceUnitOptions = ["km", "mi"] as const

export const unitOfMeasureLengthUnitOptions = [
	"mm",
	"cm",
	"m",
	"in",
	"ft",
	"yd",
] as const

export const unitOfMeasureWeightUnitOptions = [
	"gm",
	"kg",
	"oz",
	"lb",
	"c30",
] as const

export const unitOfMeasureVolumeUnitOptions = [
	"ml",
	"l",
	"cup",
	"pt",
	"qt",
	"gal",
	"bbl",
	"iqt",
	"igl",
] as const

export const unitOfMeasurePressureUnitOptions = ["kpa", "psi"] as const

export const unitOfMeasureCountUnitOptions = [
	"ea",
	"dz",
	"p02",
	"p03",
	"p04",
	"p05",
	"p10",
	"p20",
	"p25",
	"p50",
	"p75",
	"p1c",
	"set",
] as const

export const unitOfMeasureUnitOptions = [
	...unitOfMeasureAreaUnitOptions,
	...unitOfMeasureTimeUnitOptions,
	...unitOfMeasureDateUnitOptions,
	...unitOfMeasureDistanceUnitOptions,
	...unitOfMeasureLengthUnitOptions,
	...unitOfMeasureWeightUnitOptions,
	...unitOfMeasureVolumeUnitOptions,
	...unitOfMeasurePressureUnitOptions,
	...unitOfMeasureCountUnitOptions,
] as const
export type UnitOfMeasureUnit = (typeof unitOfMeasureUnitOptions)[number]

export const unitOfMeasureTypeOptions = [
	"Area",
	"Time",
	"Date",
	"Distance",
	"Length",
	"Weight",
	"Volume",
	"Pressure",
	"Count",
] as const
export type UnitOfMeasureType = (typeof unitOfMeasureTypeOptions)[number]

export const unitOfMeasureOptions = [
	{ name: "Square meter", unit: "sqm", type: "Area" },
	{ name: "Square foot", unit: "sqf", type: "Area" },
	{ name: "Second", unit: "sec", type: "Time" },
	{ name: "Minute", unit: "min", type: "Time" },
	{ name: "Hour", unit: "hr", type: "Time" },
	{ name: "Day", unit: "day", type: "Date" },
	{ name: "Week", unit: "week", type: "Date" },
	{ name: "Month", unit: "month", type: "Date" },
	{ name: "Kilometer", unit: "km", type: "Distance" },
	{ name: "Mile", unit: "mi", type: "Distance" },
	{ name: "Millimeter", unit: "mm", type: "Length" },
	{ name: "Centimeter", unit: "cm", type: "Length" },
	{ name: "Meter", unit: "m", type: "Length" },
	{ name: "Inch", unit: "in", type: "Length" },
	{ name: "Foot", unit: "ft", type: "Length" },
	{ name: "Yard", unit: "yd", type: "Length" },
	{ name: "Gram", unit: "gm", type: "Weight" },
	{ name: "Kilogram", unit: "kg", type: "Weight" },
	{ name: "Ounce", unit: "oz", type: "Weight" },
	{ name: "Pound", unit: "lb", type: "Weight" },
	{ name: "30lb Cylinder", unit: "c30", type: "Weight" },
	{ name: "Milliliter", unit: "ml", type: "Volume" },
	{ name: "Liter", unit: "l", type: "Volume" },
	{ name: "Cup", unit: "cup", type: "Volume" },
	{ name: "Pint", unit: "pt", type: "Volume" },
	{ name: "Quart", unit: "qt", type: "Volume" },
	{ name: "Gallon", unit: "gal", type: "Volume" },
	{ name: "Barrel", unit: "bbl", type: "Volume" },
	{ name: "Imperial Quart", unit: "iqt", type: "Volume" },
	{ name: "Imperial Gallon", unit: "igl", type: "Volume" },
	{ name: "Kilopascal", unit: "kpa", type: "Pressure" },
	{ name: "Pounds/sq inch", unit: "psi", type: "Pressure" },
	{ name: "Each", unit: "ea", type: "Count" },
	{ name: "Dozen", unit: "dz", type: "Count" },
	{ name: "Package of 2", unit: "p02", type: "Count" },
	{ name: "Package of 3", unit: "p03", type: "Count" },
	{ name: "Package of 4", unit: "p04", type: "Count" },
	{ name: "Package of 5", unit: "p05", type: "Count" },
	{ name: "Package of 10", unit: "p10", type: "Count" },
	{ name: "Package of 20", unit: "p20", type: "Count" },
	{ name: "Package of 25", unit: "p25", type: "Count" },
	{ name: "Package of 50", unit: "p50", type: "Count" },
	{ name: "Package of 75", unit: "p75", type: "Count" },
	{ name: "Package of 100", unit: "p1c", type: "Count" },
	{ name: "Set of Items", unit: "set", type: "Count" },
] as const
export type UnitOfMeasure = (typeof unitOfMeasureOptions)[number]
export type UnitOfMeasureName = (typeof unitOfMeasureOptions)[number]["name"]

export interface QuantityWithUnit {
	quantity: number
	unit: UnitOfMeasureUnit
}

/**
 * Returns the ratio of the unit to its base unit (ex. Minute returns 60 seconds, Millimeter returns 1 millimeter)
 *
 * @param unit Unit to convert
 * @returns Ratio of the unit to the base unit
 */
export function getUnitToBaseUnitRatio(unit: UnitOfMeasureUnit): number {
	switch (unit) {
		// Area base unit is SQUARE METER
		case "sqm":
			return 1
		case "sqf":
			return 0.092903 // 1 / 10.764
		// Time base unit is SECOND
		case "sec":
			return 1
		case "min":
			return 60
		case "hr":
			return 3600
		// Distance base unit is KILOMETER
		case "km":
			return 1
		case "mi":
			return 1.60934
		// Length base unit is MILLIMETER
		case "mm":
			return 1
		case "cm":
			return 10
		case "m":
			return 1000
		case "in":
			return 25.4
		case "ft":
			return 304.8
		case "yd":
			return 914.4
		// Weight base unit is GRAM
		case "gm":
			return 1
		case "kg":
			return 1000
		case "oz":
			return 28.3495
		case "lb":
			return 453.592
		case "c30":
			return 13607.76
		// Volume base unit is MILLILITER
		case "ml":
			return 1
		case "l":
			return 1000
		case "cup":
			return 236.588
		case "pt":
			return 473.176
		case "qt":
			return 946.353
		case "gal":
			return 3785.412
		case "bbl":
			return 208197.66
		case "iqt":
			return 1136.52
		case "igl":
			return 4546.09
		// Pressure base unit is KILOPASCAL
		case "kpa":
			return 1
		case "psi":
			return 6.89476
		// Count base unit is EACH
		case "ea":
			return 1
		case "dz":
			return 12
		case "p02":
			return 2
		case "p03":
			return 3
		case "p04":
			return 4
		case "p05":
			return 5
		case "p10":
			return 10
		case "p20":
			return 20
		case "p25":
			return 25
		case "p50":
			return 50
		case "p75":
			return 75
		case "p1c":
			return 100
		case "set":
			return 1
		default:
			return 0
	}
}

/**
 * Returns the complete definition of the unit of measure
 *
 * @param unit Unit to find
 * @returns Unit of Measure, or undefined if that unit does not exist
 */
export function getUnitOfMeasure(
	unit: UnitOfMeasureUnit,
): UnitOfMeasure | undefined {
	return unitOfMeasureOptions.find((elem) => elem.unit === unit)
}

/**
 * Converts a unit to another unit of the same type (ex. Feet to Centimeter, but not Length to Distance).
 * Note that KM/MI cannot be converted to MM/CM/IN/FT/YD.
 *
 * @param from Unit to convert from
 * @param to Unit to convert to
 * @returns Unit in the new format, or undefined if the units do not exist or are not convertible
 */
export function convertUnit(
	from: QuantityWithUnit,
	to: UnitOfMeasureUnit,
): QuantityWithUnit | undefined {
	// Don't convert to self
	if (from.unit === to) {
		return from
	}

	// Check that the units both exist and are convertible to each other
	const fromUnit = getUnitOfMeasure(from.unit)
	const toUnit = getUnitOfMeasure(to)
	if (!fromUnit || !toUnit || fromUnit.type !== toUnit.type) {
		return undefined
	}
	return {
		quantity:
			from.quantity *
			(getUnitToBaseUnitRatio(fromUnit.unit) /
				getUnitToBaseUnitRatio(toUnit.unit)),
		unit: to,
	}
}
