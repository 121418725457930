import { useEffect, useState } from "react"
import { Settings2 } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { NewService } from "@/server/schemas"
import {
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { Checkbox } from "@/components/ui/checkbox"
import { FieldGroup } from "@/components/ui/feild-group"
import { Label } from "@/components/ui/form"
import { GridRow } from "@/components/ui/gridRow"
import { Input } from "@/components/ui/input"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { Overline } from "@/components/ui/typography"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const ServiceAddEditSettings: AddEditSectionDefinition = {
	label: "Settings",
	icon: Settings2,
	component: (
		<AddEditSectionsGrouping className="pb-8">
			<SettingsGroup />
			<AccountingInfoGroup />
		</AddEditSectionsGrouping>
	),
}

function SettingsGroup() {
	const { register, setValue } = useFormContext<NewService>()

	const [system, setSystem] = useState<string | undefined>()
	const [assembly, setAssembly] = useState<string | undefined>()
	const [component, setComponent] = useState<string | undefined>()

	useEffect(() => {
		const vmrsConcatString = `${assembly}-${system}-${component}`
		if (system && assembly && component)
			setValue("vmrsCode", vmrsConcatString)
	}, [system, assembly, component, setValue])

	const handleUpdateVmrsCode = (
		code: "system" | "assembly" | "component",
		value: string,
	) => {
		if (code == "system") {
			setSystem(value)
		} else if (code == "assembly") {
			setAssembly(value)
		} else if (code == "component") {
			setComponent(value)
		}
	}

	return (
		<div className="flex flex-col gap-3">
			<AddEditSectionHeading>Info</AddEditSectionHeading>
			<div className="mb-3 flex flex-wrap gap-5">
				<div className="flex items-center gap-1.5">
					<Checkbox id="var-isActive" {...register("isActive")} />
					<Label htmlFor="var-isActive" className="cursor-pointer">
						Active
					</Label>
				</div>
				<div className="flex items-center gap-1.5">
					<Checkbox
						id="var-isPermitMultipleEntry"
						{...register("permitMultipleEntry")}
					/>
					<Label
						htmlFor="var-isPermitMultipleEntry"
						className="cursor-pointer"
					>
						Permit Multiple Entry
					</Label>
				</div>
			</div>
			<GridRow columnSize="md">
				<FieldGroup label="service Group">
					<Input
						{...register("serviceGroupId", {
							setValueAs: (value) =>
								value ? Number(value) : null,
						})}
						placeholder="123"
					/>
				</FieldGroup>
				<FieldGroup label="service code">
					<Input {...register("code")} placeholder="eg, 001-001" />
				</FieldGroup>
				<FieldGroup label="service type">
					<Select>
						<SelectTrigger>
							<SelectValue placeholder="Choose..." />
						</SelectTrigger>
						<SelectContent position="popper">
							<SelectItem value="1">1</SelectItem>
							<SelectItem value="2">2</SelectItem>
						</SelectContent>
					</Select>
				</FieldGroup>
			</GridRow>
			<Overline className="mt-3">VMRS</Overline>
			<div className="flex flex-col gap-3">
				<FieldGroup label="system">
					<Select
						value={system}
						onValueChange={(value) =>
							handleUpdateVmrsCode("system", value)
						}
					>
						<SelectTrigger>
							<SelectValue placeholder="Choose..." />
						</SelectTrigger>
						<SelectContent position="popper">
							<SelectItem value="01">01</SelectItem>
							<SelectItem value="02">02</SelectItem>
						</SelectContent>
					</Select>
				</FieldGroup>
				<FieldGroup label="assembly">
					<Select
						value={assembly}
						onValueChange={(value) =>
							handleUpdateVmrsCode("assembly", value)
						}
					>
						<SelectTrigger>
							<SelectValue placeholder="Choose..." />
						</SelectTrigger>
						<SelectContent position="popper">
							<SelectItem value="01">01</SelectItem>
							<SelectItem value="02">02</SelectItem>
						</SelectContent>
					</Select>
				</FieldGroup>
				<FieldGroup label="component">
					<Select
						value={component}
						onValueChange={(value) =>
							handleUpdateVmrsCode("component", value)
						}
					>
						<SelectTrigger>
							<SelectValue placeholder="Choose..." />
						</SelectTrigger>
						<SelectContent position="popper">
							<SelectItem value="01">01</SelectItem>
							<SelectItem value="02">02</SelectItem>
						</SelectContent>
					</Select>
				</FieldGroup>
				<FieldGroup label="Default failure">
					<Select>
						<SelectTrigger>
							<SelectValue placeholder="Choose..." />
						</SelectTrigger>
						<SelectContent position="popper">
							<SelectItem value="01">01</SelectItem>
							<SelectItem value="02">02</SelectItem>
						</SelectContent>
					</Select>
				</FieldGroup>
				<FieldGroup label="Default reason">
					<Select>
						<SelectTrigger>
							<SelectValue placeholder="Choose..." />
						</SelectTrigger>
						<SelectContent position="popper">
							<SelectItem value="1">1</SelectItem>
							<SelectItem value="2">2</SelectItem>
						</SelectContent>
					</Select>
				</FieldGroup>
				<FieldGroup label="Default work done">
					<Select>
						<SelectTrigger>
							<SelectValue placeholder="Choose..." />
						</SelectTrigger>
						<SelectContent position="popper">
							<SelectItem value="1">1</SelectItem>
							<SelectItem value="2">2</SelectItem>
						</SelectContent>
					</Select>
				</FieldGroup>
			</div>
		</div>
	)
}

function AccountingInfoGroup() {
	const { register } = useFormContext<NewService>()
	return (
		<div className="flex flex-col gap-3">
			<AddEditSectionHeading>Accounting</AddEditSectionHeading>
			<GridRow columnSize="md">
				<FieldGroup label="location code">
					<Select>
						<SelectTrigger>
							<SelectValue placeholder="Choose..." />
						</SelectTrigger>
						<SelectContent position="popper">
							<SelectItem value="1">1</SelectItem>
							<SelectItem value="2">2</SelectItem>
						</SelectContent>
					</Select>
				</FieldGroup>
				<FieldGroup label="department code">
					<Select>
						<SelectTrigger>
							<SelectValue placeholder="Choose..." />
						</SelectTrigger>
						<SelectContent position="popper">
							<SelectItem value="1">1</SelectItem>
							<SelectItem value="2">2</SelectItem>
						</SelectContent>
					</Select>
				</FieldGroup>
			</GridRow>
		</div>
	)
}
