import { useDetailsState } from "@/lib/hooks/use-details-state"
import { Button } from "@/components/ui/button"
import {
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

import { useServiceCard } from "../context"

export const ServiceCardTitles = ({ className }: { className?: string }) => {
	const { service } = useServiceCard()
	const details = useDetailsState()

	if (!service) return null

	return (
		<div className={className}>
			<DetailsCardTitle>
				<Button
					variant="link"
					size="none"
					onClick={() =>
						details.open({
							type: "service",
							id: service.serviceId.toString(),
							parentId: "1", //TODO: Change this once K7 fixes the nested details
						})
					}
				>
					{service?.service.name}
				</Button>
			</DetailsCardTitle>

			<DetailsCardSubtitle>
				{service?.service.version}
			</DetailsCardSubtitle>
		</div>
	)
}
