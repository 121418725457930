// export const noteSchema = createSchemaFromFields(noteFields)
// CreateSchema returns any, hardcode it for now

import { pgTable } from "drizzle-orm/pg-core"
import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import type { z } from "zod"

import { noteFields } from "@/lib/utils/data/schema"
import { locationTable, scopeTable } from "@/server/tables"

const tempTable = pgTable(
	"temp_note_table",
	noteFields(scopeTable, locationTable),
)

export const noteSchema = createSelectSchema(tempTable).omit({
	scopeId: true,
})
export type Note = z.infer<typeof noteSchema>

export const newNoteSchema = createInsertSchema(tempTable).omit({
	scopeId: true,
})
export type NewNote = z.infer<typeof newNoteSchema>
