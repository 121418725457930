import { User } from "lucide-react"

import type { Customer } from "@/server/schemas"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"
import { Tags } from "@/components/shared/details/tags"

import { CustomerHeaderOptionsMenu } from "../header-options-menu"

export const CustomerPageHeader: DetailsHeaderContent<Customer> = ({
	data: customer,
}) => {
	return (
		<>
			<DetailsContextBar>
				<DetailsContext feature="customer" />
				<DetailsTopRightActions>
					<CustomerHeaderOptionsMenu customer={customer} />
				</DetailsTopRightActions>
			</DetailsContextBar>
			<div className="flex gap-3">
				<div className="flex size-[90px] shrink-0 items-center justify-center rounded-md bg-background-weakest text-foreground-weakest">
					<User size={60} />
				</div>
				<div>
					<DetailsTitle>{customer.name}</DetailsTitle>
					<DetailsSubtitle className="flex flex-wrap gap-1.5 capitalize">
						<span>
							{(
								customer.addresses[0]?.city &&
								customer.addresses[0]?.state
							) ?
								`${customer.addresses[0]?.city}, ${customer.addresses[0]?.state}`
							:	"N/A"}
						</span>
						|
						<span className="italic text-foreground-weaker">
							{customer.id}
						</span>
					</DetailsSubtitle>
					<Tags tags={customer.tags} className="mt-2" />
				</div>
			</div>
		</>
	)
}
