import { integer, pgTable, serial, text } from "drizzle-orm/pg-core"

import { activeFields, timestampFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"

export const equipmentFleetTable = pgTable("equipment_fleet", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	fleetId: text("fleet_id").notNull(),
	name: text("name").notNull(),
	...activeFields,
	...timestampFields,
})
