import {
	boolean,
	date,
	decimal,
	integer,
	pgTable,
	serial,
	text,
	timestamp,
} from "drizzle-orm/pg-core"

import { equipmentGVWClassOptions } from "@/lib/utils/data/equipment"
import { fuelTypeCodeOptions } from "@/lib/utils/data/fuelType"
import { activeFields, timestampFields } from "@/lib/utils/data/schema"

import { customerTable } from "../customer"
import { locationTable } from "../location"
import { purchaseOrderTable } from "../purchase_order"
import { scopeTable } from "../user"
import { equipmentFleetTable } from "./equipmentFleet"
import { equipmentGroupTable } from "./equipmentGroup"
import { equipmentTypeTable } from "./equipmentType"

export const equipmentTable = pgTable("equipment", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	equipmentTypeId: integer("equipment_type_id").references(
		() => equipmentTypeTable.id,
		{ onDelete: "set null" },
	),
	purchaseOrderId: integer("purchase_order_id").references(
		() => purchaseOrderTable.id,
		{ onDelete: "set null" },
	),

	// Info Tab
	year: integer("year").notNull(),
	make: text("make").notNull(),
	model: text("model").notNull(),
	vin: text("vin").notNull(),
	name: text("name"),
	color: text("color"),
	avatarUrl: text("avatar_url"),

	// Owner info
	customerId: integer("customer_id").references(() => customerTable.id, {
		onDelete: "set null",
	}),
	registeredOwnerCustomerId: integer(
		"registered_owner_customer_id",
	).references(() => customerTable.id, { onDelete: "set null" }),

	// Fleet info
	fleetId: integer("fleet_id").references(() => equipmentFleetTable.id, {
		onDelete: "set null",
	}),
	groupId: integer("group_id").references(() => equipmentGroupTable.id, {
		onDelete: "set null",
	}),
	unitId: text("unit_id"),

	// Assignment
	baseLocationId: integer("base_location_id").references(
		() => locationTable.id,
		{ onDelete: "set null" },
	),
	defaultRepairLocationId: integer("default_repair_location_id").references(
		() => locationTable.id,
		{ onDelete: "set null" },
	),
	assignment: text("assignment"),
	routeNumber: text("route_number"),

	// Registration
	registrationLicensePlate: text("registration_license_plate"),
	registrationState: text("registration_state"),
	registrationExpirationDate: date("registration_expiration_date", {
		mode: "string",
	}),
	hasInterstateReciprocityPlate: boolean("has_interstate_reciprocity_plate"),
	isOffRoad: boolean("is_off_road"),

	equipmentEmailAddress: text("equipment_email_address"),
	gvwClass: text("gvw_class", { enum: equipmentGVWClassOptions }),
	hasWarranty: boolean("has_warranty"),

	// Specifications
	motorApiId: integer("motor_api_id"),
	fuelType: text("fuel_type", { enum: fuelTypeCodeOptions }),
	oilType: text("oil_type"),
	fuelCardNumber: text("fuel_card_number"),
	fuelGroup: text("fuel_group"),

	mpg: decimal("mpg").$type<number>(),
	mpgLastUpdated: timestamp("mpg_last_updated", { mode: "string" }),
	limitBetweenFueling: text("limit_between_fueling"),
	vocation: text("vocation"),
	engineTestGroupNumber: integer("engine_test_group_number"),

	axles: integer("axles"),
	wheels: integer("wheels"),
	brakeType: text("brake_type"),
	suspensionType: text("suspension_type"),

	status: text("status", { enum: ["ALL", "CONTROL", "EVENT"] }),

	// Financial
	bookValue: decimal("book_value", { scale: 2 }).$type<number>(),
	residualValue: decimal("residual_value", { scale: 2 }).$type<number>(),
	inServiceDate: date("in_service_date", { mode: "string" }),
	usefulMonthsAfter: integer("useful_months_after"),
	depreciationCode: text("depreciation_code"),
	applyDistributedLabor: boolean("apply_distributed_labor"),
	applyDistributedMisc: boolean("apply_distributed_misc"),

	// Lease
	leaseNumber: text("lease_number"),
	monthlyLeaseFee: decimal("monthly_lease_fee", { scale: 2 }).$type<number>(),
	leaseExpirationDate: date("lease_expiration_date"),

	// Unsure

	driverName: text("driver_name"),
	driverPhone: text("driver_phone"),

	warrantyDate: date("warranty_date", { mode: "string" }),
	warrantyUnits: text("warranty_units"),
	hasOnlyOriginalParts: boolean("has_only_original_parts"),

	grossWeight: integer("gross_weight"),
	equipmentUnitBarcode: text("equipment_unit_barcode"),

	serviceGroup: text("service_group"),
	lastService: timestamp("last_service", { mode: "string" }),

	...activeFields,
	...timestampFields,
})
