import { Settings2Icon } from "lucide-react"

import type { Part } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const PartSettingsSection: DetailsSectionComponent<Part> = ({
	data: part,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Settings</DetailsSectionHeading>
		<div className="grid grid-cols-2 gap-3">
			<DetailsInfoGroup label="Critical Part">
				{part.criticalPart ? "Yes" : "No"}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Add to Components?">
				{part.addToComponents ? "Yes" : "No"}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Min">{part.min}</DetailsInfoGroup>
			<DetailsInfoGroup label="Max">{part.max}</DetailsInfoGroup>
			<DetailsInfoGroup label="Flat Rate Price">
				{part.flatRatePrice}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Taxable on purchases">
				{part.taxable ? "Yes" : "No"}
			</DetailsInfoGroup>
		</div>
	</DetailsSection>
)
PartSettingsSection.label = "Settings"
PartSettingsSection.icon = Settings2Icon
