import { WrenchIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { NewWorkOrder } from "@/server/schemas"
import { AddEditSummary } from "@/components/ui/add-edit"
import { AddTagButton } from "@/components/shared/add-edit/add-tag"
import { Tags } from "@/components/shared/details/tags"

export const WorkOrderAddEditSummary = () => {
	const { watch } = useFormContext<NewWorkOrder>()

	const formValues = watch()

	return (
		<AddEditSummary>
			<div className="relative grid aspect-square w-full place-items-center rounded-xl bg-background-weaker">
				<WrenchIcon className="size-40 text-foreground-weak" />
			</div>

			<Tags tags={formValues.tags} centered />

			<AddTagButton feature="WORK_ORDER" />
		</AddEditSummary>
	)
}
