import { integer, pgTable, serial, text, unique } from "drizzle-orm/pg-core"

import {
	activeFields,
	meterTypeCodeOptions,
	timestampFields,
	workOrderStatusOptions,
} from "@/lib/utils/data"

import { equipmentTable } from "../equipment"
import { locationTable } from "../location"
import { scopeTable } from "../user"

export const workOrderTable = pgTable(
	"work_order",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		equipmentId: integer("equipment_id")
			.references(() => equipmentTable.id, { onDelete: "cascade" })
			.notNull(),
		locationId: integer("location_id")
			.references(() => locationTable.id, { onDelete: "cascade" })
			.notNull(),
		code: text("code").notNull(),
		status: text("status", {
			enum: workOrderStatusOptions,
		}).notNull(),
		bayNumber: integer("bay_number"),
		meterType: text("meter_type", { enum: meterTypeCodeOptions }).notNull(),
		meterReading: integer("meter_reading").notNull(),

		...activeFields,
		...timestampFields,
	},
	(table) => [unique().on(table.code, table.scopeId)],
)
