export const meterTypeCodeOptions = [
	"Odometer-M",
	"Odometer-K",
	"Hubometer-M",
	"Hubometer-K",
	"Hours",
] as const
export type MeterTypeCode = (typeof meterTypeCodeOptions)[number]

export const meterTypeOptions = [
	{
		code: "Odometer-M",
		units: "mi",
		defaultMeterDigits: 7,
		meterMaxDeviation: 5000,
		defaultPMAlertDue: 5000,
	},
	{
		code: "Odometer-K",
		units: "km",
		defaultMeterDigits: 7,
		meterMaxDeviation: 5000,
		defaultPMAlertDue: 5000,
	},
	{
		code: "Hubometer-M",
		units: "mi",
		defaultMeterDigits: 7,
		meterMaxDeviation: 0,
		defaultPMAlertDue: 5000,
	},
	{
		code: "Hubometer-K",
		units: "km",
		defaultMeterDigits: 7,
		meterMaxDeviation: 0,
		defaultPMAlertDue: 5000,
	},
	{
		code: "Hours",
		units: "hr",
		defaultMeterDigits: 7,
		meterMaxDeviation: 500,
		defaultPMAlertDue: 50,
	},
] as const
export type MeterType = (typeof meterTypeOptions)[number]

/**
 * Returns the complete details of the Meter Type Code
 *
 * @param code Meter Type Code to find
 * @returns Meter Type Code, or undefined if that code does not exist
 */
export function getMeterType(code: MeterTypeCode): MeterType | undefined {
	return meterTypeOptions.find((elem) => elem.code === code)
}
