import { integer, pgTable, serial, text } from "drizzle-orm/pg-core"

import { serviceTable } from "../service/service"
import { scopeTable } from "../user"
import { workOrderTable } from "./workOrder"

export const workOrderAttachmentTable = pgTable("work_order_attachment", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	workOrderId: integer("work_order_id")
		.references(() => workOrderTable.id, { onDelete: "cascade" })
		.notNull(),
	serviceId: integer("service_id").references(() => serviceTable.id, {
		onDelete: "set null",
	}),
	attachmentUrl: text("attachment_url").notNull(),
	attachedByClerkUserId: text("attached_by_clerk_user_id"),
})
