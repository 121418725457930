import type { FakePendingWork } from "@/routes/_main+/work+/pending-work/mock-data"
import { BoltIcon, PlusIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const PendingWorkPartsSection: DetailsSectionComponent<
	FakePendingWork
> = ({ data: pendingWork }) => (
	<DetailsSectionsGrouping>
		<Parts />
	</DetailsSectionsGrouping>
)
PendingWorkPartsSection.label = "Parts"
PendingWorkPartsSection.icon = BoltIcon

function Parts() {
	return (
		<DetailsSection>
			<DetailsSectionHeading>Parts</DetailsSectionHeading>
			<Button variant="placeholder">
				<PlusIcon className="size-5" />
				Add Part
			</Button>
		</DetailsSection>
	)
}
