import { InfoIcon } from "lucide-react"

import type { Part } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

import { PartAlternatesSection } from "./alternates"

export const PartInfoSection: DetailsSectionComponent<Part> = ({
	data: part,
}) => (
	<DetailsSection>
		<DetailsSectionHeading>Info</DetailsSectionHeading>
		<Notes
			notes={part.notes}
			feature="PART"
			rowId={part.id}
			className="mb-1"
		/>
		<div className="mb-2 text-foreground-strong">{part.description}</div>
		<div className="grid grid-cols-2 gap-3">
			<DetailsInfoGroup label="Last Cost">
				${part.unitCost?.toFixed(2)}
			</DetailsInfoGroup>
			{/* Determine fee from most recent PO */}
			{/* <DetailsInfoGroup label="Core Fee">
				{part.coreFee.toFixed(2)}
			</DetailsInfoGroup> */}
			<DetailsInfoGroup label="Bar Code">{part.barcode}</DetailsInfoGroup>
			<DetailsInfoGroup label="MFG Number">
				{part.manufacturerPartNumber}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Unit of Measure">
				{part.unitOfMeasure}
			</DetailsInfoGroup>
		</div>

		<PartAlternatesSection alternateParts={part.alternateParts} />
	</DetailsSection>
)
PartInfoSection.label = "Info"
PartInfoSection.icon = InfoIcon
