import {
	boolean,
	index,
	integer,
	pgTable,
	serial,
	text,
} from "drizzle-orm/pg-core"

import { activeFields, timestampFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { customerBillingTable } from "./customerBilling"

export const customerTable = pgTable(
	"customer",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		billingId: integer("billing_id").references(
			() => customerBillingTable.id,
			{
				onDelete: "set null",
			},
		),
		name: text("name").notNull(),
		avatarUrl: text("avatar_url"),

		pmEnableNotifications: boolean("pm_enable_notifications")
			.notNull()
			.default(true),
		pmNotificationMethod: text("pm_notification_method", {
			enum: ["email", "text", "push"],
		}).notNull(),
		notifyWorkOrderComplete: text("notify_work_order_complete", {
			enum: ["yes", "no", "ask"],
		}).notNull(),
		notifyWorkOrderNotes: text("notify_work_order_notes", {
			enum: ["yes", "no", "ask"],
		}).notNull(),
		tax1: text("tax1").notNull(),
		tax2: text("tax2"),
		accountsReceivable: boolean("accounts_receivable").notNull(),
		accountCode: text("account_code").notNull(),
		notes: text("notes"),
		...activeFields,
		...timestampFields,
	},
	(table) => [
		index("customer_search_index").on(table.name, table.accountCode),
	],
)
