import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"
import { EllipsisIcon } from "lucide-react"

import { useDetailsState } from "@/lib/hooks/use-details-state"
import type { Part } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

export function PartHeaderOptionsMenu({ part }: { part: Part }) {
	const details = useDetailsState()
	const { revalidate } = useRevalidator()
	const utils = trpcClient.useUtils()
	const { mutateAsync: deletePart } = trpcClient.parts.deletePart.useMutation(
		{
			onSuccess: () => {
				details.close()
				utils.parts.getPartById.invalidate({
					id: part.id,
				})

				revalidate()
			},
		},
	)

	const handleDelete = async () => {
		await deletePart({ id: part.id })
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-5" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start">
				<DropdownMenuGroup>
					<DropdownMenuLabel>Settings</DropdownMenuLabel>
				</DropdownMenuGroup>
				<DropdownMenuItem disabled>Edit</DropdownMenuItem>
				<DropdownMenuItem onSelect={() => handleDelete()}>
					Delete
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
