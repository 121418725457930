import {
	decimal,
	integer,
	pgTable,
	serial,
	text,
	unique,
} from "drizzle-orm/pg-core"

import { activeFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"

export const partTypeTable = pgTable(
	"part_type",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		code: text("code").notNull(),
		name: text("name").notNull(),
		markupPercent: decimal("markup_percent", { scale: 2 }).$type<number>(),
		...activeFields,
	},
	(table) => [unique().on(table.scopeId, table.code)],
)
