import { cn } from "@/lib/utils/classnames"
import { getTagColorHex } from "@/lib/utils/data"
import type { Tag } from "@/server/schemas"
import { Chip, ChipContainer } from "@/components/ui/chip"

export function Tags({
	tags,
	className,
	centered,
}: {
	tags: Tag[]
	className?: string
	centered?: boolean
}) {
	if (!tags || tags.length === 0) return null
	return (
		<ChipContainer className={cn(centered && "justify-center", className)}>
			{tags.map((tag) => (
				<Chip
					variant="primary"
					key={tag.rowId.toString() + tag.tag}
					className={`uppercase tracking-wider ${tag.colorName === "YELLOW" && "text-foreground"}`}
					style={{ backgroundColor: getTagColorHex(tag.colorName) }}
				>
					{tag.tag}
				</Chip>
			))}
		</ChipContainer>
	)
}
