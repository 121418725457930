import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { tagFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { partTable } from "./part"

export const partTagTable = pgTable(
	"part_tag",
	tagFields(scopeTable, partTable),
	(table) => [primaryKey({ columns: [table.rowId, table.tag] })],
)
