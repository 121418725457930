import React from "react"
import type { Dispatch, SetStateAction } from "react"
import { format } from "date-fns"
import { CalendarIcon } from "lucide-react"
import type {
	DateRange,
	DropdownNavProps,
	DropdownProps,
} from "react-day-picker"

import { cn } from "@/lib/utils/classnames"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "./select"

export function DatePickerWithRange({
	dateState,
	earliestDate,
	latestDate,
	dates,
	className,
}: {
	dateState: {
		date: DateRange
		setDate: Dispatch<SetStateAction<DateRange>>
	}
	earliestDate: Date
	latestDate: Date
	dates: Date[]
	className?: React.HTMLAttributes<HTMLDivElement>
}) {
	const date = dateState.date
	const modifiers = {
		dates: dates,
	}

	const modifiersClassNames = {
		dates: "*:after:pointer-events-none *:after:absolute *:after:bottom-1 *:after:start-1/2 *:after:z-10 *:after:size-[3px] *:after:-translate-x-1/2 *:after:rounded-full *:after:bg-primary [&[data-selected]:not(.range-middle)>*]:after:bg-background [&[data-disabled]>*]:after:bg-foreground/30 *:after:transition-colors",
	}

	const handleCalendarChange = (
		_value: string | number,
		_e: React.ChangeEventHandler<HTMLSelectElement>,
	) => {
		const _event = {
			target: {
				value: String(_value),
			},
		} as React.ChangeEvent<HTMLSelectElement>
		_e(_event)
	}

	return (
		<div className={cn("grid gap-2", className)}>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant={"outline"}
						className={cn(
							"w-full justify-start gap-2 text-left font-normal",
							!date && "text-muted-foreground",
						)}
					>
						<CalendarIcon />
						{(
							// (date?.from?.getTime() === earliestDate.getTime() &&
							// 	date?.to?.getTime() === latestDate.getTime()) ||
							!date.from && !date.to
						) ?
							<span className="text-sm">Pick a date range</span>
						:	<span className="text-sm">
								{date.from && format(date.from, "LLL dd, y")} -{" "}
								{date.to && format(date.to, "LLL dd, y")}
							</span>
						}
					</Button>
				</PopoverTrigger>
				<PopoverContent
					className="w-auto rounded-lg border border-border p-2"
					align="start"
				>
					<Calendar
						mode="range"
						captionLayout="dropdown"
						defaultMonth={date?.from}
						startMonth={earliestDate}
						endMonth={latestDate}
						selected={date}
						onSelect={dateState.setDate}
						numberOfMonths={2}
						disabled={[
							{ before: earliestDate },
							{ after: latestDate },
						]}
						modifiers={modifiers}
						modifiersClassNames={modifiersClassNames}
						required
						components={{
							DropdownNav: (props: DropdownNavProps) => {
								return (
									<div className="flex w-full items-center gap-2">
										{props.children}
									</div>
								)
							},
							Dropdown: (props: DropdownProps) => {
								return (
									<Select
										value={String(props.value)}
										onValueChange={(value) => {
											if (props.onChange) {
												handleCalendarChange(
													value,
													props.onChange,
												)
											}
										}}
									>
										<SelectTrigger className="h-8 w-fit font-medium first:grow">
											<SelectValue />
										</SelectTrigger>
										<SelectContent className="max-h-[min(26rem,var(--radix-select-content-available-height))]">
											{props.options?.map((option) => (
												<SelectItem
													key={option.value}
													value={String(option.value)}
													disabled={option.disabled}
												>
													{option.label}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								)
							},
						}}
					/>
					<Button
						variant="ghost"
						size="none"
						className="ml-4 text-sm text-info"
						onClick={() => {
							dateState.setDate({
								from: undefined,
								to: undefined,
							})
						}}
						disabled={!date.from && !date.to}
					>
						Reset
					</Button>
				</PopoverContent>
			</Popover>
		</div>
	)
}
