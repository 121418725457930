import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"

import type { NewWorkOrder } from "@/server/schemas"
import { newWorkOrderSchema } from "@/server/schemas"
import type { AddEditStateProps } from "@/components/shared/add-edit"
import { AddEdit } from "@/components/shared/add-edit"

import { WorkOrderAddEditInfo } from "./sections/info"
import { WorkOrderAddEditServices } from "./sections/services"
import { WorkOrderAddEditSummary } from "./sections/summary"

type WorkOrderAddEditProps = AddEditStateProps<typeof newWorkOrderSchema>
export function WorkOrderAddEdit({ ...props }: WorkOrderAddEditProps) {
	const { revalidate } = useRevalidator()
	const utils = trpcClient.useUtils()
	const { mutateAsync } = trpcClient.workOrders.addEditWorkOrder.useMutation({
		onSuccess: () => {
			utils.workOrders.getWorkOrderById.invalidate()
			revalidate()
		},
		onError: () => {
			console.log("Error adding work order")
		},
	})
	return (
		<AddEdit
			{...props}
			summary={<WorkOrderAddEditSummary />}
			sectionDefinitions={{
				info: WorkOrderAddEditInfo,
				services: WorkOrderAddEditServices,
				//parts: WorkOrderAddEditParts,
				//settings: WorkOrderAddEditSettings,
			}}
			feature="workOrder"
			submitHandlers={mutateAsync}
			schema={newWorkOrderSchema}
			defaultValues={defaultValues}
		/>
	)
}

const defaultValues: Partial<NewWorkOrder> = {
	locationId: 1,
	notes: [],
	status: "TO DO",
	tags: [],
	metas: [],
	services: [],
	billing: [],
	attachments: [],
}
