import { useEffect, useState } from "react"
import { format } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import { cn } from "@/lib/utils/classnames"
import type { NewService } from "@/server/schemas"
import {
	AddEditSection,
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { Checkbox } from "@/components/ui/checkbox"
import { DetailsSection } from "@/components/ui/details-view"
import { FieldGroup } from "@/components/ui/feild-group"
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Label,
} from "@/components/ui/form"
import { Input, InputSlot } from "@/components/ui/input"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"

export const ServiceAddEditPM = {
	label: "PM",
	icon: CalendarIcon,
	component: (
		<AddEditSectionsGrouping className="pb-8">
			<PMGroup />
		</AddEditSectionsGrouping>
	),
}

function PMGroup() {
	const { control, setValue } = useFormContext<NewService>()
	const [dateEnabled, setDateEnabled] = useState(false)
	const [meterEnabled, setMeterEnabled] = useState(false)

	useEffect(() => {
		if (!dateEnabled) {
			setValue("pmTimeElapsed", undefined)
		}
		if (!meterEnabled) {
			setValue("pmMeterElapsed", undefined)
		}
	}, [dateEnabled, meterEnabled, setValue])

	return (
		<AddEditSection>
			<div>
				<AddEditSectionHeading>
					Preventative Maintenance <span>(Optional)</span>
				</AddEditSectionHeading>
				<p className="text-sm text-foreground-weak">
					Check one or more box below to add this service to list of
					pm services available for a piece of equipment. If date and
					meter are both chosen, the PM will be scheduled based on
					whichever occurs first.
				</p>
			</div>

			{/* Time-based PM Section */}
			<div className="flex items-start gap-5 rounded-lg border border-border-weak p-5 pb-7">
				<div className="flex w-[150px] shrink-0 items-center gap-2">
					<Checkbox
						checked={dateEnabled}
						onCheckedChange={(checked) => {
							setDateEnabled(!!checked)
							if (checked) {
								setValue("pmTimeElapsed", {
									serviceId: 0,
									scheduleBeginDate: "",
									dueFrequencyValue: 0,
									unitOfMeasure: "day",
									notifyBeforeDue: 0,
									combineOtherPMWithin: 0,
								})
							}
						}}
					/>
					<Label
						htmlFor="pmTimeEnabled"
						className="translate-y-[8px] cursor-pointer"
					>
						PM due on a <br /> <b>specific date</b>
					</Label>
				</div>

				<div className="flex w-full grow flex-col gap-2">
					<FormField
						control={control}
						name="pmTimeElapsed.scheduleBeginDate"
						render={({ field }) => (
							<FormItem>
								<FormLabel>PM Schedule Begin Date</FormLabel>
								<Popover>
									<PopoverTrigger asChild>
										<FormControl>
											<Button
												variant={"outline"}
												className={cn(
													"w-[240px] pl-3 text-left font-normal",
													!field.value &&
														"text-foreground-weak",
												)}
											>
												{field.value ?
													format(field.value, "PPP")
												:	<span>Pick a date</span>}
												<CalendarIcon className="ml-auto size-4 opacity-50" />
											</Button>
										</FormControl>
									</PopoverTrigger>
									<PopoverContent
										className="w-auto p-0"
										align="start"
									>
										<Calendar
											mode="single"
											selected={
												new Date(field.value ?? "")
											}
											captionLayout="dropdown"
											onSelect={(date) => {
												setValue(
													"pmTimeElapsed.scheduleBeginDate",
													date?.toISOString(),
												)
											}}
											disabled={(date) =>
												date < new Date()
											}
										/>
									</PopoverContent>
								</Popover>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FieldGroup label="Due Every">
						<div className="flex gap-3">
							<FormField
								control={control}
								name="pmTimeElapsed.dueFrequencyValue"
								render={({ field }) => (
									<FormItem className="grow">
										<FormControl>
											<Input
												type="number"
												{...field}
												placeholder="0"
												value={field.value ?? ""}
												onChange={(value) =>
													field.onChange(
														value.target
															.valueAsNumber,
													)
												}
												disabled={!dateEnabled}
											/>
										</FormControl>
									</FormItem>
								)}
							/>

							<FormField
								control={control}
								name="pmTimeElapsed.unitOfMeasure"
								render={({ field }) => (
									<FormItem>
										<Select
											disabled={!dateEnabled}
											onValueChange={field.onChange}
											value={field.value}
										>
											<FormControl>
												<SelectTrigger>
													<SelectValue />
												</SelectTrigger>
											</FormControl>
											<SelectContent position="popper">
												<SelectItem value="hour">
													Hours
												</SelectItem>
												<SelectItem value="day">
													Days
												</SelectItem>
												<SelectItem value="month">
													Months
												</SelectItem>
												<SelectItem value="week">
													Years
												</SelectItem>
											</SelectContent>
										</Select>
									</FormItem>
								)}
							/>
						</div>
					</FieldGroup>

					<FormField
						control={control}
						name="pmTimeElapsed.notifyBeforeDue"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Notify before due</FormLabel>
								<FormControl>
									<Input
										type="number"
										{...field}
										placeholder="0"
										value={field.value ?? ""}
										onChange={(value) =>
											field.onChange(
												value.target.valueAsNumber,
											)
										}
										disabled={!dateEnabled}
									>
										<InputSlot side="end">Days</InputSlot>
									</Input>
								</FormControl>
							</FormItem>
						)}
					/>

					<FormField
						control={control}
						name="pmTimeElapsed.combineOtherPMWithin"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Combine other PM within</FormLabel>
								<FormControl>
									<Input
										type="number"
										{...field}
										placeholder="0"
										value={field.value ?? ""}
										onChange={(value) =>
											field.onChange(
												value.target.valueAsNumber,
											)
										}
										disabled={!dateEnabled}
									>
										<InputSlot side="end">Days</InputSlot>
									</Input>
								</FormControl>
							</FormItem>
						)}
					/>
				</div>
			</div>

			{/* Meter-based PM Section */}
			<div className="flex items-start gap-5 rounded-lg border border-border-weak p-5 pb-7">
				<div className="flex w-[150px] shrink-0 items-center gap-2">
					<Checkbox
						checked={meterEnabled}
						onCheckedChange={(checked) => {
							setMeterEnabled(!!checked)
							if (checked) {
								setValue("pmMeterElapsed", {
									serviceId: 0,
									scheduleBeginMeter: 0,
									dueFrequencyValue: 0,
									unitOfMeasure: "mi",
									notifyBeforeDue: 0,
									combineOtherPMWithin: 0,
								})
							}
						}}
					/>
					<Label
						htmlFor="pmMeterEnabled"
						className="translate-y-[8px] cursor-pointer"
					>
						PM due on <br />
						<b>meter elapsed</b>
					</Label>
				</div>

				<div className="flex w-full grow flex-col gap-2">
					<FormField
						control={control}
						name="pmMeterElapsed.scheduleBeginMeter"
						render={({ field }) => (
							<FormItem>
								<FormLabel>PM Schedule Begin Meter</FormLabel>
								<Input
									type="number"
									{...field}
									placeholder="0"
									value={field.value ?? ""}
									onChange={(value) =>
										field.onChange(
											value.target.valueAsNumber,
										)
									}
									disabled={!meterEnabled}
								/>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FieldGroup label="Due Every">
						<div className="flex gap-3">
							<FormField
								control={control}
								name="pmMeterElapsed.dueFrequencyValue"
								render={({ field }) => (
									<FormItem className="grow">
										<FormControl>
											<Input
												type="number"
												{...field}
												placeholder="0"
												value={field.value ?? ""}
												onChange={(value) =>
													field.onChange(
														value.target
															.valueAsNumber,
													)
												}
												disabled={!meterEnabled}
											/>
										</FormControl>
									</FormItem>
								)}
							/>

							<FormField
								control={control}
								name="pmMeterElapsed.unitOfMeasure"
								render={({ field }) => (
									<FormItem>
										<Select
											disabled={!meterEnabled}
											onValueChange={field.onChange}
											defaultValue="mi"
											value={field.value}
										>
											<FormControl>
												<SelectTrigger>
													<SelectValue />
												</SelectTrigger>
											</FormControl>
											<SelectContent position="popper">
												<SelectItem value="mi">
													Miles
												</SelectItem>
												<SelectItem value="km">
													Kilometers
												</SelectItem>
											</SelectContent>
										</Select>
									</FormItem>
								)}
							/>
						</div>
					</FieldGroup>

					<FormField
						control={control}
						name="pmMeterElapsed.notifyBeforeDue"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Notify before due</FormLabel>
								<FormControl>
									<Input
										type="number"
										{...field}
										placeholder="0"
										value={field.value ?? ""}
										onChange={(value) =>
											field.onChange(
												value.target.valueAsNumber,
											)
										}
										disabled={!meterEnabled}
									>
										<InputSlot side="end">Miles</InputSlot>
									</Input>
								</FormControl>
							</FormItem>
						)}
					/>

					<FormField
						control={control}
						name="pmMeterElapsed.combineOtherPMWithin"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Combine other PM within</FormLabel>
								<FormControl>
									<Input
										type="number"
										{...field}
										placeholder="0"
										value={field.value ?? ""}
										onChange={(value) =>
											field.onChange(
												value.target.valueAsNumber,
											)
										}
										disabled={!meterEnabled}
									>
										<InputSlot side="end">Miles</InputSlot>
									</Input>
								</FormControl>
							</FormItem>
						)}
					/>
				</div>
			</div>
		</AddEditSection>
	)
}
