import { useState } from "react"
import { trpcClient } from "@/trpc/client"
import type { NoteFeature } from "@/trpc/routers/notes"
import { format } from "date-fns"
import { CheckIcon, PencilIcon, PlusIcon, TrashIcon, XIcon } from "lucide-react"
import { AnimatePresence, motion } from "motion/react"
import { toast } from "sonner"

import { useInvalidator } from "@/lib/hooks/use-invalidator"
import { cn } from "@/lib/utils/classnames"
import type { Note } from "@/server/schemas"
import { Textarea } from "@/components/ui/textarea"
import { OverlineSmall } from "@/components/ui/typography"

import { Button } from "../../ui/button"

interface UseNoteEditorProps {
	feature: NoteFeature
	rowId: number
	notes: Note[]
}

const useNoteEditor = ({ feature, rowId, notes }: UseNoteEditorProps) => {
	const { invalidateById } = useInvalidator()

	const { mutateAsync: createNote } = trpcClient.notes.createNote.useMutation(
		{
			onSuccess: () => {
				invalidateById(feature, rowId)
			},
			onError: () => {
				toast.error(feature + " schema does not yet support notes")
			},
		},
	)

	const { mutateAsync: deleteNote } = trpcClient.notes.deleteNote.useMutation(
		{
			onSuccess: () => {
				if (notes.length === 1) setDeleteMode(false) //Exit delete mode if its the last note deleted
				invalidateById(feature, rowId)
			},
		},
	)

	const [editingState, setEditingState] = useState<{
		editing: boolean
		note: string
	}>({
		editing: false,
		note: "",
	})

	// Whether or not you can click the trash can to delete notes
	const [deleteMode, setDeleteMode] = useState(false)

	const startEditing = () => setEditingState({ editing: true, note: "" })
	const cancelEditing = () => setEditingState({ editing: false, note: "" })
	const updateNote = (note: string) =>
		setEditingState((prev) => ({ ...prev, note }))

	const confirmNote = async () => {
		try {
			await createNote({
				data: {
					note: editingState.note.trim(),
					createdAt: new Date().toISOString(),
					updatedAt: new Date().toISOString(),
					rowId,
				},
				feature: feature,
			})
			cancelEditing()
		} catch (error) {
			console.error("Failed to create note:", error)
		}
	}

	const toggleDeleteMode = () => setDeleteMode((prev) => !prev)
	const handleDeleteNote = (noteId: number) => {
		deleteNote({ id: noteId, feature: feature })
	}

	return {
		...editingState,
		startEditing,
		cancelEditing,
		updateNote,
		confirmNote,
		deleteMode,
		toggleDeleteMode,
		handleDeleteNote,
	}
}

interface NotesProps extends React.HTMLAttributes<HTMLDivElement> {
	notes: Note[]
	feature: NoteFeature
	rowId: number
}

export function Notes({ notes, feature, rowId, className }: NotesProps) {
	const MAX_NOTE_LENGTH = 250
	const [seeAll, setSeeAll] = useState(false)
	const {
		editing,
		note,
		startEditing,
		cancelEditing,
		updateNote,
		confirmNote,
		deleteMode,
		toggleDeleteMode,
		handleDeleteNote,
	} = useNoteEditor({ feature, rowId, notes })

	return (
		<NotesContainer className={className}>
			<div className="flex justify-between text-lg font-bold">
				Notes ({notes.length})
				<div className="flex shrink">
					<Button
						variant={deleteMode ? "link" : "ghost"}
						size="icon"
						onClick={toggleDeleteMode}
						className={editing ? "hidden" : ""}
						disabled={!notes[0]}
					>
						{deleteMode ?
							<span>done</span>
						:	<PencilIcon className="size-5" />}
					</Button>
					<Button
						variant="ghost"
						size="icon"
						onClick={startEditing}
						className={`flex items-center gap-1 ${editing || deleteMode ? "hidden" : ""}`}
					>
						<PlusIcon className="size-5" />
					</Button>
					<Button
						variant="ghost"
						size="icon"
						onClick={cancelEditing}
						className={`flex items-center gap-1 ${editing ? "" : "hidden"}`}
					>
						<XIcon className="size-5" />
					</Button>
					<Button
						variant="ghost"
						size="icon"
						onClick={confirmNote}
						className={`flex items-center gap-1 ${editing ? "" : "hidden"}`}
						disabled={!note.trim().length}
					>
						<CheckIcon className="size-5" />
					</Button>
				</div>
			</div>
			<div className="relative">
				<OverlineSmall
					className={`absolute right-[5px] top-[3px] ${editing ? "" : "hidden"}`}
				>
					{MAX_NOTE_LENGTH - note.length}
				</OverlineSmall>
				<Textarea
					value={note}
					onChange={(e) =>
						updateNote(
							e.target.value.substring(
								0,
								Math.min(
									e.target.value.length,
									MAX_NOTE_LENGTH,
								),
							),
						)
					}
					className={cn(editing ? "" : "hidden", "pt-3")}
					spellCheck={false}
				/>
			</div>

			{notes.length > 0 ?
				<NotesDisplay
					notes={notes}
					deleteMode={deleteMode}
					onDeleteNote={handleDeleteNote}
					expanded={seeAll}
				/>
			:	<span className="text-foreground-weak">
					Select the plus button above to add a note...
				</span>
			}

			{notes.length > 1 && (
				<Button
					variant="link"
					size="none"
					onClick={() => setSeeAll(!seeAll)}
				>
					{`See ${seeAll ? "less" : `all notes (${notes.length})`} `}
				</Button>
			)}
		</NotesContainer>
	)
}

interface NoteProps {
	note: Note
	deleteMode: boolean
	onDeleteNote: (noteId: number) => void
}

const Note = ({ note, deleteMode, onDeleteNote }: NoteProps) => {
	const formattedDate =
		note.createdAt && format(new Date(note.createdAt), "MM/dd/yy")

	return (
		<div className="flex items-center">
			{/* Container for positioning trash icon */}
			<div className="relative">
				<AnimatePresence>
					{deleteMode && (
						<motion.div
							initial={{ opacity: 0, x: -15 }}
							animate={{ opacity: 1, x: 0 }}
							exit={{ opacity: 0, x: -15 }}
							transition={{ duration: 0.2 }}
							className="absolute inset-0 left-4 flex items-center justify-center"
						>
							<Button
								variant="ghost"
								size="icon"
								onClick={() => onDeleteNote(note.id)}
								className="animate-[wiggle_0.4s_ease-in-out_infinite]"
							>
								<TrashIcon className="size-5" />
							</Button>
						</motion.div>
					)}
				</AnimatePresence>
			</div>

			{/* Animated content container */}
			<motion.div
				className="flex flex-1 flex-col gap-[2px]"
				animate={{
					marginLeft: deleteMode ? "44px" : "0px",
				}}
				transition={{ duration: 0.2 }}
			>
				<div className="flex justify-between">
					<OverlineSmall className="truncate">whodunit</OverlineSmall>
					<OverlineSmall className="truncate">
						{formattedDate ?? "--"}
					</OverlineSmall>
				</div>
				<p>{note.note}</p>
			</motion.div>
		</div>
	)
}

interface NotesDisplayProps {
	notes: Note[]
	deleteMode: boolean
	onDeleteNote: (noteId: number) => void
	expanded: boolean
}

function NotesDisplay({
	notes,
	deleteMode,
	onDeleteNote,
	expanded,
}: NotesDisplayProps) {
	if (!notes[0]) return null

	return (
		<div className="flex flex-col gap-4 py-2">
			{/* First note is always visible */}
			<Note
				note={notes[0]}
				deleteMode={deleteMode}
				onDeleteNote={onDeleteNote}
			/>

			{/* Animate additional notes */}
			<AnimatePresence>
				{expanded && notes.length > 1 && (
					<motion.div
						className="flex flex-col gap-2 overflow-hidden"
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: "auto", opacity: 1 }}
						exit={{ height: 0, opacity: 0 }}
						transition={{ duration: 0.25, ease: "easeInOut" }}
					>
						{notes.slice(1).map((note, index) => (
							<Note
								key={index + 1}
								note={note}
								deleteMode={deleteMode}
								onDeleteNote={onDeleteNote}
							/>
						))}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	)
}

function NotesContainer({
	children,
	className,
}: {
	children?: React.ReactNode
	className?: string
}) {
	return (
		<div
			className={cn(
				"flex h-fit max-h-[500px] flex-col gap-2 overflow-y-auto rounded-md border border-[#e7dfb5] bg-[#FFF599] p-3 text-asphalt-900 [scrollbar-width:thin]",
				className,
			)}
		>
			{children}
		</div>
	)
}
