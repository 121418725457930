import { InfoIcon } from "lucide-react"

import type { Service } from "@/server/schemas"
import {
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

import { ServiceAttachmentsSection } from "./attachments"
import { ServiceLaborSection } from "./labor"
import { ServicePartsSection } from "./parts"

export const ServiceInfoSection: DetailsSectionComponent<Service> = ({
	data: service,
}) => (
	<>
		<DetailsSectionsGrouping>
			<Notes notes={service.notes} feature="SERVICE" rowId={service.id} />
			<DetailsSection>
				<DetailsSectionHeading>Description</DetailsSectionHeading>
				<p className="p-2">{service?.description}</p>
			</DetailsSection>
			<ServiceLaborSection data={service} />
			<ServicePartsSection data={service} />
			<ServiceAttachmentsSection data={service} />
		</DetailsSectionsGrouping>
	</>
)
ServiceInfoSection.label = "Info"
ServiceInfoSection.icon = InfoIcon
