import { Bolt, EllipsisIcon } from "lucide-react"

import type { Part } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import { Link } from "@/components/ui/link"
import { Separator } from "@/components/ui/separator"
import { Overline } from "@/components/ui/typography"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

import { PartHeaderOptionsMenu } from "../header-options-menu"

export const PartPageHeader: DetailsHeaderContent<Part> = ({ data: part }) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="part" />
			<DetailsTopRightActions>
				{/* <Button variant="ghost" size="icon">
					<EllipsisIcon className="size-4" />
				</Button> */}
				<PartHeaderOptionsMenu part={part} />
			</DetailsTopRightActions>
		</DetailsContextBar>
		<div className="flex gap-3">
			<div className="flex grow gap-3">
				{part.avatarUrl ?
					<div
						className="flex size-[90px] shrink-0 items-center rounded-md bg-background-weakest bg-cover bg-no-repeat"
						style={{
							backgroundImage: `url(${part.avatarUrl})`,
							backgroundPosition: "center",
						}}
					/>
				:	<div className="flex size-[90px] shrink-0 items-center justify-center rounded-md bg-background-weakest text-foreground-weakest">
						<Bolt size={60} />
					</div>
				}
				<div>
					<DetailsTitle>{part.name}</DetailsTitle>
					<DetailsSubtitle>
						<div className="mb-0.5 flex items-center gap-2">
							<div className="font-bold uppercase">
								{part.manufacturerPartNumber}
							</div>
						</div>
						<div>
							{/* {part.MFG} */}
							{part.manufacturerCode}
						</div>
					</DetailsSubtitle>
					{/* TODO: Replace with real tags */}
					<div className="mt-2 flex flex-wrap gap-1">
						{/* TODO: Replace with real tags */}
						{/* {!part.inStock && (
							<Chip variant="warning">Out of Stock</Chip>
						)} */}
						<Chip variant="primary">PRIMARY</Chip>
						<Chip variant="info">HARD TO FIND</Chip>
						<Chip variant="info">NON-BILLABLE</Chip>
					</div>
				</div>
			</div>
			<div className="flex items-center gap-3">
				<div className="flex flex-col items-end gap-1">
					<Button
						variant="link"
						className="m-0 text-wrap p-0 leading-tight"
						size="none"
					>
						<Link to="/">
							<b>3</b> alt parts
						</Link>
					</Button>
					<Separator orientation="vertical" className="h-auto" />
					<Button
						variant="link"
						className="m-0 text-wrap p-0 leading-tight"
						size="none"
					>
						<Link to="/">
							<b>11</b> at other loc
						</Link>
					</Button>
					<Separator orientation="vertical" className="h-auto" />
					<Button
						variant="link"
						className="m-0 text-wrap p-0 leading-tight"
						size="none"
					>
						<Link to="/">
							<b>48</b> on order
						</Link>
					</Button>
				</div>
				<div className="flex shrink-0 flex-col rounded-md border border-border">
					<div className="w-full truncate border-b border-border-weak px-3 pb-1 pt-1.5 text-center font-bold leading-tight">
						Downtown Warehouse 2
					</div>
					<div className="flex items-center px-3 py-2">
						<div className="flex grow">
							<div className="flex flex-col items-center">
								<Chip variant="success">IN STOCK</Chip>
								<div className="text-3xl font-bold leading-tight">
									16
								</div>
							</div>
						</div>
						<div className="flex grow flex-col text-sm leading-tight">
							<div className="flex items-center justify-between">
								<Overline>Aisle</Overline>
								<div>AA</div>
							</div>
							<div className="flex items-center justify-between">
								<Overline>Shelf</Overline>
								<div>2</div>
							</div>
							<div className="flex items-center justify-between">
								<Overline>Bin</Overline>
								<div>6</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
)
