import type { PurchaseOrder } from "@/routes/_main+/work+/purchase-orders/mock-data"
import { Settings } from "lucide-react"

import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { Overline } from "@/components/ui/typography"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const PurchaseOrderSettingsSection: DetailsSectionComponent<
	PurchaseOrder
> = ({ data: purchaseOrder }) => (
	<DetailsSection>
		<DetailsSectionHeading>Settings</DetailsSectionHeading>
		<div className="grid grid-cols-2 gap-4">
			<div>
				<Overline>Shipping method</Overline>
				<div>Fedex Ground</div>
			</div>
			<div>
				<Overline>Tracking number</Overline>
				<div>{purchaseOrder.trackingNumber || "N/A"}</div>
			</div>
			<div>
				<Overline>Order from</Overline>
				<div>{purchaseOrder.shipper}</div>
			</div>
			<div>
				<Overline>Shipping to</Overline>
				<div>
					<div>{purchaseOrder.shipToAddress.street}</div>
					<div>
						{purchaseOrder.shipToAddress.city},{" "}
						{purchaseOrder.shipToAddress.state}{" "}
					</div>
				</div>
			</div>
			<div>
				<Overline>Payment Method</Overline>
				<div>Invoice</div>
			</div>
			<div>
				<Overline>Customer ID</Overline>
				<div>JTRUCK</div>
			</div>
			<div>
				<Overline>Tax 1</Overline>
				<div>WASTATE</div>
			</div>
			<div>
				<Overline>Tax 2</Overline>
				<div>SEACITY</div>
			</div>
		</div>
	</DetailsSection>
)

PurchaseOrderSettingsSection.label = "Settings"
PurchaseOrderSettingsSection.icon = Settings
