import { Check, Ellipsis, Wrench } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { Button } from "@/components/ui/button"
import { DetailsCardOverline } from "@/components/ui/details-card"

import { useServiceCard } from "../context"

export const ServiceCardHeader = ({ className }: { className?: string }) => {
	const { service, isComplete, setIsComplete } = useServiceCard()

	const toggleIsServiceComplete = () => {
		setIsComplete(!isComplete)
	}

	return (
		<div className={cn("flex items-center justify-between", className)}>
			<DetailsCardOverline icon={Wrench} className="mb-0">
				Service
			</DetailsCardOverline>
			<div className="flex items-center gap-2">
				{service?.service.assignedTo && (
					<Button
						size="sm"
						className={cn(
							"flex size-[22px] items-center justify-center rounded-full p-0",
							isComplete &&
								"border-transparent !bg-success text-success-foreground hover:bg-success",
						)}
						onClick={toggleIsServiceComplete}
					>
						<Check size={16} />
						{/* {isComplete ?
							<CheckCircle size={20} />
						:	<Check size={20} className="text-primary-weak" />} */}
					</Button>
				)}
				<Button variant="ghost" size="sm" className="px-1.5">
					<Ellipsis size={16} />
				</Button>
			</div>
		</div>
	)
}
