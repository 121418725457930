import { trpcClient } from "@/trpc/client"
import { useRevalidator } from "@remix-run/react"

import { locationFormSchema, newLocationSchema } from "@/server/schemas"
import type { NewLocation } from "@/server/schemas"
import { AddEdit } from "@/components/shared/add-edit"
import type { AddEditStateProps } from "@/components/shared/add-edit"

import { LocationAddEditContacts } from "./sections/contacts"
import { LocationAddEditInfo } from "./sections/info"
import { LocationAddEditSummary } from "./sections/summary"

type LocationAddEditProps = AddEditStateProps<typeof newLocationSchema>

export function LocationAddEdit({ ...props }: LocationAddEditProps) {
	const { revalidate } = useRevalidator()
	const utils = trpcClient.useUtils()
	const { mutateAsync } = trpcClient.locations.addEditLocation.useMutation({
		onSuccess: () => {
			utils.locations.getLocationById.invalidate()
			revalidate()
		},
	})

	return (
		<AddEdit
			{...props}
			summary={<LocationAddEditSummary />}
			sectionDefinitions={{
				info: LocationAddEditInfo,
				contacts: LocationAddEditContacts,
			}}
			feature="location"
			submitHandlers={mutateAsync}
			schema={newLocationSchema}
			defaultValues={defaultLocation}
		/>
	)
}

const defaultLocation: NewLocation = {
	name: "",
	street1: "",
	city: "",
	state: "",
	contacts: [],
	metas: [],
	tags: [],
	type: "WAREHOUSE",
}
