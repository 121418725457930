import {
	date,
	integer,
	pgTable,
	serial,
	text,
	unique,
} from "drizzle-orm/pg-core"

import {
	activeFields,
	purchaseOrderPaymentMethodOptions,
	purchaseOrderStatusOptions,
	timestampFields,
} from "@/lib/utils/data"

import { customerTable } from "../customer"
import { locationTable } from "../location"
import { scopeTable } from "../user"
import { vendorInvoiceTable, vendorTable } from "../vendor"

export const purchaseOrderTable = pgTable(
	"purchase_order",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		vendorId: integer("vendor_id")
			.references(() => vendorTable.id, { onDelete: "cascade" })
			.notNull(),
		customerId: integer("customer_id")
			.references(() => customerTable.id, { onDelete: "cascade" })
			.notNull(),
		orderFromLocationId: integer("order_from_location_id")
			.references(() => locationTable.id, { onDelete: "cascade" })
			.notNull(),
		shipToLocationId: integer("ship_to_location_id")
			.references(() => locationTable.id, { onDelete: "cascade" })
			.notNull(),
		code: text("code").notNull(),
		date: date("date", { mode: "string" }).notNull().defaultNow(),
		status: text("status", { enum: purchaseOrderStatusOptions })
			.notNull()
			.default("DRAFT"),
		shipperCode: text("shipper_code").notNull(),
		shippingMethod: text("shipping_method"),

		vendorInvoiceId: integer().references(() => vendorInvoiceTable.id, {
			onDelete: "set null",
		}),
		paymentMethod: text("payment_method", {
			enum: purchaseOrderPaymentMethodOptions,
		}).notNull(),
		notes: text("notes"),

		...activeFields,
		...timestampFields,
	},
	(table) => [unique().on(table.code, table.scopeId)],
)
