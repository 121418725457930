import { pgTable } from "drizzle-orm/pg-core"

import { noteFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { workOrderTable } from "./workOrder"

export const workOrderNoteTable = pgTable(
	"work_order_note",
	noteFields(scopeTable, workOrderTable),
)
