import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { equipmentTypeTable } from "@/server/tables"

const equipmentTypeOmitFields = {
	scopeId: true,
} as const
export const equipmentTypeSelectColumns = {
	...getTableSelectColumns(equipmentTypeTable, equipmentTypeOmitFields),
}

export const equipmentTypeSchema = createSelectSchema(equipmentTypeTable).omit(
	equipmentTypeOmitFields,
)
export type EquipmentType = z.infer<typeof equipmentTypeSchema>

export const newEquipmentTypeSchema = createInsertSchema(
	equipmentTypeTable,
).omit(equipmentTypeOmitFields)
export type NewEquipmentType = z.infer<typeof newEquipmentTypeSchema>
