import { CalendarIcon } from "lucide-react"

import type { Equipment } from "@/server/schemas"
import { DetailsSectionsGrouping } from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

import { EquipmentPMHistorySection } from "./history"
import { EquipmentNextDueSection } from "./next-due"
import { EquipmentSubscribedPMSection } from "./subscribed-pm"

export const EquipmentMaintenanceSection: DetailsSectionComponent<
	Equipment
> = ({ data: equipment }) => (
	<DetailsSectionsGrouping>
		<EquipmentNextDueSection />
		<EquipmentSubscribedPMSection equipment={equipment} />
		<EquipmentPMHistorySection />
	</DetailsSectionsGrouping>
)

EquipmentMaintenanceSection.label = "Maintenance"
EquipmentMaintenanceSection.icon = CalendarIcon
