import { pgTable, primaryKey } from "drizzle-orm/pg-core"

import { metaFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { serviceTable } from "./service"

export const serviceMetaTable = pgTable(
	"service_meta",
	metaFields(scopeTable, serviceTable),
	(table) => [primaryKey({ columns: [table.field, table.rowId] })],
)
