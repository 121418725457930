import {
	boolean,
	integer,
	pgTable,
	serial,
	text,
	unique,
} from "drizzle-orm/pg-core"

import { scopeTable } from "../user"

export const equipmentTypeTable = pgTable(
	"equipment_type",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		code: text("code").notNull(),
		description: text("description").notNull(),
		isMeterReadingRequired: boolean("is_meter_reading_required")
			.notNull()
			.default(false),
		isTireGridRequired: boolean("is_tire_grid_required")
			.notNull()
			.default(false),
		hasPowerUnit: boolean("has_power_unit").notNull().default(false),
		hasRequiredWeightClass: boolean("has_required_weight_class")
			.notNull()
			.default(false),
	},
	(table) => [unique().on(table.scopeId, table.code)],
)
