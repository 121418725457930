import { integer, pgTable, serial, text } from "drizzle-orm/pg-core"

import { workOrderServiceStatusCodeOptions } from "@/lib/utils/data"

import { serviceTable } from "../service"
import { scopeTable } from "../user"
import { workOrderTable } from "./workOrder"

export const workOrderServiceTable = pgTable("work_order_service", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	workOrderId: integer("work_order_id")
		.references(() => workOrderTable.id, { onDelete: "cascade" })
		.notNull(),
	serviceId: integer("service_id")
		.references(() => serviceTable.id, { onDelete: "cascade" })
		.notNull(),
	status: text("status", {
		enum: workOrderServiceStatusCodeOptions,
	}).notNull(),
})
