export const workOrderBillingCategoryOptions = [
	"LABOR",
	"PARTS",
	"MISC",
	"EXTERNAL",
] as const
export type WorkOrderBillingCategory =
	(typeof workOrderBillingCategoryOptions)[number]

export const workOrderStatusOptions = [
	"PENDING",
	"TO DO",
	"IN PROGRESS",
	"COMPLETE",
] as const
export type WorkOrderStatus = (typeof workOrderStatusOptions)[number]

export const workOrderServiceStatusCodeOptions = [
	"NOT STARTED",
	"IN PROGRESS",
	"PAUSED",
	"COMPLETE",
	"EXTERNAL",
] as const
export type WorkOrderServiceStatusCode =
	(typeof workOrderServiceStatusCodeOptions)[number]

export const workOrderServiceStatusOptions = [
	{
		code: "NOT STARTED",
		description: "Open/no service active",
		isDownTime: false,
	},
	{
		code: "IN PROGRESS",
		description: "Service in progress",
		isDownTime: true,
	},
	{
		code: "PAUSED",
		description: "Service is paused",
		isDownTime: true,
	},
	{
		code: "COMPLETE",
		description: "Work Order is complete",
		isDownTime: false,
	},
	{
		code: "EXTERNAL",
		description: "Service is being done at an external shop",
		isDownTime: false,
	},
	// {
	// 	code: "INACTIVE",
	// 	description: "Service inactive",
	// 	isDownTime: true,
	// },
	// {
	// 	code: "CLOSED",
	// 	description: "Work Order is closed",
	// 	isDownTime: false,
	// },
	// {
	// 	code: "CUSTOMER PICKUP",
	// 	description: "Waiting for customer pickup",
	// 	isDownTime: false,
	// },
	// {
	// 	code: "CUSTOMER APPROVAL",
	// 	description: "Waiting for customer approval",
	// 	isDownTime: false,
	// },
	// {
	// 	code: "WAITING BAY",
	// 	description: "Waiting for a bay",
	// 	isDownTime: true,
	// },
	// {
	// 	code: "WAITING PART",
	// 	description: "Waiting for a part",
	// 	isDownTime: true,
	// },
	// {
	// 	code: "WAITING MECH",
	// 	description: "Waiting for a mechanic",
	// 	isDownTime: true,
	// },
	// {
	// 	code: "REVIEW",
	// 	description: "Waiting for review",
	// 	isDownTime: true,
	// },
] as const
export type WorkOrderServiceStatus =
	(typeof workOrderServiceStatusOptions)[number]

/**
 * Returns the complete details of the Work Order Code
 *
 * @param code Work Order Status Code to find
 * @returns Work Order Status Code, or undefined if that code does not exist
 */
export function getWorkOrderServiceStatus(
	code: WorkOrderServiceStatusCode,
): WorkOrderServiceStatus | undefined {
	return workOrderServiceStatusOptions.find((elem) => elem.code === code)
}
