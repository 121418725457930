import {
	boolean,
	date,
	decimal,
	integer,
	pgTable,
	serial,
	text,
	unique,
} from "drizzle-orm/pg-core"

import { activeFields, timestampFields } from "@/lib/utils/data/schema"
import {
	unitOfMeasureDateUnitOptions,
	unitOfMeasureUnitOptions,
} from "@/lib/utils/data/unitOfMeasure"

import { scopeTable } from "../user"
import { vendorTable } from "../vendor"
import {
	vmrsComponentTable,
	vmrsFailureCodeTable,
	vmrsPositionCodeTable,
} from "../vmrs"
import { partTypeTable } from "./partType"

export const partTable = pgTable(
	"part",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		partTypeId: integer("part_type_id").references(() => partTypeTable.id, {
			onDelete: "set null",
		}),
		defaultVendorId: integer("default_vendor_id").references(
			() => vendorTable.id,
			{ onDelete: "set null" },
		),

		// General Info
		code: text("code").notNull(),
		name: text("name").notNull(),
		avatarUrl: text("avatar_url"),
		shortName: text("short_name"),
		description: text("description").notNull(), // Should store as rich text (Markdown)
		barcode: text("barcode"),

		// Manufacturer/Warranty
		manufacturerCode: text("manufacturer_code"),
		manufacturerPartNumber: integer("manufacturer_part_number"),
		hasWarranty: boolean("has_warranty"),
		warrantyMeter: integer("warranty_meter"),
		warrantyMeterUnit: text("warranty_meter_unit", {
			enum: unitOfMeasureUnitOptions,
		}),
		warrantyTime: integer("warranty_time"),
		warrantyTimeUnit: text("warrant_time_unit", {
			enum: unitOfMeasureDateUnitOptions,
		}),

		// VMRS Codes
		vmrsComponentCode: text("vmrs_component_code").references(
			() => vmrsComponentTable.code,
			{ onDelete: "set null" },
		),
		vmrsFailureCode: text("vmrs_failure_code").references(
			() => vmrsFailureCodeTable.code,
			{ onDelete: "set null" },
		),
		vmrsSideCode: text("vmrs_side_code").references(
			() => vmrsPositionCodeTable.code,
			{ onDelete: "set null" },
		),
		vmrsOrientationCode: text("vmrs_orientation_code").references(
			() => vmrsPositionCodeTable.code,
			{ onDelete: "set null" },
		),
		vmrsSequenceCode: text("vmrs_sequence_code").references(
			() => vmrsPositionCodeTable.code,
			{ onDelete: "set null" },
		),

		// Inventory Info
		minQuantity: integer("min_quantity"), // Reorder point
		maxQuantity: integer("max_quantity"), // Stocking level
		unitOfMeasure: text("unit_of_measure", {
			enum: unitOfMeasureUnitOptions,
		}),

		// Markup/Cost Info
		unitCost: decimal("unit_cost", { scale: 2 }).$type<number>(),
		roundUnitCostTo: integer("round_unit_cost_to"),
		useMarkupMatrix: boolean("use_markup_matrix"),
		taxableOnPurchases: boolean("taxable_on_purchases"),
		markupPercent: integer("markup_percent"),
		flatRatePrice: integer("flat_rate_price"),

		// onOrder: integer("on_order"), Don't store this. Link to purchase order.
		// lastCost: integer("last_cost"), Don't store this. Cost from most recent PO
		// coreFee: integer("core_fee"), Don't store this. Fee from most recent PO
		// count: integer("count"), Don't store this. Sum Inventory location quantities - work order quantities
		// stockLevel: text("stock_level"), Don't store this, calculate it on query

		addToComponentList: boolean("add_to_component_list"),
		lastWorkOrderDate: date("last_work_order_date", { mode: "string" }),
		isPrimaryPart: boolean("is_primary_part").notNull().default(false),
		isCriticalPart: boolean("is_critical_part").notNull().default(false),
		...activeFields,
		...timestampFields,
	},
	(table) => [unique().on(table.scopeId, table.code)],
)
