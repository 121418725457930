import { trpcClient } from "@/trpc/client"
import { BadgeCheckIcon, InfoIcon } from "lucide-react"

import { useDetailsState } from "@/lib/hooks/use-details-state"
import type { Equipment } from "@/server/schemas"
import { Accordion } from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionsGrouping,
} from "@/components/ui/details-view"
import { GridRow } from "@/components/ui/gridRow"
import { Link } from "@/components/ui/link"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

import { CurrentDetails } from "./current"
import { EquipmentOperatorInspectionsSection } from "./operator-inspections"
import { EquipmentOperatorsSection } from "./operators"
import { EquipmentSpecsSection } from "./specifications"
import { EquipmentTireTreadSection } from "./tire-tread"

export const EquipmentInfoSection: DetailsSectionComponent<Equipment> = ({
	data: equipment,
}) => {
	return (
		<>
			<DetailsSectionsGrouping>
				<Notes
					notes={equipment.notes}
					feature="EQUIPMENT"
					rowId={equipment.id}
				/>
				<CurrentDetails />

				<Info equipment={equipment} />
				<div className="relative mt-3 flex w-full rounded-md border-2 border-[#e8c14b] p-2">
					<BadgeCheckIcon className="mr-2 size-6 fill-[#d0a21a] text-white" />
					<div className="flex flex-col gap-1">
						<div className="text-lg leading-tight text-foreground-strong">
							50,000 mile / 5 year warranty
						</div>
						<div className="text-sm text-foreground-weak">
							Started 11/1/15, Original parts only
						</div>
					</div>
				</div>

				<EquipmentTireTreadSection />
				<Accordion type="multiple" className="flex flex-col gap-3">
					<EquipmentOperatorsSection />
					<EquipmentSpecsSection equipment={equipment} />
					<EquipmentOperatorInspectionsSection />
				</Accordion>
			</DetailsSectionsGrouping>
		</>
	)
}
EquipmentInfoSection.label = "Info"
EquipmentInfoSection.icon = InfoIcon

function Info({ equipment }: { equipment: Equipment }) {
	const details = useDetailsState()

	// EXTREME HACK for robert
	const driverResult = trpcClient.employees.getEmployeeById.useQuery({
		id: equipment.id,
	})
	return (
		<DetailsSection>
			<DetailsSectionHeading>Info</DetailsSectionHeading>
			<GridRow columnSize="md" className="sm:grid-cols-2">
				<DetailsInfoGroup label="Reg Owner Id">
					{equipment.registeredOwnerCustomerId}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Weight Class">
					{equipment.gvwClass}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Reg State">
					{equipment.registrationState}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Reg Expiration">
					{equipment.registrationExpirationDate}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Off-road?">
					{equipment.isOffRoad ? "Yes" : "No"}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Int. Reciprocity Plate?">
					{equipment.hasInterstateReciprocityPlate ? "Yes" : "No"}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Default Repair Site">
					{equipment.defaultRepairLocationId}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Assignment">
					{equipment.assignment}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Route #">
					{equipment.routeNumber}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Equipment email">
					{equipment.equipmentEmailAddress}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Driver Name">
					<Button
						variant="link"
						size="none"
						onClick={() =>
							details.open({
								type: "employee",
								id: equipment.id.toString(),
								parentId: equipment.id.toString(),
							})
						}
					>
						{driverResult.data?.success ?
							driverResult.data.value.firstName +
							" " +
							driverResult.data.value.lastName
						:	"--"}
					</Button>
					{/* TODO: Driver data? */}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Current oper. phone">
					{equipment.driverPhone}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Last inspection">
					<Link to="/">Passed</Link>
					{equipment.lastService}
				</DetailsInfoGroup>
			</GridRow>
		</DetailsSection>
	)
}
