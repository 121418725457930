import type { AlternatePart } from "@/server/schemas"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import { DetailsSectionHeading } from "@/components/ui/details-view"
import { Link } from "@/components/ui/link"

import { PartDetailsCard } from "../../card"

export function PartAlternatesSection({
	alternateParts,
}: {
	alternateParts: AlternatePart[]
}) {
	return (
		<Accordion type="multiple" className="mt-3 flex flex-col gap-4">
			<AccordionItem value="alternates">
				<AccordionTrigger className="p-0">
					<DetailsSectionHeading>
						Alternate Parts ({alternateParts.length})
					</DetailsSectionHeading>
				</AccordionTrigger>
				<AccordionContent className="p-0">
					<div className="flex flex-col gap-2">
						{alternateParts.map((part) => {
							return (
								<PartDetailsCard
									key={part.id}
									part={part.alternatePart}
								/>
							)
						})}
					</div>
				</AccordionContent>
			</AccordionItem>
			<AccordionItem value="specs">
				<AccordionTrigger className="p-0">
					<DetailsSectionHeading>
						Specifications
					</DetailsSectionHeading>
				</AccordionTrigger>
				<AccordionContent className="p-0">
					Custom meta/specs goes here
				</AccordionContent>
			</AccordionItem>
			<AccordionItem value="attachments">
				<AccordionTrigger className="p-0">
					<DetailsSectionHeading>
						Attachments (1)
					</DetailsSectionHeading>
				</AccordionTrigger>
				<AccordionContent className="p-0">
					<Button variant="link" size="none" className="p-0">
						<Link to="/">amber_bulb_part_guide.pdf</Link>
					</Button>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	)
}
