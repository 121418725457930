import { forwardRef } from "react"
import type { LucideIcon } from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { typographyVariants } from "@/components/ui/typography"

import type { StatusLineType } from "./queue"

const DetailsCard = forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & {
		isActive?: boolean
		statusLine?: StatusLineType
	}
>(
	(
		{
			className,
			children,
			isActive = false,
			statusLine,
			onClick,
			onKeyDown,
			...props
		},
		ref,
	) => {
		const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === "Enter" || event.key === " ") {
				if (!onClick) return
				onClick(
					event as unknown as React.MouseEvent<
						HTMLDivElement,
						MouseEvent
					>,
				)
			}
			if (onKeyDown) {
				onKeyDown(event)
			}
		}

		return (
			<div
				ref={ref}
				role="button"
				tabIndex={0}
				onClick={onClick}
				onKeyDown={handleKeyDown}
				className={cn(
					"relative flex w-full scroll-m-4 flex-col rounded-lg border p-2.5 text-left !transition-colors",
					onClick ?
						"focus-visible:outline-2 focus-visible:-outline-offset-1 focus-visible:outline-focus"
					:	"cursor-default",
					onClick && !isActive && "hover:bg-hover/[3%]",
					statusLine && "pl-5",
					isActive && "bg-selected text-selected-foreground",
					className,
				)}
				{...props}
			>
				{statusLine && (
					<div
						className={cn(
							"absolute inset-y-[6px] left-[6px] w-1 rounded-full",
							statusLine === "success" && "bg-success",
							statusLine === "warning" && "bg-warning",
							statusLine === "error" && "bg-error",
							statusLine === "info" && "bg-info",
							statusLine === "primary" && "bg-primary",
						)}
					/>
				)}
				{children}
			</div>
		)
	},
)

DetailsCard.displayName = "DetailsCard"

interface DetailsCardOverlineProps {
	icon?: LucideIcon
	children?: React.ReactNode
	className?: string
}
export const DetailsCardOverline = ({
	icon: Icon,
	children,
	className,
}: DetailsCardOverlineProps) => (
	<div
		className={cn(
			"mb-1.5 flex items-center gap-1.5",
			typographyVariants({ variant: "overline" }),
			className,
		)}
	>
		{Icon && <Icon size={16} className="shrink-0 text-foreground-weak" />}
		{children}
	</div>
)

export const DetailsCardTitle = ({
	children,
}: {
	children?: React.ReactNode
}) => <div className="font-bold text-foreground-strong">{children}</div>

export const DetailsCardSubtitle = ({
	children,
}: {
	children?: React.ReactNode
}) => <div className="text-sm">{children}</div>

interface DetailsCardInfoLinesWrapperProps
	extends React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode
}
export const DetailsCardInfoLinesWrapper = ({
	children,
	className,
}: DetailsCardInfoLinesWrapperProps) => (
	<div className={cn("mt-2 flex flex-col gap-1", className)}>{children}</div>
)

interface DetailsCardInfoLineProps
	extends React.HTMLAttributes<HTMLDivElement> {
	icon?: LucideIcon
	iconClass?: string
	children?: React.ReactNode
}
export const DetailsCardInfoLine = ({
	icon: Icon,
	children,
	className,
	iconClass,
}: DetailsCardInfoLineProps) => (
	<div
		className={cn(
			"flex items-center gap-1.5 text-sm leading-tight text-foreground-weak",
			className,
		)}
	>
		{Icon && (
			<Icon
				size={14}
				className={cn("shrink-0 text-foreground-weak", iconClass)}
			/>
		)}
		{children}
	</div>
)

export { DetailsCard }
