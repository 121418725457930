// Uncomment above once the schema is available and remove the below import
//import type { FakeEquipment as Equipment } from "@/routes/_main+/resources+/equipment/fake-equipment"
import type { Table as TanstackTable } from "@tanstack/react-table"

import { cn } from "@/lib/utils/classnames"
import type { Equipment } from "@/server/schemas"
import { Chip } from "@/components/ui/chip"
import { QueueItemRow, QueueItems } from "@/components/ui/queue"
import { QueueView } from "@/components/shared/queue"

export function EquipmentQueue({
	listDataState,
}: {
	listDataState: TanstackTable<Equipment>
}) {
	return (
		<QueueView>
			<QueueItems
				listDataState={listDataState}
				renderItem={(equipment) => (
					<>
						<QueueItemRow>
							<div className="truncate font-bold">
								{equipment.unitId}
								<span className="mx-1 font-normal opacity-70">
									|
								</span>
								{`${equipment.year} ${equipment.make} ${equipment.model}`}
							</div>
							{/* {equipment.status !== "Available" && (
								<Chip
									variant="outline"
									className={cn(
										"shrink-0 px-1.5",
										equipment.status == "Out of Service" &&
											"border-error-border/30 text-error",
									)}
								>
									{equipment.status}
								</Chip>
							)} */}
						</QueueItemRow>
						<QueueItemRow variant="secondary">
							<div className="truncate">{equipment.vin}</div>
						</QueueItemRow>
						<QueueItemRow variant="tertiary">
							<div className="truncate">
								{equipment.registeredOwnerCustomerId}
							</div>
							{equipment.leaseExpirationDate && (
								<div className="shrink-0">
									last: {equipment.leaseExpirationDate}
								</div>
							)}
						</QueueItemRow>
					</>
				)}
				onRowClick={(equipment) => {
					listDataState.setRowSelection({
						[equipment.id]: true,
					})
				}}
				isActive={(equipment) =>
					listDataState
						.getSelectedRowModel()
						.rows.some((row) => row.id === equipment.id.toString())
				}
			/>
		</QueueView>
	)
}
