import { integer, pgTable, serial, text } from "drizzle-orm/pg-core"

import {
	timestampFields,
	unitOfMeasureDistanceUnitOptions,
} from "@/lib/utils/data"

import { serviceTable } from "../service"
import { scopeTable } from "../user"

export const pmMeterElapsedTable = pgTable("pm_meter_elapsed", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	serviceId: integer("service_id")
		.references(() => serviceTable.id, { onDelete: "cascade" })
		.notNull(),
	scheduleBeginMeter: integer("schedule_begin_meter").notNull().default(0),
	dueFrequencyValue: integer("due_frequency_value").notNull(),
	notifyBeforeDue: integer("notify_before_due").notNull(),
	combineOtherPMWithin: integer("combine_other_pm_within").notNull(),
	unitOfMeasure: text("unit_of_measure", {
		enum: unitOfMeasureDistanceUnitOptions,
	})
		.notNull()
		.default("mi"),
	...timestampFields,
})
