import { Plus } from "lucide-react"

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"

export function ServiceCardAssignedUsers() {
	return (
		<div className="flex grow gap-2">
			<div className="flex flex-wrap gap-1.5">
				<Avatar className="size-[30px]">
					<AvatarImage
						src={
							"https://static.tvtropes.org/pmwiki/pub/images/larry_david_2.jpg"
						}
					/>
					<AvatarFallback>Larry David</AvatarFallback>
				</Avatar>
			</div>
			<Button
				variant="placeholder"
				className="size-[30px] rounded-full p-0"
			>
				<Plus size={16} />
			</Button>
		</div>
	)
}
