import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildArray,
} from "@/lib/utils/data/validators"
import { locationTable } from "@/server/tables"

import { metaSchema, newMetaSchema, newTagSchema, tagSchema } from "../shared"
import {
	locationContactSchema,
	locationContactSelectColumns,
	newLocationContactSchema,
} from "./locationContact"
import { locationMetaSelectColumns } from "./locationMeta"
import { locationTagSelectColumns } from "./locationTag"

const locationOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
	isDeleted: true,
	deletedDate: true,
} as const

export const baseLocationSelectColumns = {
	...getTableSelectColumns(locationTable, locationOmitFields),
}
export const locationSelectColumns = {
	...baseLocationSelectColumns,
	contacts: jsonBuildArray(locationContactSelectColumns),
	metas: jsonBuildArray(locationMetaSelectColumns),
	tags: jsonBuildArray(locationTagSelectColumns),
}

export const baseLocationSchema =
	createSelectSchema(locationTable).omit(locationOmitFields)
export type BaseLocation = z.infer<typeof baseLocationSchema>

export const locationSchema = baseLocationSchema.extend({
	contacts: locationContactSchema.array().default([]),
	metas: metaSchema.array().default([]),
	tags: tagSchema.array().default([]),
})
export type Location = z.infer<typeof locationSchema>

const locationSchemaValidation = z.object({
	name: z.string().min(1, "Please enter a location name."),
})

export const newLocationSchema = createInsertSchema(locationTable)
	.omit(locationOmitFields)
	.extend({
		contacts: newLocationContactSchema.array().default([]),
		metas: newMetaSchema.array().default([]),
		tags: newTagSchema.array().default([]),
	})
	.merge(locationSchemaValidation)
export type NewLocation = z.infer<typeof newLocationSchema>

// Add/Edit form schema
// TODO: Add validation
export const locationFormSchema = newLocationSchema
