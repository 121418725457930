export const equipmentGVWClassOptions = [
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"K",
	"L",
	"M",
	"N",
	"P",
	"Q",
	"R",
	"S",
	"Z",
] as const
export type EquipmentGVWClass = (typeof equipmentGVWClassOptions)[number]

export const equipmentGVWClassDetailOptions = [
	{
		code: "1",
		name: "Truck, 6000 lbs and less",
		shortName: "1 - Trk",
	},
	{
		code: "2",
		name: "Truck, 6001 lbs to 10000 lbs",
		shortName: "2 - Trk",
	},
	{
		code: "3",
		name: "Truck, 10001 lbs to 14000 lbs",
		shortName: "3 - Trk",
	},
	{
		code: "4",
		name: "Truck, 14001 lbs to 16000 lbs",
		shortName: "4 - Trk",
	},
	{
		code: "5",
		name: "Truck, 16001 lbs to 19500 lbs",
		shortName: "5 - Trk",
	},
	{
		code: "6",
		name: "Truck, 19501 lbs to 26000 lbs",
		shortName: "6 - Trk",
	},
	{
		code: "7",
		name: "Truck, 26001 lbs to 33000 lbs",
		shortName: "7 - Trk",
	},
	{
		code: "8",
		name: "Truck, 33001 lbs and over",
		shortName: "8 - Trk",
	},
	{
		code: "9",
		name: "Truck-Trailer Combination, 10000 lbs and less",
		shortName: "9 - Trk/Trl",
	},
	{
		code: "A",
		name: "Truck-Trailer Combination, 10000 to 19500 lbs",
		shortName: "A - Trk/Trl",
	},
	{
		code: "B",
		name: "Truck-Trailer Combination, 19501 to 29500 lbs",
		shortName: "B - Trk/Trl",
	},
	{
		code: "C",
		name: "Truck-Trailer Combination, 29501 to 39500 lbs",
		shortName: "C - Trk/Trl",
	},
	{
		code: "D",
		name: "Truck-Trailer Combination, 39501 to 49500 lbs",
		shortName: "D - Trk/Trl",
	},
	{
		code: "E",
		name: "Truck-Trailer Combination, 49501 to 59500 lbs",
		shortName: "E - Trk/Trl",
	},
	{
		code: "F",
		name: "Truck-Trailer Combination, 59501 to 79500 lbs",
		shortName: "F - Trk/Trl",
	},
	{
		code: "G",
		name: "Truck-Trailer Combination, 79501 to 99500 lbs",
		shortName: "G - Trk/Trl",
	},
	{
		code: "H",
		name: "Truck-Trailer Combination, 99501 lbs and over",
		shortName: "H - Trk/Trl",
	},
	{
		code: "K",
		name: "Off Highway, 10000 lbs and less K - Off Hwy",
		shortName: "K - Off Hwy",
	},
	{
		code: "L",
		name: "Off Highway, 10001 to 19500",
		shortName: "L - Off Hwy",
	},
	{
		code: "M",
		name: "Off Highway, 19501 to 29500",
		shortName: "M - Off Hwy",
	},
	{
		code: "N",
		name: "Off Highway, 29501 to 39500",
		shortName: "N - Off Hwy",
	},
	{
		code: "P",
		name: "Off Highway, 39501 to 59500",
		shortName: "P - Off Hwy",
	},
	{
		code: "Q",
		name: "Off Highway, 59501 to 79500",
		shortName: "Q - Off Hwy",
	},
	{
		code: "R",
		name: "Off Highway, 79501 to 99500",
		shortName: "R - Off Hwy",
	},
	{
		code: "S",
		name: "Off Highway, 99501 lbs and over",
		shortName: "S - Off Hwy",
	},
	{
		code: "Z",
		name: "Not Applicable",
		shortName: "Z - N/A",
	},
] as const
export type EquipmentGVWClassDetail =
	(typeof equipmentGVWClassDetailOptions)[number]

/**
 * Returns the complete details of the GVW Code
 *
 * @param code GVW Code to find
 * @returns GVW Code, or undefined if that code does not exist
 */
export function getGVWClass(
	code: EquipmentGVWClass,
): EquipmentGVWClassDetail | undefined {
	return equipmentGVWClassDetailOptions.find((elem) => elem.code === code)
}
