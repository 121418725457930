import { createContext, useContext, useMemo, useState } from "react"

import type { WorkOrderService } from "@/server/schemas"

interface ServiceCardContext {
	service: WorkOrderService | null
	setService: React.Dispatch<React.SetStateAction<WorkOrderService | null>>
	isComplete: boolean
	setIsComplete: React.Dispatch<React.SetStateAction<boolean>>
}

// Context & Hook
const ServiceCardContext = createContext<ServiceCardContext | null>(null)

export const useServiceCard = () => {
	const context = useContext(ServiceCardContext)
	if (!context) {
		throw new Error(
			"useServiceCard must be used within a ServiceCardProvider",
		)
	}
	return context
}

// Provider Component
export const ServiceCardProvider = ({
	children,
	initialService = null,
}: {
	children: React.ReactNode
	initialService?: WorkOrderService | null
}) => {
	const [service, setService] = useState<WorkOrderService | null>(
		initialService,
	)
	const [isComplete, setIsComplete] = useState<boolean>(
		service?.status == "COMPLETE" || false,
	)

	const contextValue: ServiceCardContext = useMemo(
		() => ({
			service,
			setService,
			isComplete,
			setIsComplete,
		}),
		[service, setService, isComplete, setIsComplete],
	)

	return (
		<ServiceCardContext.Provider value={contextValue}>
			{children}
		</ServiceCardContext.Provider>
	)
}
