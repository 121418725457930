import { Fragment, useState } from "react"
import type { Table as TanstackTable } from "@tanstack/react-table"
import { Check, ChevronsUpDown, FilterX, SaveIcon } from "lucide-react"

import {
	MAX_COLLAPSED_FILTERS,
	useFilter,
	useFilterPanel,
	useSavedFilter,
} from "@/lib/hooks/use-filter"
import { cn } from "@/lib/utils/classnames"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
	Command,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command"
import { DatePickerWithRange } from "@/components/ui/date-range-picker"
import {
	FilterCheckboxItem,
	FilterExpandContainer,
	FilterHeaderGroup,
	FilterHeaderTitle,
	FiltersFooter,
	FiltersHeader,
	FiltersHeaderInfo,
	FiltersPanel,
	FiltersPanelBody,
	FiltersTopRightActions,
	SavedFilterDropdownContainer,
	SavedFiltersContainer,
} from "@/components/ui/filter"
import { Label } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"
import RangeSlider from "@/components/ui/range-slider"
import { Separator } from "@/components/ui/separator"

interface SavedFilters {
	id: number
	filterName: string
	filters: {
		id: string
		value: any
	}[]
}

export function SavedFilterSelection() {
	const [open, setOpen] = useState(false)
	const filters = useFilterPanel()
	const {
		selectedSavedFilter,
		setSelectedSavedFilter,
		modified,
		savedFilters,
		route,
	} = useSavedFilter()
	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className="w-full max-w-[350px] justify-between"
					disabled={savedFilters.length === 0}
				>
					<div className="truncate text-sm">
						{selectedSavedFilter ?
							`${
								savedFilters?.find(
									(filter) =>
										selectedSavedFilter !== "toggled" &&
										filter.filterName ===
											selectedSavedFilter.filterName,
								)?.filterName
							}${modified ? "*" : ""}`
						: savedFilters.length > 0 ?
							"Select Saved Filter..."
						:	"No Saved Filters..."}
					</div>
					<ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent
				align="center"
				className={cn(
					"w-full max-w-[250px]",
					filters.isOpen ? "p-1" : "p-0",
				)}
			>
				<Command>
					{selectedSavedFilter && (
						<div className="mx-1 flex justify-end">
							<Button
								className="mt-2 flex w-full items-center justify-center gap-1 py-2 text-sm text-info"
								variant="ghost"
								size="none"
								onClick={() => {
									setSelectedSavedFilter("toggled")
									setOpen(false)
								}}
							>
								<FilterX className="size-4" />
								Remove saved filter
							</Button>
						</div>
					)}

					<CommandInput placeholder="Search saved filters..." />
					<CommandList>
						<CommandGroup>
							{savedFilters.map((filter) => (
								<CommandItem
									key={filter.id}
									value={filter.filterName}
									onSelect={(currentValue) => {
										setSelectedSavedFilter(
											(
												selectedSavedFilter !==
													"toggled" &&
													currentValue ===
														selectedSavedFilter?.filterName
											) ?
												"toggled"
											:	{
													...filter,
													route:
														filter.route ??
														route ??
														"",
												},
										)
										setOpen(false)
									}}
								>
									<Check
										className={cn(
											"mr-2 h-4 w-4",
											(
												selectedSavedFilter !==
													"toggled" &&
													selectedSavedFilter?.filterName ===
														filter.filterName
											) ?
												"opacity-100"
											:	"opacity-0",
										)}
									/>
									{filter.filterName}
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	)
}

export function SavedFilters({ isDisabled = true }: { isDisabled?: boolean }) {
	const filters = useFilterPanel()
	const { setUpdateSavedFilter, setFilterName, modified } = useSavedFilter()
	const [saveOpen, setSaveOpen] = useState(false)
	const [newFilterName, setNewFilterName] = useState("")

	const saveFilter = () => {
		setFilterName(newFilterName)
		setNewFilterName("")
		setSaveOpen(false)
	}

	return (
		<SavedFiltersContainer className={`${filters.isOpen && "pb-4"}`}>
			<SavedFilterDropdownContainer>
				<SavedFilterSelection />
			</SavedFilterDropdownContainer>
			{filters.isOpen &&
				(modified ?
					<Button
						variant="outline"
						size="icon"
						disabled={isDisabled}
						onClick={() => setUpdateSavedFilter(true)}
					>
						<SaveIcon className="size-4" />
					</Button>
				:	<Popover open={saveOpen} onOpenChange={setSaveOpen}>
						<PopoverTrigger>
							<Button
								variant="outline"
								size="icon"
								disabled={isDisabled}
							>
								<SaveIcon className="size-4" />
							</Button>
						</PopoverTrigger>
						<PopoverContent
							align="end"
							className="w-full max-w-[350px] p-2"
						>
							<div className="flex items-center gap-2">
								<Input
									className="h-8"
									type="text"
									placeholder="Enter a name..."
									value={newFilterName}
									onChange={(e) =>
										setNewFilterName(e.target.value)
									}
									onKeyDown={(e) => {
										if (
											e.key === "Enter" &&
											newFilterName.length > 0
										) {
											saveFilter()
										}
									}}
								/>
								<Button
									variant="primary"
									size="sm"
									disabled={newFilterName.length === 0}
									onClick={saveFilter}
								>
									Save
								</Button>
							</div>
						</PopoverContent>
					</Popover>)}
		</SavedFiltersContainer>
	)
}

export function Filters<TanstackTableSchema>({
	listDataState,
}: {
	listDataState: TanstackTable<TanstackTableSchema>
}) {
	const {
		isExpanded,
		toggleExpand,
		columnFilters,
		resetFilters,
		toggleFilter,
		filterCategories,
		filterOptions,
		dateFilter,
		setFilters,
		disableApply,
		filterCount,
		disableSave,
		numberFilter,
	} = useFilter({ listDataState })

	return (
		<FiltersPanel>
			<FiltersHeader>
				<FiltersHeaderInfo>
					<FilterHeaderGroup>FILTERS</FilterHeaderGroup>
					<FilterHeaderTitle>
						{filterCount} {filterCount === 1 ? "filter" : "filters"}
					</FilterHeaderTitle>
				</FiltersHeaderInfo>
				<FiltersTopRightActions />
			</FiltersHeader>

			<Separator className="h-[2px] bg-border-weak" />
			<FiltersPanelBody>
				<SavedFilters isDisabled={disableSave} />
				<Accordion type="multiple" defaultValue={filterCategories}>
					{filterOptions.map((filter, index) => {
						const columnId = filter.columnId
						const header = filter.header
						const headerPlural = filter.plural
						const dataType = filter.dataType
						const uniqueValues = filter.uniqueValues
						return (
							<Fragment key={index}>
								<AccordionItem value={columnId}>
									<AccordionTrigger>
										{header}
									</AccordionTrigger>
									<AccordionContent className="px-4 py-1">
										{dataType === "number" &&
											numberFilter[columnId] && (
												<>
													<RangeSlider
														{...numberFilter[
															columnId
														]}
													/>
												</>
											)}
										{dataType === "date" &&
											dateFilter[columnId] && (
												<div className="pb-2">
													<DatePickerWithRange
														dateState={
															dateFilter[columnId]
																.dateState
														}
														earliestDate={
															dateFilter[columnId]
																.earliestDate
														}
														latestDate={
															dateFilter[columnId]
																.latestDate
														}
														dates={
															dateFilter[columnId]
																.dates
														}
													/>
												</div>
											)}
										{dataType === "string" && (
											<>
												{uniqueValues
													.filter((_, index) =>
														isExpanded[columnId] ?
															true
														:	index <
															MAX_COLLAPSED_FILTERS,
													)
													.map((item, index) => {
														const value = item.value
														const count = item.count

														const labelIdentifier = `${columnId}-${value}`

														const label = `${
															value === null ?
																"Undefined"
															: value === "" ?
																"Blank"
															:	value
														} (${count})`
														return (
															<Fragment
																key={index}
															>
																<FilterCheckboxItem
																	key={
																		labelIdentifier
																	}
																>
																	<Checkbox
																		id={
																			labelIdentifier
																		}
																		checked={columnFilters.some(
																			(
																				columnFilter,
																			) =>
																				columnFilter.id ===
																					columnId &&
																				columnFilter.value.includes(
																					value,
																				),
																		)}
																		onCheckedChange={(
																			checked: boolean,
																		) =>
																			toggleFilter(
																				columnId,
																				value,
																				checked,
																			)
																		}
																	/>
																	<Label
																		htmlFor={
																			labelIdentifier
																		}
																		className="capitalize"
																	>
																		{label}
																	</Label>
																</FilterCheckboxItem>
															</Fragment>
														)
													})}
												{filter.uniqueValues.length >
													MAX_COLLAPSED_FILTERS && (
													<FilterExpandContainer>
														<Button
															variant="ghost"
															size="none"
															className={cn(
																"text-sm text-info",
															)}
															onClick={() =>
																toggleExpand(
																	columnId,
																)
															}
														>
															{(
																isExpanded[
																	filter
																		.columnId
																]
															) ?
																"Show less"
															:	`Show all ${headerPlural}`
															}
														</Button>
													</FilterExpandContainer>
												)}
											</>
										)}
									</AccordionContent>
								</AccordionItem>
								{index !== filterOptions.length - 1 && (
									<Separator className="h-[2px] bg-border-weak" />
								)}
							</Fragment>
						)
					})}
				</Accordion>
			</FiltersPanelBody>
			<Separator className="h-[2px] bg-border-weak" />
			<FiltersFooter>
				<Button
					variant="ghost"
					size="none"
					onClick={resetFilters}
					disabled={columnFilters.length > 0 ? false : true}
				>
					Clear all
				</Button>
				<Button
					variant="primary"
					size="default"
					onClick={() => setFilters(columnFilters)}
					disabled={disableApply}
				>
					Apply
				</Button>
			</FiltersFooter>
		</FiltersPanel>
	)
}
