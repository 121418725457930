import { Hammer } from "lucide-react"

import type { Service } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

import { useServicesDetail } from "../../context"

export const ServiceLaborSection: DetailsSectionComponent<Service> = ({
	data: service,
}) => {
	const { isEditedVariant, setIsEditedVariant } = useServicesDetail()

	return (
		<DetailsSection>
			<DetailsSectionHeading>Labor</DetailsSectionHeading>
			<DetailsInfoGroup label="Estimated Labor Time">
				{service?.estimatedLaborTimeMinutes} minutes
			</DetailsInfoGroup>
			<div className="flex items-center gap-5">
				<DetailsInfoGroup label="Flat Labor Fee">
					{service?.flatLaborFee}
				</DetailsInfoGroup>
				<DetailsInfoGroup label="Use Flat Fee">
					{service?.useFlatLaborFee ? "Yes" : "No"}
				</DetailsInfoGroup>
			</div>
		</DetailsSection>
	)
}
ServiceLaborSection.label = "Labor"
ServiceLaborSection.icon = Hammer
