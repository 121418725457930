import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildObject,
} from "@/lib/utils/data/validators"
import { workOrderHistoryTable } from "@/server/tables"

import { baseEmployeeSchema, baseEmployeeSelectColumns } from "../employee"

const workOrderHistoryOmitFields = {
	scopeId: true,
} as const
export const workOrderHistorySelectColumns = {
	...getTableSelectColumns(workOrderHistoryTable, workOrderHistoryOmitFields),
	employee: jsonBuildObject(baseEmployeeSelectColumns).as(
		"work_order_history_employee",
	),
}

export const workOrderHistorySchema = createSelectSchema(workOrderHistoryTable)
	.omit(workOrderHistoryOmitFields)
	.extend({ employee: baseEmployeeSchema })
export type WorkOrderHistory = z.infer<typeof workOrderHistorySchema>

export const newWorkOrderHistorySchema = createInsertSchema(
	workOrderHistoryTable,
).omit(workOrderHistoryOmitFields)
export type NewWorkOrderHistory = z.infer<typeof newWorkOrderHistorySchema>
