import { Bolt, MapPin, MessageCircleCodeIcon } from "lucide-react"

import type { AlternatePart, Part, ServicePart } from "@/server/schemas"
import {
	DetailsCard,
	DetailsCardInfoLine,
	DetailsCardInfoLinesWrapper,
	DetailsCardOverline,
	DetailsCardSubtitle,
	DetailsCardTitle,
} from "@/components/ui/details-card"

interface PartDetailsCardProps extends React.HTMLAttributes<HTMLDivElement> {
	part: ServicePart
}

//TODO: Implement this with real data
export function PartDetailsCard({ part, className }: PartDetailsCardProps) {
	return (
		<DetailsCard className={className}>
			<DetailsCardOverline icon={Bolt}>Part</DetailsCardOverline>
			{/* <Button
				variant="ghost"
				size="icon"
				className="absolute right-1 top-1"
			>
				<EllipsisIcon className="size-4" />
			</Button> */}

			<DetailsCardTitle>{part.part.name}</DetailsCardTitle>
			<DetailsCardSubtitle>
				{part.part.manufacturerCode}
			</DetailsCardSubtitle>

			<DetailsCardInfoLinesWrapper>
				<DetailsCardInfoLine icon={MapPin}>
					3 Locations
				</DetailsCardInfoLine>
				<DetailsCardInfoLine icon={MessageCircleCodeIcon}>
					{part.part.manufacturerPartNumber}
				</DetailsCardInfoLine>
			</DetailsCardInfoLinesWrapper>
		</DetailsCard>
	)
}
