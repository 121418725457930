export const purchaseOrderPaymentMethodOptions = ["INVOICE", "OTHER"] as const
export type PurchaseOrderPaymentMethod =
	(typeof purchaseOrderPaymentMethodOptions)[number]

export const purchaseOrderStatusOptions = [
	"DRAFT",
	"BACKORDERED",
	"OPEN",
	"SUBMITTED",
] as const
export type PurchaseOrderStatus = (typeof purchaseOrderStatusOptions)[number]
