import { useState } from "react"
import { trpcClient } from "@/trpc/client"
import { ChevronLeftIcon, PlusIcon, TagIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import { cn } from "@/lib/utils/classnames"
import { getTagColorHex, tagColorNameOptions } from "@/lib/utils/data"
import type { TagOptionFeature } from "@/lib/utils/data"
import type { NewTagOption, Tag, TagOption } from "@/server/schemas"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Chip } from "@/components/ui/chip"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"

interface AddTagButtonProps {
	feature: TagOptionFeature
}
export function AddTagButton({ feature }: AddTagButtonProps) {
	const [view, setView] = useState<"main" | "custom">("main")
	const [newTagValues, setNewTagValues] = useState<TagOption>({
		feature,
		tag: "",
		colorName: "GRAY",
	})

	const { setValue, watch } = useFormContext<{ tags: Tag[] }>()

	const formTags = watch("tags") || []

	const handleTagSelect = (tagSelected: TagOption) => {
		const existingTag = formTags.find(
			(formTag) => formTag.tag === tagSelected.tag,
		)

		const updatedTags =
			existingTag ?
				formTags.filter((t) => t.tag !== tagSelected.tag) // Remove the existing tag
			:	[...formTags, { rowId: 0, ...tagSelected }] // or add the new tag if it doesn't exist

		setValue("tags", updatedTags) // Update form state
	}

	const { mutateAsync: createTag } =
		trpcClient.tags.createTagOption.useMutation({
			onSuccess: async () => {
				console.log("Tag created")
				handleTagSelect(newTagValues)
				await refetch()
			},
		})

	const {
		data: tagOptions,
		isLoading: tagsAreLoading,
		refetch,
	} = trpcClient.tags.getAllTagOptionsByFeature.useQuery(feature)

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="placeholder"
					size="lg"
					className="w-full gap-1 border-dashed text-info-text"
				>
					<PlusIcon className="size-5" />
					Add tag
				</Button>
			</DropdownMenuTrigger>

			<DropdownMenuContent
				onCloseAutoFocus={() => setView("main")}
				className={cn(
					"w-[var(--radix-dropdown-menu-trigger-width)]",
					view === "main" &&
						"max-h-[var(--radix-dropdown-menu-trigger-width)]",
				)}
			>
				{view === "main" ?
					<>
						<DropdownMenuItem
							onClick={(e) => {
								e.preventDefault()
								setView("custom")
								setNewTagValues({
									tag: "",
									feature,
									colorName: "GRAY",
								})
							}}
						>
							Create new tag
						</DropdownMenuItem>

						<DropdownMenuSeparator />

						{tagsAreLoading ?
							<div className="py-1 text-base">Loading...</div>
						: tagOptions?.success ?
							tagOptions.value.map((tagOption) => (
								<DropdownMenuItem
									key={tagOption.tag}
									onClick={(e) => {
										e.preventDefault()
										handleTagSelect(tagOption)
									}}
									className="flex justify-between"
								>
									<div className="flex items-center gap-2">
										<TagIcon
											className={cn(
												(
													formTags.some(
														(t) =>
															t.tag ===
															tagOption.tag,
													)
												) ?
													"size-5 fill-info/70 stroke-1"
												:	"rotate-45",
											)}
										/>
										{tagOption.tag}
									</div>

									<Chip
										className="h-3 w-6 border-none shadow shadow-border"
										style={{
											backgroundColor: getTagColorHex(
												tagOption.colorName,
											),
										}}
									/>
								</DropdownMenuItem>
							))
						:	<div className="grid w-full place-items-center p-4 text-sm text-foreground-weaker">
								No tags found
							</div>
						}
					</>
				:	<div className="flex flex-col">
						<Button
							className="w-full rounded-none border-b border-border-weak"
							variant="ghost"
							onClick={() => setView("main")}
							size="sm"
						>
							<ChevronLeftIcon className="size-5" />
							Back
						</Button>

						<div className="flex flex-col gap-2 p-2">
							<Input
								placeholder="Tag text"
								value={newTagValues.tag}
								onChange={(e) =>
									setNewTagValues((prev) => ({
										...prev,
										tag: e.target.value,
									}))
								}
							/>
							<div className="grid grid-cols-4 gap-2 p-1">
								{tagColorNameOptions.map((colorName) => (
									<ColorSwatch
										key={colorName}
										color={getTagColorHex(colorName)}
										selected={
											colorName === newTagValues.colorName
										}
										onClick={() =>
											setNewTagValues((prev) => ({
												...prev,
												colorName,
											}))
										}
									/>
								))}
							</div>
							<Button
								className="w-full"
								variant="primary"
								disabled={
									!newTagValues.colorName || !newTagValues.tag
								}
								onClick={() => {
									// Handle tag creation here
									setView("main")
									createTag({
										tag: newTagValues.tag,
										feature: feature,
										colorName: newTagValues.colorName,
									})
								}}
							>
								Add tag
							</Button>
						</div>
					</div>
				}
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

interface ColorSwatchProps {
	color: string
	selected: boolean
	onClick: () => void
}

function ColorSwatch({ color, selected, onClick }: ColorSwatchProps) {
	return (
		<Button
			variant="ghost"
			className={`aspect-square size-full rounded-lg ${selected ? "outline-2 outline-offset-1" : ""}`}
			style={{ backgroundColor: color }}
			onClick={onClick}
		/>
	)
}
