import type { FakePendingWork } from "@/routes/_main+/work+/pending-work/mock-data"

import {
	DetailsContext,
	DetailsContextBar,
	DetailsSubtitle,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

import { PendingWorkHeaderOptionsMenu } from "../header-options-menu"

export const PendingWorkPanelHeader: DetailsHeaderContent<FakePendingWork> = ({
	data: pendingWork,
}) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="pendingWork" />
			<DetailsTopRightActions>
				<PendingWorkHeaderOptionsMenu pendingWork={pendingWork} />
			</DetailsTopRightActions>
		</DetailsContextBar>

		<DetailsTitle>
			{pendingWork.id} | {pendingWork.equipment.year}{" "}
			{pendingWork.equipment.make} {pendingWork.equipment.model}
		</DetailsTitle>
		<DetailsSubtitle>{pendingWork.equipment.lastMeter} mi</DetailsSubtitle>
	</>
)
