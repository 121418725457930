import { useState } from "react"
import type { PropsWithChildren } from "react"

interface DragDropCSVButtonProps extends PropsWithChildren {
	onFileDrop?: (csvData: string) => Promise<void> // Optional callback to handle CSV data
}

const DragDropCSVButton: React.FC<DragDropCSVButtonProps> = ({
	children,
	onFileDrop,
}) => {
	const [dragActive, setDragActive] = useState(false)

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		setDragActive(true)
	}

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		setDragActive(false)
	}

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		setDragActive(false)

		const file = e.dataTransfer.files[0]

		if (file && file.type === "text/csv") {
			const reader = new FileReader()
			reader.onload = (event) => {
				if (event.target?.result) {
					const csvData = event.target.result as string
					// console.log("CSV Data:", csvData)

					// Trigger the callback if provided
					onFileDrop?.(csvData)
				}
			}
			reader.readAsText(file)
		} else {
			alert("Please drop a valid CSV file.")
		}
	}

	return (
		<div
			className={`flex items-center justify-center rounded-lg border p-1 px-2 ${
				dragActive ?
					"border-info bg-background"
				:	"border-border bg-background-weaker"
			} cursor-pointer`}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
		>
			{children}
			{/* <input type="file" /> */}
		</div>
	)
}

export default DragDropCSVButton
