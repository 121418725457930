export const fuelTypeCodeOptions = [
	"Regular",
	"Regular Unleaded",
	"Premium",
	"Premium Unleaded",
	"Diesel",
	"Other Diesel",
	"Natural Gas",
	"Propane",
	"Alternate Fuel",
	"Motor Oil",
	"Service",
] as const
export type FuelTypeCode = (typeof fuelTypeCodeOptions)[number]

export const fuelTypeOptions = [
	{ code: "Regular", class: "F" },
	{ code: "Regular Unleaded", class: "F" },
	{ code: "Premium", class: "F" },
	{ code: "Premium Unleaded", class: "F" },
	{ code: "Diesel", class: "F" },
	{ code: "Other Diesel", class: "F" },
	{ code: "Natural Gas", class: "F" },
	{ code: "Propane", class: "F" },
	{ code: "Alternate Fuel", class: "F" },
	{ code: "Motor Oil", class: "O" },
	{ code: "Service", class: "X" },
] as const
export type FuelType = (typeof fuelTypeOptions)[number]

/**
 * Returns the complete details of the Fuel Type Code
 *
 * @param code Fuel Type Code to find
 * @returns Fuel Type Code, or undefined if that code does not exist
 */
export function getFuelType(code: FuelTypeCode): FuelType | undefined {
	return fuelTypeOptions.find((elem) => elem.code === code)
}
