import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { partTypeTable } from "@/server/tables"

const partTypeOmitFields = {
	scopeId: true,
	isDeleted: true,
	deletedDate: true,
} as const
export const partTypeSelectColumns = {
	...getTableSelectColumns(partTypeTable, partTypeOmitFields),
}

export const partTypeSchema =
	createSelectSchema(partTypeTable).omit(partTypeOmitFields)
export type PartType = z.infer<typeof partTypeSchema>

export const newPartTypeSchema =
	createInsertSchema(partTypeTable).omit(partTypeOmitFields)
export type NewPartType = z.infer<typeof newPartTypeSchema>
