import type {
	FakePendingWork,
	FakePreventiveMaintenance,
} from "@/routes/_main+/work+/pending-work/mock-data"
import { PlusIcon, XIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { Link } from "@/components/ui/link"

export function PendingWork({ pendingWork }: { pendingWork: FakePendingWork }) {
	const allWork = [
		...pendingWork.workRequests,
		...pendingWork.preventiveMaintenance,
	]

	return (
		<DetailsSection className="@container">
			<DetailsSectionHeading>Pending Work</DetailsSectionHeading>
			{allWork.map((work, index) => (
				<div
					key={index}
					className="flex gap-3 rounded-md border border-dashed border-border-weak p-3"
				>
					<span className="text-lg font-bold">{index + 1}. </span>
					{"description" in work ?
						<RequestedWork work={work} />
					:	<PreventiveMaintenance pm={work} />}
				</div>
			))}
		</DetailsSection>
	)
}

function RequestedWork({
	work,
}: {
	work: {
		description: string
	}
}) {
	return (
		<div className="flex flex-col gap-2">
			<span className="text-lg font-bold">Work Requested</span>
			<span className="p-1 text-sm text-foreground-weak">
				{work.description}
			</span>
			<div className="flex gap-1">
				<Button variant="outline" size="sm" className="text-error">
					<XIcon className="size-5" />
					Deny
				</Button>
				<Button variant="outline" size="sm" className="grow text-info">
					<PlusIcon className="size-5" />
					Add service
				</Button>
			</div>
		</div>
	)
}

function PreventiveMaintenance({ pm }: { pm: FakePreventiveMaintenance }) {
	return (
		<div className="flex grow flex-col justify-between gap-2 @sm:flex-row @sm:items-center">
			<div className="flex flex-col gap-1">
				<div className="flex gap-1 text-lg font-bold">
					PM:
					<Link to="/" className="truncate font-bold">
						{pm.name}
					</Link>
				</div>
				<span className="text-sm text-foreground-weak">
					Due {pm.dateDue ? pm.dateDue : `${pm.mileageDue} miles`}
				</span>
			</div>
			<div className="flex gap-1">
				<Button variant="outline" size="icon" className="text-error">
					<XIcon className="size-5" />
				</Button>
				<Button variant="outline" className="text-info">
					<PlusIcon className="size-5" />
					Add
				</Button>
			</div>
		</div>
	)
}
