import type { FakeEquipment } from "@/routes/_main+/resources+/equipment/fake-equipment"
import { DollarSignIcon } from "lucide-react"

import type { Equipment } from "@/server/schemas"
import { DetailsSectionsGrouping } from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

import { EquipmentAccountingSection } from "./accounting"
import { EquipmentLeaseSection } from "./lease"
import { EquipmentValueSection } from "./value"

export const EquipmentFinancialSection: DetailsSectionComponent<Equipment> = ({
	data: equipment,
}) => (
	<DetailsSectionsGrouping>
		<EquipmentValueSection equipment={equipment} />
		<EquipmentLeaseSection equipment={equipment} />
		<EquipmentAccountingSection />
	</DetailsSectionsGrouping>
)
EquipmentFinancialSection.label = "Financial"
EquipmentFinancialSection.icon = DollarSignIcon
