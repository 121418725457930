import {
	boolean,
	decimal,
	integer,
	pgTable,
	serial,
	text,
	unique,
} from "drizzle-orm/pg-core"

import { activeFields, timestampFields } from "@/lib/utils/data"

import { scopeTable } from "../user"
import { vmrsComponentTable } from "../vmrs"
import { serviceGroupTable } from "./serviceGroup"

export const serviceTable = pgTable(
	"service",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		vmrsCode: text("vmrs_code").references(() => vmrsComponentTable.code, {
			onDelete: "set null",
		}),
		serviceGroupId: integer("service_group_id").references(
			() => serviceGroupTable.id,
			{ onDelete: "set null" },
		),
		code: text("code").notNull(),
		description: text("description").notNull(),
		type: text("type").notNull(),
		name: text("name").notNull(),
		version: text("version"),
		estimatedLaborTimeMinutes: integer("estimated_labor_time_minutes"),

		flatLaborFee: decimal("flat_labor_fee", { scale: 2 }).$type<number>(),
		useFlatLaborFee: boolean("use_flat_labor_fee").notNull().default(false),

		flatPartsFee: decimal("flat_parts_fee", { scale: 2 }).$type<number>(),
		useFlatPartsFee: boolean("use_flat_parts_fee").notNull().default(false),

		permitMultipleEntry: boolean("permit_multiple_entry")
			.notNull()
			.default(true),
		isDefault: boolean("is_default").notNull().default(false),
		...activeFields,
		...timestampFields,
	},
	(table) => [unique().on(table.scopeId, table.code)],
)
