import { integer, pgTable, serial, unique } from "drizzle-orm/pg-core"

import { scopeTable } from "../user"
import { equipmentTable } from "./equipment"

export const relatedEquipmentTable = pgTable(
	"related_equipment",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		equipmentId: integer("equipment_id")
			.references(() => equipmentTable.id, { onDelete: "cascade" })
			.notNull(),
		relatedEquipmentId: integer("related_equipment_id")
			.references(() => equipmentTable.id, { onDelete: "cascade" })
			.notNull(),
	},
	(table) => [
		unique().on(table.scopeId, table.equipmentId, table.relatedEquipmentId),
	],
)
