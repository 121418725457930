import { trpcClient } from "@/trpc/client"
import { EllipsisIcon } from "lucide-react"

import { useDetailsState } from "@/lib/hooks/use-details-state"
import { useInvalidator } from "@/lib/hooks/use-invalidator"
import type { Equipment } from "@/server/schemas"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useAddEdit } from "@/components/shared/add-edit/provider"

export function EquipmentHeaderOptionsMenu({
	equipment,
}: {
	equipment: Equipment
}) {
	const addEdits = useAddEdit()
	const details = useDetailsState()
	const { invalidateById } = useInvalidator()
	const { mutateAsync: deleteEquipment } =
		trpcClient.equipment.deleteEquipment.useMutation({
			onSuccess: () => {
				details.close()
				invalidateById("EQUIPMENT", equipment.id)
			},
		})

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" size="icon">
					<EllipsisIcon className="size-5" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start">
				<DropdownMenuGroup>
					<DropdownMenuLabel>Settings</DropdownMenuLabel>
				</DropdownMenuGroup>
				<DropdownMenuItem
					onClick={() => addEdits.openDialog("equipment", equipment)}
				>
					Edit
				</DropdownMenuItem>
				<DropdownMenuItem
					onSelect={() => deleteEquipment({ id: equipment.id })}
				>
					Delete
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
