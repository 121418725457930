import type { Equipment } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"

export function EquipmentLeaseSection({ equipment }: { equipment: Equipment }) {
	return (
		<DetailsSection columned>
			<DetailsSectionHeading className="col-start-1 col-end-3">
				Lease
			</DetailsSectionHeading>
			<DetailsInfoGroup label="Lease number">
				{equipment.leaseNumber}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Monthly lease fee">
				{equipment.monthlyLeaseFee && `$${equipment.monthlyLeaseFee}`}
			</DetailsInfoGroup>
		</DetailsSection>
	)
}
