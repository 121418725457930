import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { workOrderAttachmentTable } from "@/server/tables"

const workOrderAttachmentOmitFields = {
	scopeId: true,
} as const
export const workOrderAttachmentSelectColumns = {
	...getTableSelectColumns(
		workOrderAttachmentTable,
		workOrderAttachmentOmitFields,
	),
}

export const workOrderAttachmentSchema = createSelectSchema(
	workOrderAttachmentTable,
).omit(workOrderAttachmentOmitFields)
export type WorkOrderAttachment = z.infer<typeof workOrderAttachmentSchema>

export const newWorkOrderAttachmentSchema = createInsertSchema(
	workOrderAttachmentTable,
).omit(workOrderAttachmentOmitFields)
export type NewWorkOrderAttachment = z.infer<
	typeof newWorkOrderAttachmentSchema
>
