import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { getTableSelectColumns } from "@/lib/utils/data/validators"
import { equipmentMeterTable } from "@/server/tables"

const equipmentMeterOmitFields = {
	scopeId: true,
	isDeleted: true,
	deletedDate: true,
} as const
export const equipmentMeterSelectColumns = {
	...getTableSelectColumns(equipmentMeterTable, equipmentMeterOmitFields),
}

export const equipmentMeterSchema = createSelectSchema(
	equipmentMeterTable,
).omit(equipmentMeterOmitFields)
export type EquipmentMeter = z.infer<typeof equipmentMeterSchema>

export const newEquipmentMeterSchema = createInsertSchema(
	equipmentMeterTable,
).omit(equipmentMeterOmitFields)
export type NewEquipmentMeter = z.infer<typeof newEquipmentMeterSchema>
