import type { WorkOrder } from "@/routes/_main+/work+/work-orders/columns"
import { History } from "lucide-react"

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const WorkOrderHistorySection: DetailsSectionComponent<WorkOrder> = ({
	data: workOrder,
}) => (
	<DetailsSection>
		<Accordion type="multiple" defaultValue={["history"]}>
			<AccordionItem value="history">
				<AccordionTrigger className="px-0">
					<DetailsSectionHeading>History</DetailsSectionHeading>
				</AccordionTrigger>
				<AccordionContent className="mb-5 p-0 pt-1">
					<div className="flex flex-col gap-3">
						<div>
							<span className="mr-1.5 italic text-foreground-weak">
								1/21/25 -
							</span>
							<span>Work order created by J. Userman</span>
						</div>
						<div>
							<span className="mr-1.5 italic text-foreground-weak">
								1/25/25 -
							</span>
							<span>Assigned to R. McDougal</span>
						</div>
					</div>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	</DetailsSection>
)
WorkOrderHistorySection.label = "History"
WorkOrderHistorySection.icon = History
