import { useState } from "react"
import type {
	PurchaseOrder,
	PurchaseOrderLine,
} from "@/routes/_main+/work+/purchase-orders/mock-data"
import { Info } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card"
import { DetailsSection } from "@/components/ui/details-view"
import { Input, InputSlot } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { Overline } from "@/components/ui/typography"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { Notes } from "@/components/shared/details/notes"

const LineItemDetails = ({
	line,
	status,
}: {
	line: PurchaseOrderLine
	status: PurchaseOrder["status"]
}) => {
	const [quantity, setQuantity] = useState(line.quantity)
	const [unitPrice, setUnitPrice] = useState(line.unitPrice)

	const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQuantity(Number(e.target.value))
	}

	const handleUnitPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUnitPrice(Number(e.target.value))
	}

	const renderDefaultDetails = () => (
		<>
			<div className="flex items-center gap-2">
				<Input
					type="number"
					value={quantity}
					onChange={handleQuantityChange}
					className="w-10 shrink-0 *:px-2 *:text-right"
				/>
				<span>&times;</span>
				<Input
					type="number"
					value={unitPrice}
					onChange={handleUnitPriceChange}
					className="w-20 shrink-0 *:px-2 *:text-right"
				>
					<InputSlot>$</InputSlot>
				</Input>
				<span>=</span>
			</div>
			<span className="ml-auto font-medium">
				${(quantity * unitPrice).toFixed(2)}
			</span>
		</>
	)

	const renderReceivingDetails = () => (
		<>
			<div className="flex items-stretch gap-4 *:flex *:flex-col *:gap-1 *:leading-none">
				<div>
					<Overline>Ordered</Overline>
					<span className="my-auto">{line.quantity} ea</span>
				</div>
				<div>
					<Overline>Received</Overline>
					<div className="flex items-center gap-4">
						<Input
							type="number"
							defaultValue={line.quantity}
							className="w-16 shrink-0 *:text-right"
							placeholder="received"
						>
							<InputSlot side="end">ea</InputSlot>
						</Input>
						<span>&times;</span>
					</div>
				</div>
				<div>
					<Overline>Price Per</Overline>
					<Input
						type="number"
						defaultValue={line.unitPrice}
						className="w-20 shrink-0 *:px-2 *:text-right"
					>
						<InputSlot>$</InputSlot>
					</Input>
				</div>
			</div>
		</>
	)

	const renderClosedDetails = () => (
		<>
			<div className="flex items-stretch gap-4 *:flex *:flex-col *:gap-1 *:leading-none">
				<div>
					<Overline>Ordered</Overline>
					<span>{line.quantity} ea</span>
				</div>
				<div>
					<Overline>Received</Overline>
					<span>{line.quantity} ea</span>
				</div>
				<div>
					<Overline>&nbsp;</Overline>
					<span>&times;</span>
				</div>
				<div>
					<Overline>Price Per</Overline>
					<span>${line.unitPrice}</span>
				</div>
			</div>
		</>
	)

	switch (status) {
		case "Draft":
		case "Backordered":
		case "Submitted":
			return renderDefaultDetails()
		case "In Receiving":
			return renderReceivingDetails()
		case "Closed":
			return renderClosedDetails()
		default:
			return null
	}
}

export const PurchaseOrderInfoSection: DetailsSectionComponent<
	PurchaseOrder
> = ({ data: purchaseOrder }) => (
	<DetailsSection className="grow gap-0 overflow-hidden">
		<div className="mt-2 flex-1 overflow-auto px-6 py-3">
			<div className="grid gap-4">
				<Notes notes={[]} />
				{purchaseOrder.lines.map((line) => (
					<Card key={line.partId} className="@container">
						<CardHeader className="p-4">
							<CardTitle>{line.name}</CardTitle>
							<CardDescription>
								{line.partId.slice(0, 10)}... | {line.brand}
							</CardDescription>
						</CardHeader>
						<CardContent className="px-4 pb-4">
							<div className="flex items-center gap-4 @lg:w-fit">
								<LineItemDetails
									line={line}
									status={purchaseOrder.status}
								/>
							</div>
						</CardContent>
					</Card>
				))}
			</div>
		</div>
		<div className="mx-3 grid grid-cols-2 gap-4 rounded-md bg-background-weak p-4">
			<div className="space-y-2">
				<div>
					<span className="font-medium">
						{purchaseOrder.lines.length}
					</span>{" "}
					lines
				</div>
				<div>
					<span className="font-medium">
						{purchaseOrder.lines.reduce(
							(total, line) => total + line.quantity,
							0,
						)}
					</span>{" "}
					items
				</div>
			</div>
			<div className="grid gap-2">
				<div className="flex items-center justify-between">
					<Overline>Subtotal</Overline>
					<span>
						$
						{purchaseOrder.lines
							.reduce(
								(sum, line) =>
									sum + line.quantity * line.unitPrice,
								0,
							)
							.toFixed(2)}
					</span>
				</div>
				<div className="flex items-center justify-between">
					<Overline>Tax</Overline>
					<span>
						$
						{(
							purchaseOrder.lines.reduce(
								(sum, line) =>
									sum + line.quantity * line.unitPrice,
								0,
							) * 0.095
						).toFixed(2)}
					</span>
				</div>
				<div className="flex items-center justify-between">
					<Overline>Total</Overline>
					<span>
						$
						{(
							purchaseOrder.lines.reduce(
								(sum, line) =>
									sum + line.quantity * line.unitPrice,
								0,
							) * 1.095
						).toFixed(2)}
					</span>
				</div>
			</div>
		</div>
		<div className="p-3">
			<Separator className="mb-3" />
			<div className="grid grid-cols-2 gap-2">
				{purchaseOrder.status !== "Closed" && (
					<Button>More options...</Button>
				)}
				{purchaseOrder.status === "Draft" && (
					<Button variant="primary">Submit</Button>
				)}
				{(purchaseOrder.status === "Submitted" ||
					purchaseOrder.status === "Backordered") && (
					<Button variant="primary">Receive</Button>
				)}
				{purchaseOrder.status === "In Receiving" && (
					<Button variant="primary">Close</Button>
				)}
				{purchaseOrder.status === "Closed" && (
					<Button className="col-start-2">Edit</Button>
				)}
			</div>
		</div>
	</DetailsSection>
)

PurchaseOrderInfoSection.label = "Info"
PurchaseOrderInfoSection.icon = Info
PurchaseOrderInfoSection.disableScrollArea = true
PurchaseOrderInfoSection.disablePadding = true
