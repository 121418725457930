import { pgTable, primaryKey, text } from "drizzle-orm/pg-core"

import { metaFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"
import { partTable } from "./part"

export const partMetaTable = pgTable(
	"part_meta",
	{
		...metaFields(scopeTable, partTable),
		unit: text("unit"),
	},
	(table) => [primaryKey({ columns: [table.field, table.rowId] })],
)
