import type { LucideIcon } from "lucide-react"
import {
	BoltIcon,
	BoxesIcon,
	CarFrontIcon,
	CheckSquareIcon,
	ClockIcon,
	DollarSignIcon,
	FileIcon,
	GemIcon,
	HomeIcon,
	LayoutIcon,
	MapPinIcon,
	PackageIcon,
	Settings2Icon,
	ShieldIcon,
	ShoppingBagIcon,
	StarIcon,
	TruckIcon,
	UserIcon,
	UsersIcon,
	WrenchIcon,
} from "lucide-react"

import type { MultiDetailsView, SingleDetailsView } from "./details-view"
import type { FeatureLabels } from "./labels"
import { featureLabels } from "./labels"
import type { ListViewConfig } from "./views"
import { listViewConfig } from "./views"

type FeatureConfig = Record<
	string,
	{
		labels: FeatureLabels
		icon: LucideIcon
		details: {
			singleSelectView: SingleDetailsView
			multipleSelectView: MultiDetailsView | null
		} | null
		listViews: ListViewConfig[keyof ListViewConfig] | null
	}
>

export const featureConfig = {
	customer: {
		labels: featureLabels.customer,
		icon: UserIcon,
		details: {
			singleSelectView: "customer",
			multipleSelectView: "customer-multi",
		},
		listViews: listViewConfig.customer,
	},
	dashboard: {
		labels: featureLabels.dashboard,
		icon: LayoutIcon,
		details: null,
		listViews: null,
	},
	employee: {
		labels: featureLabels.employee,
		icon: UsersIcon,
		details: {
			singleSelectView: "employee",
			multipleSelectView: "employee-multi",
		},
		listViews: listViewConfig.employee,
	},
	vendor: {
		labels: featureLabels.vendor,
		icon: PackageIcon,
		details: {
			singleSelectView: "vendor",
			multipleSelectView: "vendor-multi",
		},
		listViews: listViewConfig.vendor,
	},
	workOrder: {
		labels: featureLabels.workOrder,
		icon: WrenchIcon,
		details: {
			singleSelectView: "work-order",
			multipleSelectView: "work-order-multi",
		},
		listViews: listViewConfig.workOrder,
	},
	purchaseOrder: {
		labels: featureLabels.purchaseOrder,
		icon: ShoppingBagIcon,
		details: {
			singleSelectView: "purchase-order",
			multipleSelectView: "purchase-order-multi",
		},
		listViews: listViewConfig.purchaseOrder,
	},
	part: {
		labels: featureLabels.part,
		icon: BoltIcon,
		details: {
			singleSelectView: "part",
			multipleSelectView: "part-multi",
		},
		listViews: listViewConfig.part,
	},
	pendingWork: {
		labels: featureLabels.pendingWork,
		icon: CarFrontIcon,
		details: {
			singleSelectView: "pending-work",
			multipleSelectView: "work-order-multi",
		},
		listViews: listViewConfig.pendingWork,
	},
	maintenance: {
		labels: featureLabels.maintenance,
		icon: CheckSquareIcon,
		details: null,
		listViews: null,
	},
	shopClock: {
		labels: featureLabels.shopClock,
		icon: ClockIcon,
		details: null,
		listViews: null,
	},
	financial: {
		labels: featureLabels.financial,
		icon: DollarSignIcon,
		details: null,
		listViews: null,
	},
	inventory: {
		labels: featureLabels.inventory,
		icon: BoxesIcon,
		details: null,
		listViews: null,
	},
	equipment: {
		labels: featureLabels.equipment,
		icon: TruckIcon,
		details: {
			singleSelectView: "equipment",
			multipleSelectView: "equipment-multi",
		},
		listViews: listViewConfig.equipment,
	},
	service: {
		labels: featureLabels.service,
		icon: WrenchIcon,
		details: {
			singleSelectView: "service",
			multipleSelectView: null,
		},
		listViews: listViewConfig.services,
	},
	settings: {
		labels: {
			navigation: "Settings",
			singular: "Setting",
			plural: "Settings",
			abbreviation: "Set",
		},
		icon: Settings2Icon,
		details: null,
		listViews: null,
	},
	location: {
		labels: featureLabels.location,
		icon: MapPinIcon,
		details: {
			singleSelectView: "location",
			multipleSelectView: "location-multi",
		},
		listViews: listViewConfig.location,
	},
	report: {
		labels: featureLabels.report,
		icon: FileIcon,
		details: null,
		listViews: null,
	},
	user: {
		labels: featureLabels.user,
		icon: UserIcon,
		details: null,
		listViews: null,
	},
	accounting: {
		labels: featureLabels.accounting,
		icon: StarIcon,
		details: null,
		listViews: null,
	},

	//THESE FEATURES WILL BE REMOVED:
	home: {
		labels: featureLabels.home,
		icon: HomeIcon,
		details: null,
		listViews: null,
	},
	component: {
		labels: featureLabels.component,
		icon: LayoutIcon,
		details: null,
		listViews: null,
	},
	sentry: {
		labels: featureLabels.sentry,
		icon: ShieldIcon,
		details: null,
		listViews: null,
	},
	deployment: {
		labels: featureLabels.deployment,
		icon: GemIcon,
		details: null,
		listViews: null,
	},
} satisfies FeatureConfig

export type Feature = keyof typeof featureConfig
