// import type { Equipment } from "@/server/schemas"
// Uncomment above once the schema is available and remove the below import
import type { Table as TanstackTable } from "@tanstack/react-table"

import type { Service } from "@/server/schemas"
import { QueueItemRow, QueueItems } from "@/components/ui/queue"
import { QueueView } from "@/components/shared/queue"

export function ServicesQueue({
	listDataState,
}: {
	listDataState: TanstackTable<Service>
}) {
	return (
		<QueueView>
			<QueueItems
				listDataState={listDataState}
				renderItem={(service) => (
					<>
						<QueueItemRow>
							<div className="truncate font-bold">
								{service?.name}
							</div>
							<div className="shrink-0 text-sm text-foreground-weak">
								{service?.estimatedLaborTimeMinutes} min
							</div>
						</QueueItemRow>
						<QueueItemRow variant="secondary">
							<div className="truncate">{service.version}</div>
							{service?.parts?.length > 0 && (
								<div className="shrink-0">
									{service?.parts?.length} Part
									{service?.parts?.length > 1 && <>s</>}
								</div>
							)}
						</QueueItemRow>
					</>
				)}
				onRowClick={(service) => {
					listDataState.setRowSelection({
						[service.id]: true,
					})
				}}
				isActive={(service) =>
					listDataState
						.getSelectedRowModel()
						.rows.some((row) => row.id === service.id.toString())
				}
			/>
		</QueueView>
	)
}
