import type { PurchaseOrder } from "@/routes/_main+/work+/purchase-orders/mock-data"
import { History } from "lucide-react"

import {
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"

export const PurchaseOrderHistorySection: DetailsSectionComponent<
	PurchaseOrder
> = ({ data: purchaseOrder }) => (
	<DetailsSection>
		<DetailsSectionHeading>History</DetailsSectionHeading>
		<div className="mt-1 flex flex-col gap-3">
			{purchaseOrder.tags.map((tag, index) => (
				<div key={index}>
					<span className="mr-1.5 italic text-foreground-weak">
						{new Date(
							parseInt(purchaseOrder.poDate),
						).toLocaleDateString()}{" "}
						-
					</span>
					<span>{tag}</span>
				</div>
			))}
		</div>
	</DetailsSection>
)

PurchaseOrderHistorySection.label = "History"
PurchaseOrderHistorySection.icon = History
