import { pgTable } from "drizzle-orm/pg-core"
import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import { tagFields } from "@/lib/utils/data/schema"
import { locationTable, scopeTable } from "@/server/tables"

const tempTable = pgTable(
	"temp_tag_table",
	tagFields(scopeTable, locationTable),
)

export const tagSchema = createSelectSchema(tempTable).omit({
	scopeId: true,
})
export type Tag = z.infer<typeof tagSchema>

export const newTagSchema = createInsertSchema(tempTable).omit({
	scopeId: true,
})
export type NewTag = z.infer<typeof newTagSchema>
