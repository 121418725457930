import type { Service } from "@/server/schemas"
import { Chip } from "@/components/ui/chip"
import {
	DetailsContext,
	DetailsContextBar,
	DetailsTitle,
	DetailsTopRightActions,
} from "@/components/ui/details-view"
import type { DetailsHeaderContent } from "@/components/shared/details/details-view"

import { ServiceHeaderOptionsMenu } from "../header-options-menu"
import { ServiceVariantSelection } from "./variant-selection"

export const ServicesPageHeader: DetailsHeaderContent<Service> = ({
	data: service,
}) => (
	<>
		<DetailsContextBar>
			<DetailsContext feature="service" />
			<DetailsTopRightActions>
				<ServiceHeaderOptionsMenu service={service} />
			</DetailsTopRightActions>
		</DetailsContextBar>
		<DetailsTitle className="mb-1 flex items-center gap-2">
			<div className="grow">{service?.name}</div>
			{/* {service.status.toLowerCase() == "inactive" && (
				<Chip variant="warning">Inactive</Chip>
			)} */}
		</DetailsTitle>
		<ServiceVariantSelection />
		<div className="mt-3 flex flex-wrap gap-1">
			{service?.isDefault && <Chip variant="primary">Default</Chip>}
			{service?.tags &&
				service.tags.map((tag, i) => (
					<Chip key={`service-` + i}>{tag.tag}</Chip>
				))}
		</div>
	</>
)
