import type { register } from "module"
import { useState } from "react"
import { InfoIcon, PlusIcon } from "lucide-react"
import { useFieldArray, useFormContext } from "react-hook-form"

import type {
	CustomerAddress,
	NewCustomerAddress,
	NewVendor,
} from "@/server/schemas"
import {
	AddEditSection,
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { OverlineSmall } from "@/components/ui/typography"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const VendorAddEditInfo: AddEditSectionDefinition = {
	label: "Info",
	icon: InfoIcon,
	component: (
		<AddEditSectionsGrouping>
			<NameGroup />
			<ContactInfoGroup />
			<AddressGroup />
			<AttachmentsGroup />
		</AddEditSectionsGrouping>
	),
}

function NameGroup() {
	const { control } = useFormContext<NewVendor>()
	return (
		<AddEditSection className="grid grid-cols-8">
			<FormField
				control={control}
				name="name"
				render={({ field }) => (
					<FormItem className="col-span-6">
						<FormLabel>Name</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder={"Acme Corporation"}
							/>
						</FormControl>

						<FormMessage />
					</FormItem>
				)}
			/>
			{/* Why is this ID field here */}
			{/* <FormField
				control={control}
				name="id"
				render={({ field }) => (
					<FormItem className="col-span-2">
						<FormLabel>ID</FormLabel>
						<FormControl>
							<Input {...field} placeholder={"eg, ACME"} />
						</FormControl>

						<FormMessage />
					</FormItem>
				)}
			/> */}
		</AddEditSection>
	)
}

function ContactInfoGroup() {
	const { control } = useFormContext<NewVendor>()
	return (
		<AddEditSection>
			{/* There is no website field in the schema */}
			<FormField
				control={control}
				name="email"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Website</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder={"eg, www.acmecompany.com"}
								value={field.value ?? ""}
							/>
						</FormControl>

						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="email"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Email</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder={"eg, name@acmecompany.com"}
								value={field.value ?? ""}
							/>
						</FormControl>

						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="phone"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Phone</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder={"(555) 555-5555"}
								value={field.value ?? ""}
							/>
						</FormControl>

						<FormMessage />
					</FormItem>
				)}
			/>
		</AddEditSection>
	)
}

function AddressGroup() {
	const { control } = useFormContext<NewVendor>()

	return (
		<AddEditSection className="grid grid-cols-8">
			<FormField
				control={control}
				name="street1"
				render={({ field }) => (
					<FormItem className="col-span-full">
						<FormLabel>Address</FormLabel>
						<Input {...field} placeholder={"Street 1"} />
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				control={control}
				name="street2"
				render={({ field }) => (
					<FormItem className="col-span-full">
						<Input
							{...field}
							value={field.value ?? ""}
							placeholder={"Street 2"}
						/>
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				control={control}
				name="city"
				render={({ field }) => (
					<FormItem className="col-span-5">
						<Input
							{...field}
							value={field.value ?? ""}
							placeholder={"City"}
						/>
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				control={control}
				name="state"
				render={({ field }) => (
					<FormItem className="col-span-1">
						<Input
							{...field}
							value={field.value ?? ""}
							placeholder={"State"}
						/>
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				control={control}
				name="zip"
				render={({ field }) => (
					<FormItem className="col-span-2">
						<Input
							{...field}
							value={field.value ?? ""}
							placeholder={"Zip"}
						/>
						<FormMessage />
					</FormItem>
				)}
			/>
		</AddEditSection>
	)
}

// function AddFieldButton() {
// 	return (
// 		<Button
// 			className="w-full gap-2 border-dashed border-border text-info-text"
// 			variant="outline"
// 			onClick={() => {
// 				console.log("TODO: Add field")
// 			}}
// 		>
// 			<PlusIcon className="size-4" />
// 			Add Field
// 		</Button>
// 	)
// }

function AttachmentsGroup() {
	const { control } = useFormContext<NewVendor>()
	return (
		<AddEditSection>
			<AddEditSectionHeading>Attachments</AddEditSectionHeading>
			{/* TODO: Add attachments */}
			<Button
				variant="outline"
				className="mt-3 w-full gap-1 border-dashed text-primary shadow-none"
			>
				<PlusIcon className="size-4" />
				Add Attachment
			</Button>
		</AddEditSection>
	)
}
