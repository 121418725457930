import { format } from "date-fns"
import { CalendarIcon, DollarSignIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import { cn } from "@/lib/utils/classnames"
import type { NewEquipment } from "@/server/schemas"
import {
	AddEditSection,
	AddEditSectionHeading,
	AddEditSectionsGrouping,
} from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { Checkbox } from "@/components/ui/checkbox"
import { DetailsInfoGroup } from "@/components/ui/details-view"
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form"
import { Input, InputSlot } from "@/components/ui/input"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"
import type { AddEditSectionDefinition } from "@/components/shared/add-edit"

export const EquipmentAddEditFinancial: AddEditSectionDefinition = {
	label: "Financial",
	icon: DollarSignIcon,
	component: <GroupedFinancialSections />,
}

function GroupedFinancialSections() {
	return (
		<AddEditSectionsGrouping>
			<ValueFormSection />
			<LeaseFormSection />
			{/* <AccountingFormSection /> */}
		</AddEditSectionsGrouping>
	)
}

function ValueFormSection() {
	const { register, control, setValue } = useFormContext<NewEquipment>()
	return (
		<AddEditSection columned>
			<AddEditSectionHeading>Value</AddEditSectionHeading>
			<FormField
				control={control}
				name="bookValue"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Book Value</FormLabel>
						<FormControl>
							<Input
								{...field}
								type="number"
								placeholder="10000.00"
								value={field.value ?? ""}
								onChange={(value) =>
									field.onChange(value.target.valueAsNumber)
								}
							>
								<InputSlot side="start">$</InputSlot>
							</Input>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="residualValue"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Residual Value</FormLabel>
						<FormControl>
							<Input
								{...field}
								type="number"
								placeholder="10000.00"
								value={field.value ?? ""}
								onChange={(value) =>
									field.onChange(value.target.valueAsNumber)
								}
							>
								<InputSlot side="start">$</InputSlot>
							</Input>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				control={control}
				name="inServiceDate"
				render={({ field }) => (
					<FormItem className="col-span-full flex flex-col">
						<FormLabel>In-service date</FormLabel>
						<Popover>
							<PopoverTrigger asChild>
								<FormControl>
									<Button
										variant={"outline"}
										className={cn(
											"w-[240px] pl-3 text-left font-normal",
											!field.value &&
												"text-foreground-weak",
										)}
									>
										{field.value ?
											format(field.value, "PPP")
										:	<span>Pick a date</span>}
										<CalendarIcon className="ml-auto size-4 opacity-50" />
									</Button>
								</FormControl>
							</PopoverTrigger>
							<PopoverContent
								className="w-auto p-0"
								align="start"
							>
								<Calendar
									mode="single"
									selected={new Date(field.value ?? "")}
									captionLayout="dropdown"
									onSelect={(date) => {
										setValue(
											"inServiceDate",
											date?.toISOString(),
										)
									}}
									disabled={(date) =>
										date > new Date() ||
										date < new Date("1900-01-01")
									}
								/>
							</PopoverContent>
						</Popover>
						<FormMessage />
					</FormItem>
				)}
			/>

			{/* <DetailsInfoGroup label="In service date">
				<Input
					{...register("inServiceDate")}
					placeholder={"MM/DD/YYYY"}
				/>
			</DetailsInfoGroup> */}
			<FormField
				control={control}
				name="usefulMonthsAfter"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Useful Months After</FormLabel>
						<FormControl>
							<Input
								{...field}
								type="number"
								placeholder="0"
								onChange={(e) => {
									const value =
										e.target.value === "" ?
											""
										:	Number(e.target.value)
									field.onChange(value)
								}}
								value={field.value?.toString()}
							/>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="depreciationCode"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Depreciation Code</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder={"AB1"}
								value={field.value?.toString()}
							/>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="applyDistributedLabor"
				render={({ field }) => (
					<FormItem className="flex flex-row items-start space-x-3 space-y-0">
						<FormControl>
							<Checkbox
								checked={!!field.value}
								onCheckedChange={field.onChange}
							/>
						</FormControl>

						<FormLabel>Apply distributed labor</FormLabel>
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="applyDistributedMisc"
				render={({ field }) => (
					<FormItem className="flex flex-row items-start space-x-3 space-y-0">
						<FormControl>
							<Checkbox
								checked={!!field.value}
								onCheckedChange={field.onChange}
							/>
						</FormControl>

						<FormLabel>Apply distributed misc</FormLabel>
					</FormItem>
				)}
			/>
		</AddEditSection>
	)
}

function LeaseFormSection() {
	const { control } = useFormContext<NewEquipment>()
	return (
		<AddEditSection columned>
			<AddEditSectionHeading>Lease</AddEditSectionHeading>
			<FormField
				control={control}
				name="leaseNumber"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Lease Number</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder={"12345678"}
								value={field.value?.toString()}
							/>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={control}
				name="monthlyLeaseFee"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Monthly Lease Fee</FormLabel>
						<FormControl>
							<Input
								{...field}
								type="number"
								placeholder="500"
								value={field.value ?? ""}
								onChange={(value) =>
									field.onChange(value.target.valueAsNumber)
								}
							>
								<InputSlot side="start">$</InputSlot>
							</Input>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
		</AddEditSection>
	)
}

function AccountingFormSection() {
	const { register } = useFormContext()
	return (
		<AddEditSection columned>
			<AddEditSectionHeading>Accounting</AddEditSectionHeading>
			<DetailsInfoGroup label="Flat rate maintenance billing code">
				<Input
					{...register("flatRateMaintenanceBillingCode")}
					placeholder={"TRK500"}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Department">
				<Input
					{...register("department")}
					placeholder={"1000 - Fleet Maintenance"}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Expense category">
				<Input
					{...register("expenseCategory")}
					placeholder={"001 - Operating expenses"}
				/>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Unit">
				<Input
					{...register("unit")}
					placeholder={"0001 - North Region"}
				/>
			</DetailsInfoGroup>
		</AddEditSection>
	)
}
