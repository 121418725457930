import { forwardRef } from "react"
import { XIcon } from "lucide-react"

import { useFilterPanel } from "@/lib/hooks/use-filter"
import { cn } from "@/lib/utils/classnames"

import { Button } from "./button"
import { typographyVariants } from "./typography"

export const FiltersPanel = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn("flex size-full flex-col overflow-hidden", className)}
	>
		{children}
	</div>
))
FiltersPanel.displayName = "FiltersPanel"

export const FiltersHeader = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn("flex items-center justify-between px-3 py-1", className)}
	>
		{children}
	</div>
))
FiltersHeader.displayName = "FiltersHeader"

export const FiltersFooter = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn(
			"mt-auto flex items-center justify-between px-4 py-3",
			className,
		)}
	>
		{children}
	</div>
))
FiltersFooter.displayName = "FiltersFooter"

export const FiltersHeaderInfo = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div ref={ref} {...props} className={cn(className)}>
		{children}
	</div>
))
FiltersHeaderInfo.displayName = "FiltersHeaderInfo"

export const FiltersTopRightActions = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
	return (
		<div
			ref={ref}
			{...props}
			className={cn("flex items-center", className)}
		>
			{children}

			<FiltersPanelCloseButton />
		</div>
	)
})
FiltersTopRightActions.displayName = "FiltersTopRightActions"

export const FiltersPanelCloseButton = ({
	className,
}: {
	className?: string
}) => {
	const filter = useFilterPanel()

	return (
		<Button
			variant="ghost"
			size="icon"
			onClick={() => {
				filter.close()
			}}
			className={cn(className)}
		>
			<XIcon className="size-6" />
		</Button>
	)
}

export const FiltersPanelBody: React.FC<
	React.HTMLAttributes<HTMLDivElement>
> = ({ className, children }) => {
	return (
		<div
			className={cn(
				"flex-1 overflow-auto px-3 py-3 [scrollbar-width:thin]",
				className,
			)}
		>
			{children}
		</div>
	)
}

export const FilterHeaderGroup = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn(
			typographyVariants({ variant: "overlineSmall" }),
			className,
		)}
	>
		{children}
	</div>
))
FilterHeaderGroup.displayName = "FilterHeaderGroup"

export const FilterHeaderTitle = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn(
			"text-lg font-bold text-foreground-strong opacity-[75%]",
			className,
		)}
	>
		{children}
	</div>
))
FilterHeaderTitle.displayName = "FilterHeaderTitle"

export const SavedFiltersContainer = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn("flex items-center justify-between gap-2", className)}
	>
		{children}
	</div>
))
SavedFiltersContainer.displayName = "SavedFiltersContainer"

export const SavedFilterDropdownContainer = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div ref={ref} {...props} className={cn("flex flex-grow-[1]", className)}>
		{children}
	</div>
))
SavedFilterDropdownContainer.displayName = "SavedFilterDropdownContainer"

export const FilterCheckboxItem = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div
		ref={ref}
		{...props}
		className={cn("flex items-center gap-2 pb-2", className)}
	>
		{children}
	</div>
))
FilterCheckboxItem.displayName = "FilterCheckboxItem"

export const FilterExpandContainer = forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
	<div ref={ref} {...props} className={cn("flex justify-center", className)}>
		{children}
	</div>
))
FilterExpandContainer.displayName = "FilterExpandContainer"
