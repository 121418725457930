import {
	boolean,
	date,
	decimal,
	foreignKey,
	integer,
	pgTable,
	serial,
	text,
} from "drizzle-orm/pg-core"

import { activeFields, timestampFields } from "@/lib/utils/data/schema"

import { scopeTable } from "../user"

export const employeeTable = pgTable(
	"employee",
	{
		id: serial("id").primaryKey(),
		scopeId: integer("scope_id")
			.references(() => scopeTable.id, { onDelete: "cascade" })
			.notNull(),
		clerkUserId: text("clerk_user_id").unique(),
		supervisorEmployeeId: integer("supervisor_employee_id"),
		firstName: text("first_name").notNull(),
		lastName: text("last_name").notNull(),
		title: text("title").notNull(),
		role: text("role", {
			enum: ["driver", "mechanic", "manager", "employee"],
		}).notNull(),
		department: text("department"),
		employmentType: text("employment_type"),
		groupCode: text("group_code"),
		isAdmin: boolean("is_admin").notNull().default(false),
		avatarUrl: text("avatar_url"),

		workSiteCode: text("work_site_code"),
		workEmail: text("work_email"),
		workPhone: text("work_phone"),

		billOutsideRate: boolean("bill_outside_rate").notNull(),
		payRateType: text("pay_rate_type", {
			enum: ["hourly", "annual_salary"],
		}),
		payRate: decimal("pay_rate", { scale: 2 }).$type<number>(),
		hiredDate: date("hired_date", { mode: "string" }),
		unionJoinedDate: date("union_joined_date", { mode: "string" }),

		birthDate: date("birth_date", { mode: "string" }),
		lastFourSocial: text("last_four_social"),

		driverLicenseNumber: text("driver_license_number"),
		driverLicenseState: text("driver_license_state"),
		driverLicenseExpires: date("driver_license_expires", {
			mode: "string",
		}),

		medicalPolicy: text("medical_policy"),
		medicalExpires: date("medical_expires", { mode: "string" }),

		notes: text("notes"),
		...activeFields,
		...timestampFields,
	},
	(table) => [
		foreignKey({
			columns: [table.supervisorEmployeeId],
			foreignColumns: [table.id],
		}).onDelete("set null"),
	],
)
