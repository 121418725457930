import { createInsertSchema, createSelectSchema } from "drizzle-zod"
import { z } from "zod"

import {
	getTableSelectColumns,
	jsonBuildArray,
	jsonBuildObject,
} from "@/lib/utils/data/validators"
import { workOrderTable } from "@/server/tables"

import {
	equipmentInfoSchema,
	equipmentInfoSelectColumns,
	equipmentMeterSchema,
	equipmentMeterSelectColumns,
} from "../equipment"
import { baseLocationSchema, baseLocationSelectColumns } from "../location"
import { metaSchema, newMetaSchema, newTagSchema, tagSchema } from "../shared"
import { newNoteSchema, noteSchema } from "../shared/note"
import {
	newWorkOrderAttachmentSchema,
	workOrderAttachmentSchema,
	workOrderAttachmentSelectColumns,
} from "./workOrderAttachment"
import {
	newWorkOrderBillingSchema,
	workOrderBillingSchema,
	workOrderBillingSelectColumns,
} from "./workOrderBilling"
import {
	workOrderHistorySchema,
	workOrderHistorySelectColumns,
} from "./workOrderHistory"
import {
	newWorkOrderInvoiceSchema,
	workOrderInvoiceSchema,
	workOrderInvoiceSelectColumns,
} from "./workOrderInvoice"
import { workOrderMetaSelectColumns } from "./workOrderMeta"
import { workOrderNoteSelectColumns } from "./workOrderNote"
import {
	newWorkOrderServiceSchema,
	workOrderServiceSchema,
	workOrderServiceSelectColumns,
} from "./workOrderService"
import {
	workOrderShopWatchSchema,
	workOrderShopWatchSelectColumns,
} from "./workOrderShopWatch"
import { workOrderTagSelectColumns } from "./workOrderTag"

const workOrderOmitFields = {
	scopeId: true,
	createdAt: true,
	updatedAt: true,
	isDeleted: true,
	deletedDate: true,
} as const
export const baseWorkOrderSelectColumns = {
	...getTableSelectColumns(workOrderTable, workOrderOmitFields),
}
export const workOrderSelectColumns = {
	...baseWorkOrderSelectColumns,
	location: jsonBuildObject(baseLocationSelectColumns),
	equipment: jsonBuildObject(equipmentInfoSelectColumns),
	equipmentMeters: jsonBuildArray(equipmentMeterSelectColumns),
	invoice: jsonBuildObject(workOrderInvoiceSelectColumns),
	attachments: jsonBuildArray(workOrderAttachmentSelectColumns),
	shopWatches: jsonBuildArray(workOrderShopWatchSelectColumns),
	billing: jsonBuildArray(workOrderBillingSelectColumns),
	history: jsonBuildArray(workOrderHistorySelectColumns),
	notes: jsonBuildArray(workOrderNoteSelectColumns),
	metas: jsonBuildArray(workOrderMetaSelectColumns),
	tags: jsonBuildArray(workOrderTagSelectColumns),
	services: jsonBuildArray(workOrderServiceSelectColumns),
}

export const baseWorkOrderSchema =
	createSelectSchema(workOrderTable).omit(workOrderOmitFields)
export type BaseWorkOrder = z.infer<typeof baseWorkOrderSchema>

export const workOrderSchema = baseWorkOrderSchema.extend({
	location: baseLocationSchema,
	equipment: equipmentInfoSchema,
	equipmentMeters: equipmentMeterSchema.array().default([]),
	invoice: workOrderInvoiceSchema.optional(),
	attachments: workOrderAttachmentSchema.array().default([]),
	services: workOrderServiceSchema.array().default([]),
	shopWatches: workOrderShopWatchSchema.array().default([]),
	billing: workOrderBillingSchema.array().default([]),
	history: workOrderHistorySchema.array().default([]),
	notes: noteSchema.array().default([]),
	metas: metaSchema.array().default([]),
	tags: tagSchema.array().default([]),
})
export type WorkOrder = z.infer<typeof workOrderSchema>

const workOrderFormValidation = z.object({
	equipmentId: z.number({ message: "Please select an equipment" }),
})

export const newWorkOrderSchema = createInsertSchema(workOrderTable)
	.omit(workOrderOmitFields)
	.extend({
		code: z.string().optional(),
		invoice: newWorkOrderInvoiceSchema.optional(),
		attachments: newWorkOrderAttachmentSchema.array().default([]),
		services: newWorkOrderServiceSchema.array().default([]),
		billing: newWorkOrderBillingSchema.array().default([]),
		notes: newNoteSchema.array().default([]),
		metas: newMetaSchema.array().default([]),
		tags: newTagSchema.array().default([]),
	})
	.merge(workOrderFormValidation)
export type NewWorkOrder = z.infer<typeof newWorkOrderSchema>
