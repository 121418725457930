import { decimal, integer, pgTable, serial, text } from "drizzle-orm/pg-core"

import { activeFields, timestampFields } from "@/lib/utils/data"

import { scopeTable } from "../user"
import { vendorTable } from "./vendor"

export const vendorInvoiceTable = pgTable("vendor_invoice", {
	id: serial("id").primaryKey(),
	scopeId: integer("scope_id")
		.references(() => scopeTable.id, { onDelete: "cascade" })
		.notNull(),
	vendorId: integer("vendor_id")
		.references(() => vendorTable.id, { onDelete: "cascade" })
		.notNull(),
	code: text("code").notNull(),
	total: decimal("total", { scale: 2 }).$type<number>().notNull(),
	...activeFields,
	...timestampFields,
})
