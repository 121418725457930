import type { MotorAPI_TaggedVIN } from "@/server/schemas/equipment/equipmentExternal"

/**
 * Converts data returned from a CSV parser into tagged EquipmentCsvVin types.
 *
 * @param csvData CSV data to parse
 * @returns List of valid tag/vin pairs
 */
export const convertCsvToVinList = (
	csvData: string[][],
): MotorAPI_TaggedVIN[] => {
	const res: MotorAPI_TaggedVIN[] = []
	for (const row of csvData) {
		// Don't parse rows without both a tag and a vin
		if (row.length < 2) continue
		res.push({ tag: row[0]!, vin: row[1]! })
	}
	return res
}
