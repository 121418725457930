import { useState } from "react"
import { ImageIcon, PlusIcon } from "lucide-react"
import { useFormContext } from "react-hook-form"

import type { NewCustomer } from "@/server/schemas"
import { AddEditSummary } from "@/components/ui/add-edit"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { AddTagButton } from "@/components/shared/add-edit/add-tag"
import { Tags } from "@/components/shared/details/tags"

export const VendorAddEditSummary = () => {
	const { watch } = useFormContext<NewCustomer>()

	const [tempFileName, setTempFileName] = useState<string | undefined>()

	const formValues = watch()

	return (
		<AddEditSummary>
			<section className="flex w-full flex-col items-center">
				<div className="relative grid aspect-square w-full place-items-center rounded-md bg-background-weak">
					<ImageIcon className="size-10 text-foreground-weak" />
					<p className="absolute bottom-2 text-xs text-foreground-weak">
						{tempFileName ?? "No Photo"}
					</p>
				</div>
				<Button
					variant="link"
					onClick={() => document.getElementById("getFile")?.click()}
					className="text-sm"
					size="none"
				>
					Replace Photo
				</Button>
			</section>

			<Input
				type="file"
				id="getFile"
				className="hidden"
				onChange={(e) => {
					if (e.target.files) setTempFileName(e.target.files[0]?.name)
				}}
			/>

			<Tags tags={formValues.tags} centered />
			<AddTagButton feature="LOCATION" />

			<p className="font-semibold text-foreground-strong">
				{formValues.name}
			</p>
			<p className="text-foreground-strong">{formValues.id}</p>
			{formValues.addresses && (
				<section className="text-center font-semibold text-foreground-strong">
					<p>
						{formValues.addresses[0]?.street1}
						{formValues.addresses[0]?.street2 &&
							" " + formValues.addresses[0].street2}
					</p>
					<p>
						{formValues.addresses[0]?.city}
						{formValues.addresses[0]?.city &&
							formValues.addresses[0]?.state &&
							", "}
						{formValues.addresses[0]?.state}
						{formValues.addresses[0]?.zip &&
							" " + formValues.addresses[0].zip}
					</p>
				</section>
			)}
		</AddEditSummary>
	)
}
