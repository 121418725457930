import type { Equipment } from "@/server/schemas"
import {
	DetailsInfoGroup,
	DetailsSection,
	DetailsSectionHeading,
} from "@/components/ui/details-view"
import { Link } from "@/components/ui/link"

export function EquipmentValueSection({ equipment }: { equipment: Equipment }) {
	return (
		<DetailsSection columned>
			<DetailsSectionHeading className="col-start-1 col-end-3">
				Value
			</DetailsSectionHeading>
			<DetailsInfoGroup label="Book value">
				{equipment.bookValue}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Residual value">
				{equipment.residualValue}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="In service date">
				{equipment.inServiceDate &&
					new Date(equipment.inServiceDate).toDateString()}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Useful months after">
				{equipment.usefulMonthsAfter}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Depreciation Code">
				{equipment.depreciationCode}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Original purchase info">
				{/* TODO: Add original purchase info */}
				<Link to="/">PO 1518439313</Link>
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Apply distributed labor">
				{equipment.applyDistributedLabor ? "Yes" : "No"}
			</DetailsInfoGroup>
			<DetailsInfoGroup label="Apply distributed misc">
				{equipment.applyDistributedMisc ? "Yes" : "No"}
			</DetailsInfoGroup>
		</DetailsSection>
	)
}
