import type { Table as TanstackTable } from "@tanstack/react-table"

import type { Part } from "@/server/schemas"
import { Chip } from "@/components/ui/chip"
import { QueueItemRow, QueueItems } from "@/components/ui/queue"
import { QueueView } from "@/components/shared/queue"

export function PartQueue({
	listDataState,
}: {
	listDataState: TanstackTable<Part>
}) {
	return (
		<QueueView>
			<QueueItems
				listDataState={listDataState}
				statusLine={(part) => (!part.inStock ? "warning" : null)}
				renderItem={(part) => (
					<>
						<QueueItemRow>
							<div className="truncate font-bold">
								{part.name}
							</div>
							<div className="shrink-0 text-sm text-foreground-weak">
								{part.partTypeId}
							</div>
						</QueueItemRow>
						<QueueItemRow variant="secondary">
							<div className="shrink-0 text-foreground-weak">
								{part.MFG}
							</div>
							{!part.inStock && (
								<Chip variant="success" fill={false}>
									In Stock
								</Chip>
							)}
						</QueueItemRow>
					</>
				)}
				onRowClick={(part) => {
					listDataState.setRowSelection({
						[part.id]: true,
					})
				}}
				isActive={(part) =>
					listDataState
						.getSelectedRowModel()
						.rows.some((row) => row.id === part.id.toString())
				}
			/>
		</QueueView>
	)
}
