import { Bolt } from "lucide-react"

import type { WorkOrder } from "@/server/schemas"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import {
	DetailsSection,
	DetailsSectionHeading,
	DetailsSectionHeadingCount,
} from "@/components/ui/details-view"
import type { DetailsSectionComponent } from "@/components/shared/details/details-view"
import { PartDetailsCard } from "@/components/features/part/details/card"

export const WorkOrderPartsSection: DetailsSectionComponent<WorkOrder> = ({
	data: workOrder,
}) => {
	const serviceParts = workOrder.services.flatMap((service) => service.parts)

	return (
		<DetailsSection>
			<Accordion type="multiple" defaultValue={["parts"]}>
				<AccordionItem value="parts">
					<AccordionTrigger className="px-0">
						<DetailsSectionHeading>
							Parts
							<DetailsSectionHeadingCount>
								({serviceParts.length})
							</DetailsSectionHeadingCount>
						</DetailsSectionHeading>
					</AccordionTrigger>
					<AccordionContent className="mb-5 flex flex-col gap-3 p-0 pt-1">
						{serviceParts.map((part) => (
							<PartDetailsCard key={part.id} part={part} />
						))}
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</DetailsSection>
	)
}
WorkOrderPartsSection.label = "Parts"
WorkOrderPartsSection.icon = Bolt
